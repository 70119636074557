<template>
  <div>
    <template v-for="(alert, i) in alerts">
      <v-snackbar
        :key="`alert-${i}`"
        :value="true"
        :timeout="alert.duration"
        :top="!alert.asLogin"
        :right="!alert.asLogin"
        :bottom="alert.asLogin"
        :color="(alert.color ? alert.color : 'primary')"
        :style="alert.asLogin ? `bottom: ${10 + ((alerts.length - 1 - i) * 70)}px` : `top: ${10 + ((alerts.length - 1 - i) * 70)}px`"
      >
        {{ alert.message }}
        <v-btn icon small @click="removeAlertByIndex(i)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    alerts: []
  }),

  created () {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'toggleSnackbar') {
        let payload = mutation.payload

        if (!payload) {
          payload = {
            duration: 6000,
            message: this.$i18n.t('helpers.errors.disaster'),
            color: 'red darken-4'
          }
        }

        if (!payload.duration) {
          payload.duration = 6000
        }

        this.alerts.push(payload)

        setTimeout(() => {
          this.alerts.pop()
        }, (payload.duration + 1000 | 7000))
      }
    })
  },
  methods: {
    removeAlertByIndex (index) {
      this.alerts.splice(index, 1)
    }
  }
}
</script>
