<template>
  <v-dialog
    v-model="state"
    max-width="550"
  >
    <v-card dark>
      <v-card-title class="mb-0 pt-4 pb-2 text-center">
        <v-icon color="red darken-2" size="30">
          mdi-delete
        </v-icon>
        <p class="pa-0 ma-0 ml-2 delete__dialog__title">
          {{ title }}
        </p>
      </v-card-title>

      <v-divider class="mx-4" />

      <v-card-text class="mt-2">
        <p>{{ caption }}</p>
        <ul>
          <li>{{ name }}</li>
        </ul>
      </v-card-text>

      <v-divider class="mx-4" />

      <!-- <v-card-text class="mt-2">
        <p>{{ confirmMessage }} <code>{{ choice }}</code></p>
        <v-text-field
          v-model="confirmation"
          solo
          hide-details
        />
      </v-card-text> -->

      <v-divider class="mx-4" />

      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          color="white"
          text
          @click="state = false"
        >
          <v-icon>mdi-cancel</v-icon>
          {{ $t('actions.ignore') }}
        </v-btn>
        <v-btn
          color="red"
          text
          @click="() => action(idToDelete)"
        >
          <v-icon>mdi-delete</v-icon>
          {{ $t('actions.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: 'dialog',
    event: 'set-dialog'
  },

  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    options: {
      type: Array,
      default: () => ['Confirm my action']
    },
    confirmMessage: {
      type: String,
      default: () => 'To confirm the action, please fill the following field with this message:'
    },
    title: {
      type: String,
      required: true
    },
    caption: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      default: () => () => null
    },
    // idToDelete
    idToDelete: {
      type: String,
      default: () => null
    }
  },

  data () {
    return {
      choice: '',
      confirmation: ''
    }
  },

  computed: {
    state: {
      get () {
        return this.dialog
      },

      set (value) {
        this.$emit('clear')
        this.$emit('set-dialog', value)
      }
    }

    // isValid () {
    //   return this.choice === this.confirmation
    // }
  },

  watch: {
    dialog (newVal, _) {
      if (newVal) {
        // this.confirmation = ''
        // this.choice = this.options[parseInt(Math.random() * this.options.length)]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.delete__dialog__title{
  white-space: normal;
  word-break: break-word;
}
</style>