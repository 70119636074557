/*
  Unit mutations
*/
export default {
  setList (state, payload) {
    state.list = []
    state.tableList = []
    state.list = payload
    state.tableList = payload
  },
  appendMobileList (state, payload) {
    const list = state.list
    state.list = list.concat(payload)
  },

  // setMobileList (state, payload) {
  //   const list = state.list
  //   state.list = list.concat(payload)
  // },

  setConnectionStatus (state, payload) {
    for (let i = 0; i < payload.length; i++) {
      const element = payload[i]

      state.unitConnectionStatus[element.ident.toString()] = {
        ident: element.ident,
        ip: element.ip,
        established: element.established
      }
    }
  }
}
