<template>
  <v-expansion-panels v-model="currentPanel" dark>
    <v-expansion-panel class="grey-opacity">
      <v-expansion-panel-header>
        {{ $t(`headers.${$route.name}`) }}
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pa-0">
        <div class="scrollbar__seamless expansion__content">
          <v-btn text small @click="newItem">
            <v-icon small>mdi-plus-circle-outline</v-icon>
            &nbsp;
            {{ $t('actions.new') }}
          </v-btn>
          <!--
            Courses settings datatable
            shows coursesGrouped
          -->
          <v-data-table
            :headers="headers"
            :items="coursesGrouped"
            fixed-header
            multi-sort
            class="transparent datatable__custom"
            height="55vh"
            :items-per-page="-1"
          >
            <template v-slot:[`header.icon`]="{ header }">
              {{ header.text }}
            </template>
            <template v-slot:[`header.name`]="{ header }">
              {{ header.text }}
            </template>
            <template v-slot:[`header.mode`]="{ header }">
              {{ header.text }}
            </template>
            <template v-slot:[`header.type`]="{ header }">
              {{ header.text }}
            </template>

            <template v-slot:[`header.actions`]>
              <v-icon small>mdi-wrench</v-icon>
            </template>

            <template v-slot:item="{ item }">
              <tr :key="`playing-${item.id}`">
                <td class="white--text">
                  <v-icon small>mdi-folder-move</v-icon>
                </td>

                <td class="text-center" style="width: 35px;">
                  <template v-if="item.icon === null">
                    <v-icon color="white">mdi-image-off-outline</v-icon>
                  </template>

                  <v-avatar v-else :size="30">
                    <v-img :src="item.icon.uri || '/flag/render'" contain />
                  </v-avatar>
                </td>

                <td class="white--text">
                  {{ item.name }}
                </td>

                <td class="white--text">
                  {{ $t(`courses.modesList.${item.mode}`) }}
                </td>

                <td v-if="item.mode === 'GROUPED'" class="white--text">
                  {{ $t('courses.typesList.CUSTOM', { noHoles: item.noHoles }) }}
                </td>

                <td v-else class="white--text">
                  {{ $t(`courses.typesList.${item.typeOf}`) }}
                </td>

                <td class="text-right white--text">
                  <v-tooltip right color="blue darken-2">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on">
                        <v-icon small @click="editItem(item.id)">mdi-pencil</v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('actions.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip right color="red darken-4">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on">
                        <v-icon
                          small
                          color="red"
                          @click="openDeleteDialog(item.id)"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('actions.delete') }}</span>
                  </v-tooltip>
                </td>
              </tr>
              <!-- subcourse iteration -->
              <tr v-for="(subcourse, j) in item.childs" :key="`subcourse-${item.id}-${j}`">
                <td class="white--text">
                  <v-icon small>mdi-subdirectory-arrow-right</v-icon>
                </td>
                <td class="text-center" style="width: 35px;">
                  <template v-if="subcourse.icon === null">
                    <v-icon color="white">mdi-image-off-outline</v-icon>
                  </template>

                  <v-avatar v-else :size="30">
                    <v-img :src="subcourse.icon.uri || '/flag/render'" contain />
                  </v-avatar>
                </td>

                <td class="white--text">
                  {{ subcourse.name }}
                </td>

                <td class="white--text">
                  {{ $t(`courses.modesList.${subcourse.mode}`) }}
                </td>

                <td class="white--text">
                  {{ $t(`courses.typesList.${subcourse.typeOf}`) }}
                </td>

                <td class="text-right white--text">
                  <v-tooltip right color="blue darken-2">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on">
                        <v-icon small @click="editItem(subcourse.id)">mdi-pencil</v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('actions.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip right color="red darken-4">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on">
                        <v-icon
                          small
                          color="red"
                          @click="openDeleteDialog(subcourse.id)"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('actions.delete') }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>

          <!-- /Courses settings datatable -->
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <!-- panel de edicion y creacion -->
    <v-expansion-panel class="grey-opacity">
      <v-expansion-panel-header>{{ $t('actions.editor') }}</v-expansion-panel-header>

      <v-expansion-panel-content>
        <div
          class="scrollbar__seamless pt-4 form--expansion__content"
        >
          <!-- :style="`${currentPanel === 1 ? '' : 'max-'}height: calc(100vh - 300px); overflow-y: scroll; padding: 0 10px;`" -->
          <template v-if="isEditting">
            <v-layout row wrap class="pt-2 mx-1">
              <v-flex xs12>
                <!-- Hole name -->
                <v-text-field
                  v-model="object.name"
                  :label="$t('courses.name')"
                  :error="errors.name.length > 0"
                  :error-messages="errors.name"
                  outlined
                />
              </v-flex>
              <v-flex v-if="isAdmin" xs12>
                <!-- if it is an admin select to which customer account the hole will be created -->
                <v-select
                  v-model="object.customerId"
                  :label="$t('courses.customer')"
                  :items="customersList"
                  :error="errors.customerId.length > 0"
                  :error-messages="errors.customerId"
                  item-text="username"
                  item-value="id"
                  :disabled="!isEditting"
                  outlined
                />
              </v-flex>
              <v-flex
                xs12
                :md6="object.mode === 'NORMAL'"
                :class="{'pr-2': width.full > 960 && object.mode === 'NORMAL'}"
              >
                <v-select
                  v-model="object.mode"
                  :label="$t('courses.mode')"
                  :error="errors.mode.length > 0"
                  :error-messages="errors.mode"
                  :items="mode"
                  dark
                  outlined
                  :disabled="object.id !== undefined"
                  @change="toggleType"
                />
              </v-flex>

              <v-flex v-if="object.mode === 'NORMAL'" xs12 md6 :class="{'pl-2': width.full > 960}">
                <v-select
                  v-model="object.typeOf"
                  :label="$t('courses.type')"
                  :error="errors.typeOf.length > 0"
                  :error-messages="errors.typeOf"
                  :items="typeOf"
                  dark
                  outlined
                  :disabled="object.id !== undefined"
                />
              </v-flex>

              <v-flex v-if="object.mode === 'GROUPED'" xs12>
                <v-autocomplete
                  v-model="object.childsIds"
                  :label="$t('courses.childsIds')"
                  :error="errors.childsIds.length > 0"
                  :error-messages="errors.childsIds"
                  :items="courses.filter((item) => item.mode === 'NORMAL')"
                  item-value="id"
                  item-text="name"
                  dark
                  outlined
                  multiple
                />
              </v-flex>

              <v-flex v-else xs12>
                <!-- if the course has a parent course select it-->
                <v-autocomplete
                  v-model="object.parentId"
                  :label="$t('courses.parentId')"
                  :error="errors.parentId.length > 0"
                  :error-messages="errors.parentId"
                  :items="[
                    { id: null, name: $t('courses.helpers.withoutParent') },
                    ...courses.filter((item) => item.mode === 'GROUPED')
                  ]"
                  item-value="id"
                  item-text="name"
                  dark
                  outlined
                  @change="handleParentId"
                />
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="object.almostThere"
                  :label="$t('courses.almostThere')"
                  :error="errors.almostThere.length > 0"
                  :error-messages="errors.almostThere"
                  :suffix="$t('courses.paceOfPlay.unit')"
                  outlined
                  type="number"
                />
              </v-flex>
              
              <v-flex xs12>
                <v-select
                  v-model="object.iconId"
                  :label="$t('courses.iconId')"
                  :error="errors.iconId.length > 0"
                  :error-messages="errors.iconId"
                  :items="icons"
                  item-value="id"
                  dark
                  outlined
                >
                  <template v-slot:item="{ item }">
                    <v-avatar :size="30">
                      <v-img v-if="item.uri" :src="item.uri" contain />
                    </v-avatar>
                    
                    <v-divider vertical class="mx-2 my-1" />
                    {{ item.name }}
                  </template>

                  <template v-slot:selection="{ item }">
                    <v-avatar :size="30">
                      <v-img v-if="item.uri" :src="item.uri" contain />
                    </v-avatar>
                    
                    <v-divider vertical class="mx-2 my-1" />
                    {{ item.name }}
                  </template>
                </v-select>
              </v-flex>

              <!-- Color del course -->
              <v-flex v-if="!object.parentId" xs12 md6 class="pr-2">
                <v-menu
                  v-model="courseColorModal"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  :nudge-right="40"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="object.courseColor"
                      :disabled="!isEditting"
                      :error="errors.courseColor.length > 0"
                      :error-messages="errors.courseColor"
                      :label="$t('courses.courseColor')"
                      outlined
                      v-on="on"
                    >
                      <template v-slot:append>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <div :style="`background-color: ${object.courseColor}; width: 20px; height: 20px; border-radius: 50%; margin-top: 2px; box-shadow: 0 0 10px rgba(0,0,0,0.2)`" v-on="on" />
                          </template>

                          <span>{{ $t('courses.helpers.swatch') }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>

                  <v-color-picker
                    v-model="object.courseColor"
                    mode="hexa"
                    hide-inputs
                    hide-mode-switch
                  />
                </v-menu>
              </v-flex>

              <!-- Color del hoyo -->
              <v-flex xs12 :md6="!object.parentId" :class="{'pl-2': !object.parentId }">
                <v-menu
                  v-model="holeColorModal"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  :nudge-right="40"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="object.holeColor"
                      :disabled="!isEditting"
                      :error="errors.holeColor.length > 0"
                      :error-messages="errors.holeColor"
                      :label="$t('courses.holeColor')"
                      outlined
                      v-on="on"
                    >
                      <template v-slot:append>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <div :style="`background-color: ${object.holeColor}; width: 20px; height: 20px; border-radius: 50%; margin-top: 2px; box-shadow: 0 0 10px rgba(0,0,0,0.2)`" v-on="on" />
                          </template>

                          <span>{{ $t('courses.helpers.swatch') }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>

                  <v-color-picker
                    v-model="object.holeColor"
                    mode="hexa"
                    hide-inputs
                    hide-mode-switch
                  />
                </v-menu>
              </v-flex>
              
              <v-flex xs12>
                <v-divider />
              </v-flex>

              <template v-if="!object.parentId">
                <v-flex xs12 class="mt-2">
                  <h4 class="text-center">{{ $t('restrictedZones.actions.title') }}</h4>
                </v-flex>

                <v-flex v-for="action in object.actions" :key="`action-${action.type}`" xs12>
                  <v-checkbox
                    v-model="action.willExecute"
                    :label="$t(`restrictedZones.actions.${action.type.toLowerCase()}`)"
                    hide-details
                  />
                </v-flex>
              </template>

              <v-flex xs12>
                <v-divider class="my-3" />
              </v-flex>

              <v-flex v-if="!object.parentId" xs12>
                <v-btn text block :color="`${drawing ? 'warning' : 'primary'}`" @click="draw">{{ $t(`courses.draw.${drawing}`) }}</v-btn>
              </v-flex>

              <v-flex xs12>
                <v-btn
                  v-if="object.mode === 'NORMAL'"
                  text
                  block
                  color="primary"
                  :disabled="result.holes.length >= (object.typeOf === 'HALF' ? 9 : 18)"
                  @click="newHole"
                >
                  {{ $t(`courses.appendHole`) }}
                </v-btn>
              </v-flex>

              <v-flex xs12 class="mb-3 d-flex justify-center">
                <v-btn
                  text
                  :loading="isLoading"
                  color="error"
                  @click="ignoreItem"
                >
                  {{ $t('actions.ignore') }}
                </v-btn>

                <v-btn
                  text
                  :loading="isLoading"
                  :disabled="!allowed"
                  color="success"
                  @click="saveItem"
                >
                  {{ $t('actions.save') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </template>

          <template v-else>
            <h4 class="text-center pa-2">{{ $t('helpers.notAllowed') }}</h4>
          </template>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <gm-delete-dialog
      v-model="deleteDialog"
      :action="deleteItem"
      :name="courseToDeleteName"

      :title="$t('units.delete.title')"
      :caption="$t('units.delete.caption')"
      :confirm-message="$t('helpers.delete.dialog.confirm')"
      :options="[
        $t('helpers.delete.dialog.message1'),
        $t('helpers.delete.dialog.message2'),
        $t('helpers.delete.dialog.message3')
      ]"
      :id-to-delete="idToDelete"
      @clear="idToDelete = null"
    />
  </v-expansion-panels>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

import add from 'GraphQL/mutations/courses/add.gql'
import edit from 'GraphQL/mutations/courses/edit.gql'
import del from 'GraphQL/mutations/courses/delete.gql'
import details from 'GraphQL/queries/courses/details.gql'
import DeleteDialog from '../../components/utils/DeleteDialog.vue'

export default {
  components: {
    'gm-delete-dialog': DeleteDialog
  },
  data () {
    return {
      courseColorModal: false,
      holeColorModal: false,
      currentPanel: 0,
      object: {},
      isEditting: false,
      isLoading: false,
      rawErrors: {},
      drawing: false,
      deleteDialog: false,
      idToDelete: null
    }
  },

  computed: {
    ...mapGetters({
      defaultObject: 'courses/defaultObject',
      protocolById: 'general/protocolById',
      defaultActions: 'courses/defaultActions',
      coursesGrouped: 'courses/grouped'
    }),
    ...mapState(['general', 'users', 'language', 'width', 'customers']),

    courseToDeleteName () {
      const course = this.courses.find(course => course.id === this.idToDelete)
      if (course) {
        return course.name
      }
      return ''
    },

    isAdmin () {
      return this.user.__typename === 'Admin'
    },

    customersList () {
      return this.customers.list
    },

    protocols () {
      return this.general.protocols
    },

    courses () {
      return this.$store.state.courses.list
    },

    holes () {
      return this.$store.state.courses.holes
    },

    geofence () {
      return this.$store.state.courses.geofence
    },

    user () {
      return this.users.entity
    },

    icons () {
      return [
        { id: null, name: this.$i18n.t('courses.noIcon') },
        ...this.$store.state.icons.list
      ]
    },

    result () {
      return {
        ...this.object,
        holes: this.holes,
        geofence: this.geofence
      }
    },

    allowed () {
      let result = true

      for (const i in this.result.holes) {
        if (this.result.holes[i].geofence.length === 0) {
          result = false
        }
      }

      // console.log(this.object.parentId, this.object.mode)

      if ((!this.object.parentId && this.object.mode === 'NORMAL') || this.object.mode === 'GROUPED') {
        if (this.result.geofence.length === 0) {
          result = false
        }
      }

      return result
    },

    typeOf () {
      if (this.object.mode === 'GROUPED') {
        return [
          { text: this.$i18n.t('courses.types.NONE'), value: 'NONE' }
        ]
      }

      return [
        { text: this.$i18n.t('courses.types.HALF'), value: 'HALF' },
        { text: this.$i18n.t('courses.types.FULL'), value: 'FULL' }
      ]
    },

    mode () {
      return [
        { text: this.$i18n.t('courses.modes.NORMAL'), value: 'NORMAL' },
        { text: this.$i18n.t('courses.modes.GROUPED'), value: 'GROUPED' }
      ]
    },

    headers () {
      return [
        {
          text: '',
          value: 'courseTypeIcon',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('courses.iconId'),
          value: 'icon',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('courses.name'),
          value: 'name',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('courses.mode'),
          value: 'mode',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('courses.type'),
          value: 'type',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('actions.actions'),
          value: 'actions',
          class: 'transparent text--center',
          filterable: false,
          sortable: false
        }
      ]
    },

    errors () {
      const errors = {}

      for (const key in this.defaultObject) {
        let keyCamel = key
        if (key.includes('_')) {
          keyCamel = this.convertToCamelCase(key)
        }

        if (this.rawErrors[key] === undefined) {
          errors[keyCamel] = ''
        } else {
          errors[keyCamel] = this.rawErrors[key].join(', ')
        }
      }

      return errors
    },
    holeColor () {
      return this.object.holeColor
    },
    courseColor () {
      return this.object.courseColor
    }
  },

  watch: {
    currentPanel (newValue, oldValue) {
      if (newValue === 0 && this.isEditting) {
        this.ignoreItem()
        this.$store.commit('clearDrawing')
      }
    },

    coursesGrouped (newValue, oldValue) {
      // console.log('Nuevo valor en la agrupación: ', newValue)
    },
    holeColor (newValue, oldValue) {
      this.$store.commit('courses/setHoleColor', newValue)
    },
    courseColor (newValue, oldValue) {
      this.$store.commit('courses/setCourseColor', newValue)
    }

  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
  },

  mounted () {
    // this.newItem()
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'courses/setGeofence') {
        this.drawing = false
      }
    })
  },

  methods: {
    /**
     * Open the delete dialog to confirm a delete
     */
    openDeleteDialog (id) {
      this.idToDelete = id
      this.deleteDialog = true
    },

    handleParentId () {
      if (this.object.parentId) {
        this.object.actions = []
        this.object.geofence = []
        this.$store.commit('courses/setGeofence', [])
      } else {
        this.object.actions = Object.assign({}, this.defaultActions)
        this.object.parentId = null
      }
    },

    toggleType () {
      if (this.object.mode === 'GROUPED') {
        this.object.typeOf = 'NONE'
        this.$store.commit('setSingleMode', false)
        this.$store.commit('courses/setHoles', [])
        this.object.parentId = null
      } else if (this.object.mode === 'NORMAL') {
      // } else if (this.object.typeOf === 'NONE') {
        this.object.typeOf = 'FULL'
        this.$store.commit('setSingleMode', true)
        this.object.childsIds = []
      }
    },

    newHole () {
      const holes = this.$store.state.courses.holes

      holes.push({
        id: undefined,
        position: holes.length + 1,
        name: this.$i18n.t('courses.holes.name', { number: holes.length + 1 }),
        geofence: []
      })

      this.$store.commit('courses/setHoles', holes)
    },

    draw () {
      if (!this.drawing) {
        this.drawing = true
        this.$store.commit('toggleMap', {
          state: true,
          information: [],
          type: 'course'
        })
      } else {
        this.drawing = false
        this.$store.commit('toggleMap', {
          state: false,
          information: [],
          type: 'course'
        })
      }
    },

    convertToCamelCase (str) {
      str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, (match) => {
        return match.charAt(match.length - 1).toUpperCase()
      })
      return str.charAt(0).toLowerCase() + str.substring(1)
    },

    newItem () {
      this.currentPanel = 1
      this.$store.commit('setHolesEditting', true)
      this.isEditting = true

      this.$store.commit('courses/setHoles', [])

      this.object = Object.assign({}, this.defaultObject)

      this.toggleType()
    },

    async editItem (id) {
      this.object = Object.assign({}, this.defaultObject)

      await this.$apollo.query({
        query: details,
        variables: {
          apiToken: this.user.apiToken,
          language: this.language,
          id
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status, result } = response.data.course

        let holes
        switch (status) {
          case 'OK':
            this.currentPanel = 1
            this.isEditting = true
            this.$store.commit('setHolesEditting', true)

            // Actualizando la visibilidad del selector de hoyos
            if (result.mode === 'GROUPED') {
              this.$store.commit('setSingleMode', false)
            } else {
              this.$store.commit('setSingleMode', true)
            }

            holes = result.holes.map((item) => {
              return {
                id: item.id,
                name: this.$i18n.t('courses.holes.name', { number: item.name }),
                position: item.name,
                paceOfPlay: item.paceOfPlay,
                geofence: item.geofence.points.map((item) => {
                  return {
                    latitude: item.latitude,
                    longitude: item.longitude
                  }
                })
              }
            })

            result.holes = holes

            result.geofence = result.geofence.points.map((item) => {
              return {
                latitude: item.latitude,
                longitude: item.longitude
              }
            })

            this.object = Object.assign({}, result)

            if (this.object.actions.length === 0) {
              this.object.actions = this.defaultActions
            }

            this.$store.commit('courses/setHoles', holes)
            this.$store.commit('courses/setGeofence', result.geofence)
            this.$store.commit('courses/setEdittingId', result.id)
            break

          default:
            this.$store.commit('toggleSnackbar', undefined)
            break
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar', undefined)
      })
    },

    async deleteItem (id) {
      this.object = Object.assign({}, this.defaultObject)

      await this.$apollo.mutate({
        mutation: del,
        variables: {
          apiToken: this.user.apiToken,
          language: this.language,
          id
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status } = response.data.deleteCourse

        switch (status) {
          case 'OK':
            this.$store.dispatch('courses/getList', true)
            this.ignoreItem()
            break

          default:
            this.$store.commit('toggleSnackbar', undefined)
            break
        }
      }).catch((error) => {
        console.error('Settings Delete Error', error)
        this.$store.commit('toggleSnackbar', undefined)
      })
    },

    ignoreItem () {
      this.currentPanel = 0
      this.isEditting = false
      this.object = Object.assign({}, this.defaultObject)
      this.$store.commit('setHolesEditting', false)
      this.$store.commit('courses/setEdittingId', undefined)
      this.$store.commit('clearDrawing')
    },
    /**
     * Save course
     */
    async saveItem () {
      this.rawErrors = {}
      this.isLoading = true

      let method = add
      let name = 'addCourse'

      if (this.object.id !== undefined) {
        method = edit
        name = 'editCourse'
      }

      const variables = {
        apiToken: this.user.apiToken,
        language: this.language,
        data: this.result
      }

      delete variables.data.__typename

      for (const i in variables.data.actions) {
        delete variables.data.actions[i].__typename
      }

      await this.$apollo.mutate({
        mutation: method,
        variables,
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status, errors } = response.data[name]
        const errorProcessed = {}
        switch (status) {
          case 'OK':
            this.$store.dispatch('courses/getList', true)
            setTimeout(() => this.ignoreItem(), 400)

            break

          case 'UNPROCESSABLE':
            for (const key in errors) {
              errorProcessed[this.convertToCamelCase(key)] = errors[key]
            }
            this.rawErrors = errorProcessed
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('errors.invalidFields'),
              color: 'warning'
            })
            break

          default:
            this.$store.commit('toggleSnackbar', undefined)
            break
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar', undefined)
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
