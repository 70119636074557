<template>
  <v-simple-table class="transparent">
    <tbody class="white--text">
      <!-- {{ message }}
      <br>
      -----
      <br>
      {{ unit }} -->
      <tr>
        <td class="d-flex justify-center align-center" colspan="5">
          <v-avatar :size="40">
            <v-img :src="unit.icon.uri || '/car/render'" contain />
          </v-avatar>

          <h3 class="ml-3">{{ unit.name }}</h3>
        </td>
      </tr>

      <tr>
        <!-- Units ident -->
        <td class="text-right font-weight-bold">
          {{ $t('units.ident') }}
        </td>
        <td class="text-left">{{ unit.ident }}</td>

        <td><v-divider vertical /></td>

        <!-- Units phone -->
        <td class="text-right font-weight-bold">
          {{ $t('units.phone') }}
        </td>
        <td class="text-left">
          {{ unit.phone }}
        </td>
      </tr>
      
      <template v-if="unit.protocol.id !== '4'">
        <tr>
          <!-- Battery voltage -->
          <td class="text-right font-weight-bold">
            {{ $t('units.message.battery.voltage') }}
          </td>
          <td class="text-left">
            {{ message === undefined ? 'NA' : `${message.battery.voltage} V` }}
          </td>

          <td><v-divider vertical /></td>

          <!-- Battery charging -->
          <td class="text-right font-weight-bold">
            {{ $t('units.message.battery.charging') }}
          </td>
          <td class="text-left">
            <v-chip
              small
              :color="(message === undefined ? false : message.battery.charging.status) ? 'success' : 'error'"
            >
              {{ $t(`states.charging.${message === undefined ? false : message.battery.charging.status}`) }}
            </v-chip>
          </td>
        </tr>

        <tr>
          <!-- cart voltage -->
          <td class="text-right font-weight-bold">
            {{ $t('units.message.source.voltage') }}
          </td>
          <td class="text-left">
            {{ message === undefined ? 'NA' : `${message.source.voltage}V` }}
          </td>

          <td><v-divider vertical /></td>
          <!-- cart level -->
          <td class="text-right font-weight-bold">
            {{ $t('units.message.source.level') }}
          </td>
          <td class="text-left">
            {{ message === undefined ? 'NA' : `${parseInt(message.source.level * 100) / 100}%` }}
          </td>
        </tr>

        <tr>
          <!-- Cart connection status -->
          <td class="text-right font-weight-bold">
            {{ $t('units.message.source.charging') }}
          </td>
          <td class="text-left">
            <!-- Tooltip with chip -->
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-chip
                  small
                  :color="connectionStatusColor(unitConnectionStatus[unit.ident])"
                  v-on="on"
                >
                  {{ connectionStatusText(unitConnectionStatus[unit.ident]) }}
                </v-chip>
              </template>
              <span>
                {{ connectionStatusTime(unitConnectionStatus[unit.ident]) }}
              </span>
            </v-tooltip>
          </td>

          <td><v-divider vertical /></td>

          <!-- Cart movement -->
          <td class="text-right font-weight-bold">{{ $t('units.message.motion.state') }}</td>
          <td class="text-left">
            <v-chip small :color="(message === undefined ? false : message.position.motion.state) ? 'success' : 'error'">{{ $t(`states.movement.${message === undefined ? false : message.position.motion.state}`) }}</v-chip>
          </td>
        </tr>

        <tr>
          <!-- Battery level percentage -->
          <td class="text-right font-weight-bold">
            {{ $t('units.message.battery.level') }}
          </td>
          <td class="text-left">
            {{ message === undefined ? 'NA' : `${message.battery.level}%` }}
          </td>

          <td><v-divider vertical /></td>
          <!--  Cart movement value-->
          <td class="text-right font-weight-bold">
            {{ $t('units.message.motion.value') }}
          </td>
          <td class="text-left">
            {{ message === undefined ? 'N/A' : `${message.position.motion.value}` }}
          </td>
        </tr>

        <tr>
          <!-- satellite strength -->
          <td class="text-right font-weight-bold">
            {{ $t('units.message.position.hdop') }}
            <!--  -->
          </td>
          <td class="text-left">
            <hdop :value="message === undefined ? undefined : message.position.satellites" />
          </td>

          <td><v-divider vertical /></td>
          <!-- Protocol -->
          <td class="text-right font-weight-bold">
            {{ $t('units.protocolId') }}
          </td>
          <td class="text-left">
            {{ unit.protocol.name }}
          </td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <!-- Protocol -->
          <td class="text-right font-weight-bold">
            {{ $t('units.protocolId') }}
          </td>
          <td class="text-left">
            {{ unit.protocol.name }}
          </td>
        </tr>
      </template>
    </tbody>
  </v-simple-table>
</template>

<script>
import Hdop from './Hdop.vue'

var relativeTime = require('dayjs/plugin/relativeTime')
var duration = require('dayjs/plugin/duration')
var dayjs = require('dayjs')
dayjs.extend(duration)
dayjs.extend(relativeTime)

export default {
  components: {
    Hdop
  },

  props: {
    unit: {
      type: Object,
      required: true
    },

    message: {
      type: Object,
      required: false,
      default: () => undefined
    }
  },
  computed: {
    unitConnectionStatus () {
      return this.$store.state.units.unitConnectionStatus
    }
  },
  methods: {
    connectionObjectTimeDifference (connectionObject) {
      if (connectionObject) {
        // tiempo llega en segundo asi que se multiplica por 1000 para obtener en milisegundos
        const lastConnectionDate = connectionObject.established * 1000
        const now = new Date().getTime()
        const difference = now - (lastConnectionDate)
        return difference
      }
      return undefined
    },
    connectionStatusColor (connectionObject) {
      if (connectionObject === undefined) {
        return 'grey'
      } else {
        // 60_000
        const minutesInMillis = 60000

        const difference = this.connectionObjectTimeDifference(connectionObject)

        // 20 minutos sin conexion desconectada
        if (difference > (minutesInMillis * 20)) {
          return 'grey'
        } else {
          return 'green'
        }
      }
    },
    connectionStatusText (connectionObject) {
      if (connectionObject === undefined) {
        return this.$i18n.t('signal.status.na')
      } else {
        // 60_000
        const minutesInMillis = 60000

        const difference = this.connectionObjectTimeDifference(connectionObject)
        // 15 minutos
        if (difference > (minutesInMillis * 15)) {
          return `${this.$i18n.t('signal.status.disconnected')}`
        // 5 minutos
        } else if (difference > (minutesInMillis * 5)) {
          return `${this.$i18n.t('signal.status.waiting')}`
        } else {
          return `${this.$i18n.t('signal.status.connected')}`
        }
      }
    },
    connectionStatusTime (connectionObject) {
      if (connectionObject === undefined) {
        return this.$i18n.t('signal.status.na')
      } else {
        // 60_000

        const difference = this.connectionObjectTimeDifference(connectionObject)
        return `${this.capitalize(this.$i18n.t('signal.status.last_connection'))}: ${dayjs.duration(difference, 'milliseconds').humanize()} ${this.$i18n.t('signal.time.ago')}`
      }
    },
    capitalize (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }
}
</script>