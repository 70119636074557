/**
 * Class to draw label name in geofence
 *
 * must be called after window.google already exists
 * preferably with require('path/LabelDrawer.js')
 */
class LabelDrawer extends window.google.maps.OverlayView {
  constructor (
    google,
    map,
    position,
    itemName,
    type = 'car',
    labelBackgroundColor = 'black',
    labelTextColor = 'white'
  ) {
    super()
    this._map = map
    this._position = new google.maps.LatLng(position.latitude, position.longitude)
    this._name = itemName

    this._labelBackgroundColor = labelBackgroundColor
    this._labelTextColor = labelTextColor

    this._type = type

    this.setMap(map)

    this._google = google

    this._html = null
  }

  onAdd () {
    const size = 9.5
    const div = document.createElement('div')
    const inner = document.createElement('div')
    const panes = this.getPanes()

    div.style.border = 'none'
    div.style.position = 'absolute'
    div.style.fontSize = '14px'
    div.style.textAlign = 'center'

    inner.innerHTML = this._name
    inner.style.position = 'absolute'
    inner.style.background = this._labelBackgroundColor
    inner.style.border = `2px solid ${this._labelTextColor}`
    inner.style.height = '25px'
    inner.style.paddingTop = '2px'

    inner.style.borderRadius = '10px'

    inner.style.letterSpacing = '0px'

    // distance to center the label below the marker
    const leftDistant = this._name.length > 12 ? 13 : this._name.length

    inner.style.left = -(leftDistant * (size / 2))

    inner.style.top = `${this._type === 'car' ? '' : '-'}10px`

    inner.style.width = this._name.length * size

    inner.style.maxWidth = '130px'

    inner.style.fontFamily = 'Fira Sans Extra Condensed'
    inner.style.color = this._labelTextColor
    inner.style.fontWeight = '600'

    div.innerHTML = inner.outerHTML

    this._html = div

    panes.overlayImage.appendChild(this._html)
  }

  getName () {
    return this._name
  }

  setName (newName) {
    this._name = newName
    this.onRemove()
    this.onAdd()
  }

  draw () {
    const overlayProjection = this.getProjection()
    let pos = { x: 0, y: 0 }
    try {
      pos = overlayProjection.fromLatLngToDivPixel(this._position)
    } catch (error) {
      console.warn('LabelDrawer Error' + error.toString())
    }

    const div = this._html

    if (div !== null && pos) {
      div.style.left = pos.x + 'px'
      div.style.top = pos.y + 'px'
    }
  }

  setPosition (position) {
    this._position = new this._google.maps.LatLng(
      position.latitude,
      position.longitude
    )

    this.draw()
  }

  onRemove () {
    this._html.parentNode.removeChild(this._html)
    this._html = null
  }
}

module.exports = LabelDrawer
