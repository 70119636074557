<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12>
        <gm-listing
          name="operators"
          has-actions
          :actions-access="{ show: true, edit: true, delete: true }"
          :columns="columns"
          :items-per-page="itemsPerPage"
          :rows="data"
          @new-item="newItem"
          @reload-list="$store.dispatch('operators/getList', true)"
          @new-items-per-page="setNewItemsPerPage"
          @toggle-action="toggleAction"
        />
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="dialog"
      :fullscreen="isMobile"
      :max-width="width.dialog"
      scrollable
      persistent
      :overlay="false"
      transition="dialog-transition"
      :dark="isDark"
    >
      <v-card>
        <v-card-title>
          {{ dialogTitle }}
          <v-spacer />
          <v-btn icon text @click="discard">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider class="mx-4 my-2" />

        <v-card-text class="mb-0 pb-0 pt-1">
          <v-layout row wrap>
            <v-flex xs12 sm6 md4 class="px-2">
              <v-text-field
                v-model="object.name"
                :disabled="!isEditting"
                :error="errors.name.length > 0"
                :error-messages="errors.name"
                :label="$t('operators.name')"
                outlined
              />
            </v-flex>

            <v-flex xs12 sm6 md4 class="px-2">
              <v-text-field
                v-model="object.lastName"
                :disabled="!isEditting"
                :error="errors.lastName.length > 0"
                :error-messages="errors.lastName"
                :label="$t('operators.lastName')"
                outlined
              />
            </v-flex>

            <v-flex xs12 md4 class="px-2">
              <v-text-field
                v-model="object.username"
                :disabled="!isEditting"
                :error="errors.username.length > 0"
                :error-messages="errors.username"
                :label="$t('operators.username')"
                outlined
              />
            </v-flex>
            <v-flex xs12 sm6 :md4="isEditting" class="px-2">
              <v-text-field
                v-model="object.email"
                :disabled="!isEditting"
                :error="errors.email.length > 0"
                :error-messages="errors.email"
                :label="$t('marshalls.email')"
                outlined
                type="email"
              />
            </v-flex>

            <v-flex xs12 sm6 :md4="isEditting" class="px-2">
              <v-text-field
                v-model="object.phone"
                :disabled="!isEditting"
                :error="errors.phone.length > 0"
                :error-messages="errors.phone"
                :label="$t('marshalls.phone')"
                outlined
                type="phone"
              />
            </v-flex>

            <v-flex v-if="isEditting" xs12 sm6 md4 class="px-2">
              <v-text-field
                v-model="object.password"
                :disabled="!isEditting"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :error="errors.password.length > 0"
                :error-messages="errors.password"
                :label="$t('marshalls.password')"
                :type="showPassword ? 'text' : 'password' "
                outlined
                @click:append="showPassword = !showPassword"
              />
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions class="d-flex justify-space-between flex-row-reverse">
          <v-btn v-if="isEditting" color="green darken-2 white--text" :disabled="isLoading" @click="graphqlHandler(object.id === undefined ? 'add' : 'edit')">
            <v-icon>mdi-check-circle</v-icon>
            {{ $t('actions.save') }}
          </v-btn>

          <v-btn v-if="!isEditting" color="orange darken-2 white--text" :disabled="isLoading" @click="editItem">
            <v-icon>mdi-pencil</v-icon>
            {{ $t('actions.edit') }}
          </v-btn>

          <v-btn v-if="isEditting" color="red darken-2 white--text" @click="discard">
            <v-icon>mdi-close-circle</v-icon>
            {{ $t('actions.discard') }}
          </v-btn>

          <v-btn v-if="object.id !== undefined && !isEditting" color="red darken-2 white--text" :disabled="isLoading" @click="graphqlHandler('delete', object.id)">
            <v-icon>mdi-delete</v-icon>
            {{ $t('actions.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { Listing } from 'Components/scaffolds'
import { mapState, mapGetters } from 'vuex'

import add from 'GraphQL/mutations/operators/add.gql'
import edit from 'GraphQL/mutations/operators/edit.gql'
import del from 'GraphQL/mutations/operators/delete.gql'
import details from 'GraphQL/queries/operators/details.gql'

export default {
  components: {
    'gm-listing': Listing
  },

  data () {
    return {
      object: {},
      dialog: false,
      isEditting: false,
      itemsPerPage: 10,
      currentTab: 0,
      showPassword: false,
      isLoading: false,
      rawErrors: {}
    }
  },

  computed: {
    ...mapState(['operators', 'width', 'isDark', 'users', 'language', 'units']),
    ...mapGetters(['isMobile']),
    ...mapGetters({
      defaultObject: 'operators/defaultObject'
    }),

    errors () {
      const errors = {}

      for (const key in this.defaultObject) {
        let keyCamel = key
        if (key.includes('_')) {
          keyCamel = this.convertToCamelCase(key)
        }

        // console.log(key, keyCamel, this.rawErrors[key])
        if (this.rawErrors[key] === undefined) {
          errors[keyCamel] = ''
        } else {
          errors[keyCamel] = this.rawErrors[key].join(', ')
        }
      }
      return errors
    },

    dialogTitle () {
      if (this.object.id === undefined) {
        return this.$i18n.t('operators.title.new')
      } else {
        return this.$i18n.t(`operators.title.${this.isEditting ? 'edit' : 'show'}`, { name: this.object.name })
      }
    },

    user () {
      return this.users.entity
    },

    data () {
      return this.operators.list
    },

    columns () {
      return [
        {
          title: this.$i18n.t('operators.name'),
          value: 'name',
          isSortable: false,
          alignment: 'center'
        },
        {
          title: this.$i18n.t('operators.lastName'),
          value: 'lastName',
          isSortable: true,
          alignment: 'center'
        }
      ]
    }
  },

  // mounted () {
  //   this.newItem()
  // },

  methods: {
    convertToCamelCase (str) {
      str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, (match) => {
        return match.charAt(match.length - 1).toUpperCase()
      })
      return str.charAt(0).toLowerCase() + str.substring(1)
    },

    setNewItemsPerPage (newValue) {
      this.itemsPerPage = newValue
    },

    newItem () {
      this.dialog = true
      this.isEditting = true
      this.object = Object.assign({}, this.defaultObject)
    },

    editItem () {
      this.isEditting = true
    },

    async toggleAction ({ action, id }) {
      await this.$store.commit('setLoading', true)

      if (action === 'delete') {
        await this.$store.commit('setLoading', true)
        this.graphqlHandler('delete', id)
        await this.$store.commit('setLoading', false)
      } else {
        await this.$apollo.query({
          query: details,
          variables: {
            apiToken: this.user.apiToken,
            language: this.language,
            id
          },
          fetchPolicy: 'no-cache'
        }).then((response) => {
          const { status, result } = response.data.operator

          if (status === 'OK') {
            this.object = Object.assign({}, result)
            this.dialog = true

            if (action === 'edit') {
              this.isEditting = true
            }
          } else {
            this.$store.commit('toggleSnackbar')
          }
        }).catch((error) => {
          this.$store.commit('toggleSnackbar')
        }).finally(() => {
          this.$store.commit('setLoading', false)
        })
      }
    },

    discard () {
      this.object = Object.assign({}, this.defaultObject)
      this.isEditting = false
      this.dialog = false
    },

    async graphqlHandler (method, id = undefined) {
      this.isLoading = true
      this.rawErrors = {}

      let mutation = add
      let name = 'addOperator'

      const variables = {
        apiToken: this.user.apiToken,
        language: this.language,
        data: this.object
      }

      delete variables.data.__typename

      if (method === 'edit') {
        name = 'editOperator'
        mutation = edit
      } else if (method === 'delete') {
        name = 'deleteOperator'
        delete variables.data
        variables.id = id
        mutation = del
        // console.log('Here', id)
      }

      await this.$apollo.mutate({
        mutation,
        variables,
        fetchPolicy: 'no-cache'
      }).then((response) => {
        // console.log(response)
        const data = response.data[name]
        const status = data.status

        const errorProcessed = {}

        switch (status) {
          case 'OK':
            this.$store.dispatch('operators/getList', true)

            if (method === 'delete') {
              this.discard()
            } else {
              this.object = Object.assign({}, data.result)
              this.isEditting = false
            }
            break

          case 'UNPROCESSABLE':
            for (const key in data.errors) {
              errorProcessed[this.convertToCamelCase(key)] = data.errors[key]
            }

            this.rawErrors = Object.assign({}, errorProcessed)
            // console.log(this.rawErrors)
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('errors.invalidFields'),
              color: 'orange darken-2'
            })
            break
        }
      }).catch((error) => {
        console.error('Operators Mutation Error', error)
        this.$store.commit('toggleSnackbar')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>
