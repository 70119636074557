<template>
  <v-app>
    <gm-navbar />
    <gm-platform-sidebar
      :is-mobile="isMobile"
      :dark="true"
      :mobile-links="links"
      background-color="black"
      :state="drawer"
      :name="$t('app.title')"
      :logo="$assets.faviconWhite"
      :avatar="avatar"
      :full-name="fullName"
      :mini-variant="false"
      :actions="actions"
      :permanent="false"
      @set-state="(value) => $store.commit('setDrawer', value)"
    />

    <v-main>
      <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 1; pointer-events: none; overflow: hidden;">
        <vue-draggable-resizable
          :w="width"
          :h="height"
          :x="x"
          :y="y"
          :parent="true"
          :drag-handle="'.drag-handle'"
          style="pointer-events: auto;"
          class-name-handle="vue-resize-handle"
          :handles="['ml','mr']"
          :draggable="!isDragLocked"
          :resizable="!isResizeLocked"
          :active.sync="activeResizeDrag"
          @resizing="onResize"
          @dragging="onDrag"
        >
          <v-card dark>
            <v-card-title class="pa-1">
              <!-- tooltip for the lock drag button -->
              <v-tooltip>
                <template v-slot:activator="{ on, attrs }">
                  <!-- Lock movement button icon -->
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="isDragLocked = !isDragLocked"
                  >
                    <v-icon>
                      {{ isDragLocked ? 'mdi-lock' : 'mdi-lock-open-variant' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('app.menu.lock_drag') }}</span>
              </v-tooltip>
              <!-- tooltip  for the lock resize button -->
              <v-tooltip>
                <template v-slot:activator="{ on, attrs }">
                  <!-- Lock resize button icon -->
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="isResizeLocked = !isResizeLocked"
                  >
                    <v-icon>
                      {{ isResizeLocked ? 'mdi-arrow-horizontal-lock' : 'mdi-arrow-expand' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('app.menu.lock_resize') }}</span>
              </v-tooltip>
              <v-spacer />
              <!-- tooltip for the activate resize -->
              <v-tooltip>
                <template v-slot:activator="{ on, attrs }">
                  <!-- Activate resize button icon -->
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      @click="activeResizeDrag = true"
                    >
                      mdi-arrow-expand-horizontal
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('app.menu.activate_resize') }}</span>
              </v-tooltip>
              <!-- tooltip for the drag handle -->
              <v-tooltip>
                <template v-slot:activator="{ on, attrs }">
                  <!-- Drag handle icon -->
                  <v-icon
                    class="drag-handle"
                    style="cursor: move;"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-drag
                  </v-icon>
                </template>
                <span>{{ $t('app.menu.drag_handle') }}</span>
              </v-tooltip>
            </v-card-title>
          </v-card>
          <!-- <transition name="component-fade" mode="out-in">
            <div v-if="isNormalDistribution">
              <transition name="component-fade" mode="out-in">
                <router-view />
              </transition>
            </div>
          </transition>

          <transition name="component-fade" mode="out-in">
            <div v-if="!isNormalDistribution">
            </div>
          </transition> -->
          <div class="main-content">
            <transition name="component-fade" mode="out-in">
              <router-view />
            </transition>
          </div>

          <transition name="component-fade" mode="out-in">
            <div
              v-if="isHolesEditting && isSingleMode"
              class="column-abs-2"
              :style="`left: ${absOffsetContainer2};`"
            >
              <gm-holes />
            </div>
          </transition>

          <transition name="component-fade" mode="out-in">
            <div
              v-if="isHolesEditting && isSingleMode"
              class="column-abs-3"
              :style="`left: ${absOffsetContainer3};`"
            >
              <gm-pace-of-play />
            </div>
          </transition>
        </vue-draggable-resizable>
      </div>

      <!-- Visualization Tool  -->
      <div class="column-abs-final">
        <v-card dark class="grey-opacity pa-2" style="display: flex; flex-direction: column;">
          <v-menu
            offset-y
            :close-on-click="true"
            :open-on-hover="false"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <v-btn fab small depressed color="transparent" v-on="on">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="(item, i) in options" :key="item.value" @click="toggleOptions(i)">
                <v-checkbox
                  v-model="item.isSelected"
                  readonly
                  color="primary"
                  class="mt-0"
                  dense
                  hide-details
                  :label="item.text"
                />
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu
            offset-y
            :close-on-click="true"
            :open-on-hover="false"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <v-btn fab small depressed color="transparent" v-on="on">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in categoryOptions"
                :key="item.value"
                @click="toggleCategory(i)"
              >
                <v-checkbox
                  v-model="item.isSelected"
                  readonly
                  color="primary"
                  class="mt-0"
                  dense
                  hide-details
                  :label="item.text"
                />
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            icon
            @click="toggleTooltipCorner"
          >
            <v-icon
              :style="`transform: rotate(${popupCornerRotation}deg);`"
            >
              {{ currentCorner === 0 ? 'mdi-card-outline' : 'mdi-chevron-left' }}
            </v-icon>
          </v-btn>
        </v-card>
      </div>

      <!-- Map -->
      <gm-map />
    </v-main>

    <gm-footer is-login />
  </v-app>
</template>

<script>
import { Footer, TrackingBar, PlatformSidebar } from 'Components/layout'
import { Map, Holes, PaceOfPlay } from 'Components/map'
import { mapState, mapGetters } from 'vuex'
import mqtt from 'mqtt'
import VueDraggableResizable from 'vue-draggable-resizable'

// import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

export default {
  components: {
    'gm-navbar': TrackingBar,
    'gm-footer': Footer,
    'gm-map': Map,
    'gm-holes': Holes,
    'gm-pace-of-play': PaceOfPlay,
    'gm-platform-sidebar': PlatformSidebar,
    VueDraggableResizable
  },

  data () {
    return {
      client: null, // QMTT Client
      optionsSelected: [],
      categorySelected: [],
      width: 500,
      height: 100,
      x: 10,
      y: 10,
      isDragLocked: false,
      isResizeLocked: false,
      activeResizeDrag: false,
      /**
       * 0: NONE
       * 1: TOPLEFT
       * 2: TOPRIGHT
       * 3: BOTTOMLEFT
       * 4: BOTTOMRIGHT
       */
      currentCorner: 0
    }
  },

  computed: {
    ...mapState([
      'isHolesEditting',
      'isSingleMode',
      'isRestrictedEditting',
      'general',
      'drawer'
    ]),
    ...mapGetters({
      units: 'units/byId',
      courses: 'courses/byId',
      holes: 'courses/holesById',
      geofences: 'restrictedZones/byId',
      avatar: 'users/avatar',
      fullName: 'users/fullName',
      isMobile: 'isMobile'
    }),
    popupCornerRotation () {
      switch (this.currentCorner) {
        case 0:
          return 0
        case 1:
          return 45
        case 2:
          return 135
        case 3:
          return 225
        case 4:
          return 315
        default:
          return 0
      }
    },
    tooltipCornerName () {
      return [
        'NONE',
        'TOPLEFT',
        'TOPRIGHT',
        'BOTTOMRIGHT',
        'BOTTOMLEFT'
      ]
    },

    actions () {
      return [
        {
          name: this.$i18n.t('users.signOut'),
          action: () => this.$store.dispatch('users/logout', 'Platform')
        }
      ]
    },
    links () {
      return [
        {
          to: '/Platform/Home',
          name: this.$i18n.t('headers.home'),
          can: true
        },
        {
          to: '/Platform/Units',
          name: this.$i18n.t('headers.units'),
          can: true
        },
        {
          to: '/Platform/Settings',
          name: this.$i18n.t('headers.settings'),
          can: true
        },
        {
          to: '/Platform/Restricted',
          name: this.$i18n.t('headers.restricted'),
          can: true
        },
        {
          to: '/Platform/PaceOfPlay',
          name: this.$i18n.t('headers.paceOfPlay'),
          can: true
        },
        {
          to: '/Platform/Monitor',
          name: this.$i18n.t('headers.monitor'),
          can: true
        },
        {
          to: '/Platform/Alerts',
          name: this.$i18n.t('headers.alerts'),
          can: true
        },
        {
          to: '/Platform/History',
          name: this.$i18n.t('headers.history'),
          can: true
        }
      ]
    },

    /**
      * General visibility options
      *
      * @since  2020-02-11
      * @access private
      *
      * @return {Array} Array of Options for general visibility
      *
      */
    options: {
      get () {
        const defaultValue = [...this.general.defaultVisibility]

        // console.log('defaultVisibility: ', defaultValue)

        if (this.optionsSelected.length === 0) {
          this.optionsSelected = defaultValue /* eslint-disable-line */
          return defaultValue
        }

        return this.optionsSelected
      },

      set (value) {
        this.optionsSelected = value
      }
    },

    /**
      * Unit visibility options
      *
      * @since  2020-02-11
      * @access private
      *
      * @return {Array} Array of Options for unit visibility
      *
      */
    categoryOptions: {
      get () {
        const defaultValue = [...this.general.defaultCategoryVisibility]

        // console.log('defaultCategoryVisibility', defaultValue)

        if (this.categorySelected.length === 0) {
          this.categorySelected = defaultValue /* eslint-disable-line */
          return defaultValue
        }
        return this.categorySelected
      },

      set (value) {
        this.categorySelected = value
      }
    },

    /**
      * Is normal distribution
      * Modify the layout of the platform for more space in tab
      *
      * @since  2020-02-12
      * @access private
      *
      * @return {Boolean} Is normal
      *
      */
    isNormalDistribution () {
      return !(['/Platform/PaceOfPlay', '/Platform/Monitor', '/Platform/Home'].includes(this.$route.path))
    },
    currentRoute () {
      return this.$route.path
    },
    absOffsetContainer2 () {
      const value = this.width + 10

      return `${value}px`
    },
    absOffsetContainer3 () {
      const value = this.width + 190
      return `${value}px`
    }
  },
  watch: {
    currentRoute () {
      this.updateMainWidth()
    }
  },
  mounted () {
    this.startMqtt()
    this.timeoutController()
    this.updateMainWidth()

  },

  methods: {
    updateWidth (width) {
      if (width > window.innerWidth) {
        this.width = window.innerWidth
      } else {
        this.width = width
      }
    },
    updateMainWidth () {
      if (this.isNormalDistribution) {
        this.updateWidth(500)
        this.x = 10
        this.y = 10
      } else {
        this.updateWidth(960)
        this.x = 10
        this.y = 10
      }
    },
    onResize: function (x, y, width, height) {
      this.x = x
      this.y = y
      this.width = width
      this.height = height
    },
    onDrag: function (x, y) {
      this.x = x
      this.y = y
    },
    toggleOptions (index) {
      this.options[index].isSelected = !this.options[index].isSelected
      const temp = Object.values(Object.assign({}, this.options))
      this.options = temp

      const result = this.options.filter(
        (item) => { return item.isSelected }).map((item) => item.value
      )
      this.$store.commit('general/setVisibility', result)
    },

    toggleCategory (index) {
      this.categoryOptions[index].isSelected = !this.categoryOptions[index].isSelected
      const temp = Object.values(Object.assign({}, this.categoryOptions))
      this.categoryOptions = temp

      const result = this.categoryOptions.filter(
        (item) => { return item.isSelected }).map((item) => { return item.value }
      )
      this.$store.commit('general/setCategoryVisibility', result)
    },

    /**
      * Start MQTT
      *
      * @since  2020-02-11
      * @access private
      * 
      * @notes
      * Devices IDs
      * + -------------- + ------ +
      * | Map            | 482871 |
      * + -------------- + ------ +
      * | Alerts         | 562841 |
      * + -------------- + ------ +
      * | Maintenance    | 562237 |
      * + -------------- + ------ +
      * | Pace of play   | 550245 |
      * + -------------- + ------ +
      * @return {null}
      *
      */
    startMqtt () {
      if (this.client !== null) {
        return
      }

      this.client = mqtt.connect('mqtts://mqtt.flespi.io', {
        username: `FlespiToken ${this.$tokens.realtime}`
      })

      this.client.on('connect', () => {
        // First channel id 24233
        this.client.subscribe('flespi/message/gw/channels/24233/#', (err) => {
          if (!err) {
            console.log('MQTT Client - Subscribed')
          } else {
            console.error('MQTT Client - Subscription error handled', err)
          }
        })

        // Second channel id 47385
        this.client.subscribe('flespi/message/gw/channels/47385/#', (err) => {
          if (!err) {
            console.log('MQTT Client - Subscribed')
          } else {
            console.error('MQTT Client - Subscription error handled', err)
          }
        })
      })

      this.client.on('error', (err) => {
        console.error('MQTT Client - Client error', err)
      })

      this.client.on('close', () => {
        console.log('MQTT Client - Closing session')
      })

      this.client.on('message', this.processMessage)
    },

    /**
      * Process Message
      * Receives MQTT message
      *
      * @since  2020-02-11
      * @access private
      * 
      * @param {String} topic
      * @param {String} message JSON string of Message payload
      *
      * @return {null}
      *
      */
    processMessage (topic, message) {
      message = JSON.parse(message)

      // console.log('New message')
      // console.log('message ', message)
      if ((new Date().getTime() / 1000 - message['message.timestamp']) > 180) {
        return
      }

      // if the message doesnt belong to any unit of this account return
      if (!this.units[message['unit.id']]) {
        return
      }

      if (topic.includes('alerts')) {
        this.appendAlert(message)
      } else {
        if (this.units[message['unit.id'].toString()] === undefined) {
          return null
        }

        if (topic.includes('maintenance')) {
          // Processing Maintenance message
          this.updateMaintenance(message)
        } else if (topic.includes('seguimiento')) {
          // Processing Map message
          this.updateMessage(message)
        } else if (topic.includes('paceofplay')) {
          // Processing pace of play message
          this.updatePaceOfPlay(message)
        }
      }
    },

    updateMaintenance (message) {
      this.$store.commit('general/setMaintenance', {
        unitId: message['unit.id'],
        engineRuntime: message['message.engine.runtime'],
        engineStatus: message['message.engine.status'],
        batteryLevel: message['message.battery.level'],
        isMoving: message['message.is.moving'],
        isCharging: message['message.is.charging'],
        crashes: message['message.crashes'],
        courseId: message['message.course.id'],
        holeId: message['message.hole.id'],
        mileage: message['message.mileage'],
        rounds: message['message.quantity.rounds']
      })
    },

    updateMessage (message) {

      this.$store.commit('general/setMessage', {
        unit: {
          id: message['unit.id'],
          name: message['unit.name'],
          ident: message['unit.ident']
        },
        position: {
          latitude: message['message.latitude'],
          longitude: message['message.longitude'],
          speed: message['message.speed'],
          direction: message['message.direction'],
          altitude: message['message.altitude'],
          motion: {
            state: message['message.is.moving'],
            value: message['message.state']
          },
          hdop: message['message.hdop'],
          satellites: message['message.satellites'],
        },
        killbox:{
          ain_1: message['message.kill.box.parameters.ain.1'],
          ain_2: message['message.kill.box.parameters.ain.2'],
        },
        gprs: {
          baseId: message['message.gprs.base.id'],
          networkCode: message['message.gprs.network.code'],
          locationCode: message['message.gprs.location.code']
        },

        battery: {
          voltage: message['message.battery.voltage'],
          charging: {
            status: message['message.battery.charging']
          },
          level: message['message.battery.level']
        },

        source: {
          level: message['message.source.level'],
          voltage: message['message.source.voltage'],
          charging: {
            status: message['message.source.state']
          }
        },

        geofence: {
          supercourse: {
            id: message['message.supercourse.id']
          },
          course: {
            id: message['message.course.id']
          },
          hole: {
            id: message['message.hole.id']
          },
          geofence: {
            id: message['message.geofence.id']
          }
        },

        engine: {
          virtual: {
            status: message['message.virtual.ignition']
          },

          ignition: {
            status: message['message.ignition']
          }
        },
        receivedAt: message['message.timestamp']
      })
    },

    updatePaceOfPlay (message) {
      if (message['start.at'] === null) {
        return null
      }

      this.$store.commit('general/setPaceOfPlay', {
        unitId: message['unit.id'],
        current: message['game.current.time'],
        finished: message['game.is.finished'],
        overtime: message['game.overtime'],
        allowed: message['game.allowed'],
        average: message['hole.average'],
        startAt: message['start.at']
      })
    },

    timeoutController () {
      setInterval(() => {
        this.$store.commit('updateClock', {
          hours: (new Date()).getHours(),
          minutes: (new Date()).getMinutes(),
          seconds: (new Date()).getSeconds()
        })
        this.$store.commit('general/increaseSeconds')
      }, 1000)
    },

    appendAlert (message) {
      this.$store.commit('general/appendAlert', {
        unit: {
          id: message['unit.id'],
          name: message['unit.name'],
          ident: message['unit.ident'],
          category: message['unit.category'],
          iconId: message['unit.icon.id'],
          nominalVoltage: message['unit.nominal.voltage']
        },
        content: {
          content: message['alerts.content.content'],
          courseId: message['alerts.content.course.id'],
          restrictedId: message['alerts.content.restricted.id'],
          unitId: message['alerts.content.unit.id']
        },
        at: message.timestamp
      })

      this.$store.dispatch('units/getList')
    },
    toggleTooltipCorner () {
      this.currentCorner = this.currentCorner + 1 > this.tooltipCornerName.length - 1 ? 0 : this.currentCorner + 1

      this.$store.commit('general/setTooltipCorner', this.tooltipCornerName[this.currentCorner])
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scrollbar_chrome.scss';

$max_size: 88vw;

// $size-container-1: clamp(150px, 500px, $max_size);
$size-container-2: clamp(150px, 170px, $max_size);
$size-container-3: clamp(150px, 240px, $max_size);
// $size-container-full: clamp(250px, 960px, $max_size);

$spacer: 10px;

$offset-container-2: clamp(160px, 510px, $max_size);
$offset-container-3: clamp(320px, 690px, $max_size);

.main-content{
  max-height: calc(100vh - 96px);
}

// holes
.column-abs-2 {
  max-height: calc(100vh - 96px);
  // left: $offset-container-2;
  padding: 5px 0 5px 5px;
  position: absolute;
  top: 0px;
  width: $size-container-2;
  z-index: 1;
}

// pace of play
.column-abs-3 {
  max-height: calc(100vh - 96px);
  // left: $offset-container-3;
  padding: 5px 0 5px 5px;
  position: absolute;
  top: 0px;
  width: $size-container-3;
  z-index: 1;
}

.column-abs-final {
  position: absolute;
  right: 10px;
  top: 100px;
  z-index: 1;
}

.page__container {
  height: calc(100vh - 96px );

  .sidebar__container {
    pointer-events: none;
    z-index: 1;
    width: 30%;
    position: absolute;
    top: 0px;
    left: 0;
    height: calc(100vh - 96px);
    padding: 10px;

    .sidebar {
      pointer-events: all;
    }
  }

}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.acordion-active {
  @extend .scrollbar__seamless;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  padding: 0 10px;
}

/**
drag handle class
*/
.vue-resize-handle {
    position: absolute;
    background-color: #CFCFCF;
    border: 1px solid black;
    // border-radius: 50%;
    height: 14px;
    width: 14px;
    -webkit-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.vue-resize-handle-tl {
  top: -14px;
  left: -14px;
  cursor: nw-resize;
}

.vue-resize-handle-tm {
  top: -14px;
  left: 50%;
  margin-left: -7px;
  cursor: n-resize;
}

.vue-resize-handle-tr {
  top: -14px;
  right: -14px;
  cursor: ne-resize;
}

.vue-resize-handle-ml {
  top: 50%;
  margin-top: -7px;
  left: -14px;
  cursor: w-resize;
}

.vue-resize-handle-mr {
  top: 50%;
  margin-top: -7px;
  right: -14px;
  cursor: e-resize;
}

.vue-resize-handle-bl {
  bottom: -14px;
  left: -14px;
  cursor: sw-resize;
}

.vue-resize-handle-bm {
  bottom: -14px;
  left: 50%;
  margin-left: -7px;
  cursor: s-resize;
}

.vue-resize-handle-br {
  bottom: -14px;
  right: -14px;
  cursor: se-resize;
}

.vue-resize-handle-tl,
.vue-resize-handle-tr,
.vue-resize-handle-bl,
.vue-resize-handle-ml,
.vue-resize-handle-mr,
.vue-resize-handle-tm,
.vue-resize-handle-bm,
.vue-resize-handle-br {
  &:focus, &:hover{
    background-color: white;
    transform: scale(1.5);
  }
}
</style>
