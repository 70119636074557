<template>
  <v-img :src="image" contain :width="200" />
</template>

<script>
export default {
  props: {
    primary: {
      type: String,
      default: () => '#48aae0'
    },
    secondary: {
      type: String,
      default: () => '#036fa7'
    }
  },

  computed: {
    image () {
      return 'data:image/svg+xml,' + encodeURIComponent(this.inlineSvg)
    },

    inlineSvg () {
      return `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <defs>
        <style>
            .cls-1 {
                fill: #3b2314;
            }
            .cls-2 {
                fill: ${this.primary};
            }
            .cls-3 {
                fill: ${this.secondary};
            }
            .cls-4 {
                fill: #d0d2d3;
            }
            .cls-5 {
                fill: #4f612b;
            }
        </style>
    </defs>
    <title>caddyhole-flag</title>
    <path class="cls-1" d="M140.38,466.2c0,5.42-2.53,9.82-5.65,9.82h0c-3.11,0-5.64-4.4-5.64-9.82V21.68c0-5.43,2.53-9.82,5.64-9.82h0c3.12,0,5.65,4.39,5.65,9.82Z" />
    <path class="cls-2" d="M139.49,13.18s10,59.57,113.87,32.39c0,0,82.91-35.58,117.55,11.65-1-1.33-7.38-1-8.72-1a181.82,181.82,0,0,0-21.27.79c-22.23,1.93-43.62,7.57-60.71,22.63a129.92,129.92,0,0,0-15,16C254,109.52,246,123,229.43,130.84a73,73,0,0,1-50.92,4.68,111.94,111.94,0,0,1-18.76-6.83c-2.42-1.1-20.26-8.48-20.26-10.62Z"
    />
    <path class="cls-3" d="M139.49,13.18V118.07c0,2.14,17.84,9.52,20.26,10.62a111.94,111.94,0,0,0,18.76,6.83,73,73,0,0,0,50.92-4.68C246,123,254,109.52,265.2,95.62,265.2,95.62,167.8,92.89,139.49,13.18Z" />
    <path class="cls-4" d="M221.78,474.18c0,7.71-20.77,14-46.34,14s-46.35-6.25-46.35-14,20.75-14,46.35-14S221.78,466.46,221.78,474.18Z" />
    <path class="cls-5" d="M175.44,464.65c22.55,0,41.34,5.24,45.47,12.19a4.86,4.86,0,0,0,.87-2.66c0-7.72-20.77-14-46.34-14s-46.35,6.25-46.35,14a4.92,4.92,0,0,0,.85,2.66C134.07,469.89,152.87,464.65,175.44,464.65Z" />
</svg>`
    }
  }
}
</script>