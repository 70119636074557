// Libraries
import router from '@/plugins/router'
import cookies from 'js-cookie'

// graphql files
import login from 'GraphQL/mutations/utils/login.gql'
import auth from 'GraphQL/queries/utils/auth.gql'

import { apollo } from '@/plugins/apollo'
import { i18n } from '@/plugins'

export default {
  logout ({ state, dispatch, commit, rootState }, payload) {
    commit('setLoading', true, { root: true })

    cookies.remove('api_token')
    cookies.remove('visibility')
    cookies.remove('categoryVisibility')

    router.push(`/${payload === undefined ? 'Platform' : payload}/Login`)

    commit('general/setAlerts', [], { root: true })
    commit('units/setList', [], { root: true })
    /// emptying the messages object
    commit('general/clearMessages', null, { root: true })

    commit('setLoading', false, { root: true })
  },

  async login ({ state, dispatch, commit, rootState }, payload) {
    commit('setLoading', true, { root: true })

    await apollo.mutate({
      mutation: login,
      variables: {
        username: payload.username,
        password: payload.password,
        language: rootState.language,
        app: payload.as
      }
    }).then((response) => {
      const { status, errors, result } = response.data.login

      switch (status) {
        case 'OK':
          cookies.set('api_token', result.apiToken)

          commit('setEntity', {
            ...result,
            loaded: true
          })

          dispatch('loadData')
          break

        case 'UNPROCESSABLE':
          if (errors.username) {
            if (errors.username[0] === 'Username or password not found') {
              commit('toggleSnackbar',
                {
                  message: errors.password[0],
                  asLogin: true,
                  color: 'error darken-4',
                  duration: 10000
                },
                {
                  root: true
                }
              )
              break
            }
            commit('toggleSnackbar',
              {
                message: `${i18n.t('users.username')} ${errors.username[0]}`,
                asLogin: true,
                color: 'error darken-4',
                duration: 10000
              },
              {
                root: true
              }
            )
          }
          if (errors.password) {
            commit('toggleSnackbar',
              {
                message: `${i18n.t('users.password')} ${errors.password[0]}`,
                asLogin: true,
                color: 'error darken-4',
                duration: 10000
              },
              {
                root: true
              }
            )
          }
          break
      }
    }).catch((error) => {
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      commit('setLoading', false, { root: true })
    })
  },

  async loadEntity ({ state, dispatch, commit, rootState }, payload) {
    const apiToken = cookies.get('api_token')

    if (apiToken !== undefined) {
      await apollo.query({
        query: auth,
        variables: {
          apiToken,
          language: rootState.language
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status, result } = response.data.auth
        // console.log(result)
        switch (status) {
          case 'OK':
            commit('setEntity', {
              ...result,
              loaded: true
            })

            dispatch('loadData', payload)
            break

          case 'UNPROCESSABLE':
            router.push('/Platform/Login')
            break
        }
      }).catch((error) => {
        commit('toggleSnackbar', undefined, { root: true })
      }).finally(() => {
        commit('setLoading', false, { root: true })
      })
    }
  },

  async loadData ({ state, dispatch, commit, rootState }, payload) {
    /**
     * CMS loads:
     *
     * Admin
     *  Consumer
     *  Icons
     *  Customers
     *  Categories
     *
     * Customer
     *  Operators
     *  Marshall
     *  Units
     *
     * Operator
     *  Marshall
     *  Units
     *
     * Platform always loads
     *  Units
     *  Icons
     *  RestrictedZones
     *  Messages
     *  Maintenance
     */

    const lastUrl = window.location.href.split('/#')[1]

    const currentApp = lastUrl.split('/')[1]

    /**
     * loads for both Operator and Customer
     * depending of current app
     */
    const loadCustomerOperator = async () => {
      // always loads
      await dispatch('units/getList', false, { root: true })
      await dispatch('icons/getList', false, { root: true })
      await dispatch('general/getProtocols', false, { root: true })
      await dispatch('general/getVisibility', false, { root: true })
      await dispatch('categories/getList', false, { root: true })
      await dispatch('general/getCategoryVisibility', false, { root: true })

      switch (currentApp) {
        case 'Platform':
          /**
           * powertrakker distance mobile mutation
           */
          await dispatch('units/getMobileList', false, { root: true })

          await dispatch('general/getMaintenances', false, { root: true })
          await dispatch('restrictedZones/getList', false, { root: true })
          await dispatch('commands/getList', false, { root: true })
          await dispatch('courses/getList', false, { root: true })
          // messages must be the last one
          await dispatch('general/getMessages', false, { root: true })

          await commit('setDrawer', false, { root: true })

          // getting the messages from the mobile distance app
          await dispatch('general/getMobileMessages', false, { root: true })

          break

        case 'CMS':
          await dispatch('marshalls/getList', false, { root: true })

          break
      }
    }

    // loads depending of user type
    switch (rootState.users.entity.__typename) {
      case 'Admin':
        await dispatch('customers/getList', false, { root: true })
        await dispatch('consumers/getList', false, { root: true })
        await loadCustomerOperator()
        break

      case 'Customer':
        await dispatch('operators/getList', false, { root: true })

        await loadCustomerOperator()

        break

      case 'Operator':
        await loadCustomerOperator()

        break
    }

    await commit('setLoading', false, { root: true })

    if (payload === undefined) {
      let redirect = lastUrl

      if (['/Platform/Login', '/CMS/Login'].includes(lastUrl)) {
        redirect = `${lastUrl.includes('/Platform') ? '/Platform/Home' : '/CMS/Home'}`
      }

      router.push(redirect)
    } else {
      if (payload.as === 'CMS') {
        router.push('/CMS/Home')
      } else {
        router.push('/Platform/Home')
      }
    }
  }
}
