<template>
  <v-expansion-panels
    v-model="currentPanel"
    dark
  >
    <v-expansion-panel class="grey-opacity">
      <v-expansion-panel-header>
        {{ $t(`headers.${$route.name}`) }}
      </v-expansion-panel-header>

      <!-- Expansion panel of the units table -->
      <v-expansion-panel-content class="pa-0">
        <div
          class="expansion__content scrollbar__seamless"
        >
          <v-btn text small @click="newItem">
            <v-icon small>mdi-plus-circle-outline</v-icon>
            &nbsp;
            {{ $t('actions.new') }}
          </v-btn>
          <v-btn text small @click="executeCommandMulti">
            <v-icon small>mdi-card-bulleted-settings-outline</v-icon>
            &nbsp;
            {{ $t('actions.executeCommand') }}
          </v-btn>
          <v-data-table
            :headers="headers"
            :items="units"
            multi-sort

            fixed-header
            height="60vh"
            class="transparent datatable__custom"
            :items-per-page="-1"
          >
            <!-- column headers -->
            <template #[`header.icon`]="{ header }">
              {{ header.text }}
            </template>

            <template #[`header.name`]="{ header }">
              {{ header.text }}
            </template>

            <template #[`header.category`]="{ header }">
              {{ header.text }}
            </template>

            <template #[`header.state`]="{ header }">
              {{ header.text }}
            </template>
            <template #[`header.actions`]>
              <v-icon small>mdi-wrench</v-icon>
            </template>
            <!-- /column headers -->

            <template v-slot:item="{ item }">
              <!-- row of table -->
              <tr :key="`units-unit-${item.id}`">
                <!-- Icon -->
                <td style="width: 35px;">
                  <v-avatar :size="30">
                    <v-img :src="item.icon.uri || '/car/render'" contain />
                  </v-avatar>
                </td>
                <!-- Unit Name -->
                <v-menu
                  close-on-content-click
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <td
                      class="white--text unit_name--container"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.name }}
                    </td>
                  </template>
                  <v-card dark class="unit_info__popup">
                    <gm-unit-information
                      :unit="item"
                      :message="messages[item.id]"
                    />
                  </v-card>
                </v-menu>
                <!-- Unit Type -->
                <td class="text-center white--text">
                  {{ $t(`units.categories.${item.category.toLowerCase()}`) }}
                </td>
                <!-- Unit is active/killed -->
                <td class="text-center white--text">
                  <div
                    :class="`${item.isKilled ? 'red' : 'green'} darken-2`"
                    style="width: 15px; height: 15px; border-radius: 15px; margin: 0 auto;"
                  />
                </td>
                <!-- Unit actions -->
                <td class="text-right white--text d-flex justify-center align-center">
                  <v-tooltip top color="orange darken-2">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on">
                        <v-icon small @click="openCommandsModule(item.id)">
                          mdi-message-settings-variant
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('actions.sendKill') }}</span>
                  </v-tooltip>

                  <v-tooltip top color="blue darken-2">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on">
                        <v-icon small @click="editItem(item.id)">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('actions.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip top color="red darken-4">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on">
                        <v-icon
                          small
                          color="red"
                          @click="openDeleteDialog(item.id)"
                        >
                          <!-- @click="deleteItem(item.id)" -->
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('actions.delete') }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <!-- Edit form -->
    <v-expansion-panel class="grey-opacity">
      <v-expansion-panel-header>{{ $t('actions.editor') }}</v-expansion-panel-header>

      <v-expansion-panel-content>
        <div class="form--expansion__content scrollbar__seamless">
          <template v-if="isEditting">
            <v-layout row wrap class="pt-2 mx-1">
              <v-flex xs12>
                <v-text-field
                  v-model="object.name"
                  :label="$t('units.name')"
                  :error="errors.name.length > 0"
                  :error-messages="errors.name"
                  outlined
                />
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="object.ident"
                  :label="$t('units.ident')"
                  :error="errors.ident.length > 0"
                  :error-messages="errors.ident"
                  outlined
                />
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="object.phone"
                  :label="$t('units.phone')"
                  :error="errors.phone.length > 0"
                  :error-messages="errors.phone"
                  outlined
                />
              </v-flex>
              
              <v-flex xs12>
                <v-select
                  v-model="object.iconId"
                  :label="$t('units.iconId')"
                  :error="errors.iconId.length > 0"
                  :error-messages="errors.iconId"
                  :items="icons"
                  item-value="id"
                  dark
                  outlined
                >
                  <template v-slot:item="{ item }">
                    <v-avatar :size="30">
                      <v-img :src="item.uri" contain />
                    </v-avatar>
                    
                    <v-divider vertical class="mx-2 my-1" />
                    {{ item.name }}
                  </template>

                  <template v-slot:selection="{ item }">
                    <v-avatar :size="30">
                      <v-img :src="item.uri" contain />
                    </v-avatar>
                    
                    <v-divider vertical class="mx-2 my-1" />
                    {{ item.name }}
                  </template>
                </v-select>
              </v-flex>

              <v-flex xs12>
                <v-select
                  v-model="object.protocolId"
                  :label="$t('units.protocolId')"
                  :items="protocols"
                  :error="errors.protocolId.length > 0"
                  :error-messages="errors.protocolId"
                  item-text="name"
                  item-value="id"
                  dark
                  outlined
                />
              </v-flex>

              <v-flex v-if="object.protocolId !== '4'" xs12>
                <v-select
                  v-model="object.nominalVoltage"
                  :label="$t('units.nominalVoltage')"
                  :items="nominalVoltages"
                  :error="errors.nominalVoltage.length > 0"
                  :error-messages="errors.nominalVoltage"
                  dark
                  outlined
                />
              </v-flex>

              <v-flex xs12>
                <v-select
                  v-model="object.category"
                  :label="$t('units.category')"
                  :items="categories"
                  :error="errors.category.length > 0"
                  :error-messages="errors.category"
                  dark
                  outlined
                />
              </v-flex>

              <v-flex v-if="isAdmin" xs12>
                <v-select
                  v-model="object.customerId"
                  :label="$t('units.customer')"
                  :items="customersList"
                  :error="errors.customerId.length > 0"
                  :error-messages="errors.customerId"
                  dark
                  outlined
                  item-text="username"
                  item-value="id"
                />
              </v-flex>

              <v-flex xs12 class="mb-3 d-flex justify-center">
                <v-btn text :loading="isLoading" color="error" @click="ignoreItem">{{ $t('actions.ignore') }}</v-btn>

                <v-btn text :loading="isLoading" color="success" @click="saveItem">{{ $t('actions.save') }}</v-btn>
              </v-flex>
            </v-layout>
          </template>

          <template v-else>
            <h4 class="text-center pa-2">{{ $t('helpers.notAllowed') }}</h4>
          </template>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-dialog
      v-model="isCommand"
      width="300px"
    >
      <v-card shaped>
        <v-card-title>{{ $t('commands.title') }}</v-card-title>

        <v-card-text>
          <v-autocomplete
            v-model="unitsIds"
            multiple
            :items="units"
            item-text="name"
            item-value="id"
            :label="$t('commands.units')"
            outlined
            :error="unitsIds.length === 0"
          />

          <v-select
            v-model="commandId"
            :items="commands"
            item-text="name"
            item-value="id"
            :label="$t('commands.command')"
            outlined
            hide-details
            :error="!commandId"
          />
        </v-card-text>

        <v-divider class="mx-2" />

        <v-card-actions>
          <v-btn
            text
            color="primary"
            :disabled="!commandId"
            block
            @click="sendCommand"
          >
            {{ $t('commands.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <gm-delete-dialog
      v-model="deleteDialog"
      :action="deleteItem"
      :name="unitToDeleteName"

      :title="$t('units.delete.title')"
      :caption="$t('units.delete.caption')"
      :confirm-message="$t('helpers.delete.dialog.confirm')"
      :options="[
        $t('helpers.delete.dialog.message1'),
        $t('helpers.delete.dialog.message2'),
        $t('helpers.delete.dialog.message3')
      ]"
      :id-to-delete="idToDelete"
      @clear="idToDelete = null"
    />
  </v-expansion-panels>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { Unit } from 'Components/utils'

import add from 'GraphQL/mutations/units/add.gql'
import edit from 'GraphQL/mutations/units/edit.gql'
import del from 'GraphQL/mutations/units/delete.gql'
import details from 'GraphQL/queries/units/details.gql'
import unitCommands from 'GraphQL/queries/commands/unit.gql'
import commands from 'GraphQL/queries/commands/list.gql'
import execute from 'GraphQL/mutations/commands/execute.gql'
import DeleteDialog from '../../components/utils/DeleteDialog.vue'

export default {
  components: {
    'gm-unit-information': Unit,
    'gm-delete-dialog': DeleteDialog
  },

  data () {
    return {
      singleMode: true,
      currentPanel: 0,
      commandId: null,
      object: {},
      unitsIds: [],
      commands: [],
      isEditting: false,
      isLoading: false,
      isCommand: false,
      rawErrors: {},
      deleteDialog: false,
      idToDelete: null
    }
  },

  computed: {
    /**
     * name of the unit to delete
     */
    unitToDeleteName () {
      const unit = this.units.find(unit => unit.id === this.idToDelete)
      if (unit) {
        return unit.name
      }
      return ''
    },

    ...mapGetters({
      defaultObject: 'units/defaultObject',
      protocolById: 'general/protocolById'
    }),
    ...mapState(['general', 'users', 'language', 'customers']),
    isAdmin () {
      return this.user.__typename === 'Admin'
    },
    protocols () {
      return this.general.protocols
    },

    messages () {
      return this.general.messages
    },

    units () {
      return this.$store.state.units.tableList
    },

    user () {
      return this.users.entity
    },

    icons () {
      return this.$store.state.icons.list
    },

    headers () {
      return [
        {
          text: this.$i18n.t('units.icon'),
          value: 'icon',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('units.name'),
          value: 'name',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('units.category'),
          value: 'category',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('units.state'),
          value: 'state',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('actions.actions'),
          value: 'actions',
          class: 'transparent text--center',
          filterable: false,
          sortable: false
        }
      ]
    },

    errors () {
      const errors = {}

      for (const key in this.defaultObject) {
        let keyCamel = key
        if (key.includes('_')) {
          keyCamel = this.convertToCamelCase(key)
        }

        if (this.rawErrors[key] === undefined) {
          errors[keyCamel] = ''
        } else {
          errors[keyCamel] = this.rawErrors[key].join(', ')
        }
      }
      console.log(errors)
      return errors
    },

    categories () {
      return [
        {
          text: this.$i18n.t('units.categories.player'),
          value: 'PLAYER'
        },
        {
          text: this.$i18n.t('units.categories.marshall'),
          value: 'MARSHALL'
        },
        {
          text: this.$i18n.t('units.categories.maintenance'),
          value: 'MAINTENANCE'
        },
        {
          text: this.$i18n.t('units.categories.personal'),
          value: 'PERSONAL'
        },
        {
          text: this.$i18n.t('units.categories.staff'),
          value: 'STAFF'
        }
      ]
    },

    nominalVoltages () {
      return [
        {
          text: this.$i18n.t('units.nominalVoltages.48V'),
          value: 'VOLT48'
        },
        {
          text: this.$i18n.t('units.nominalVoltages.36V'),
          value: 'VOLT36'
        },
        {
          text: this.$i18n.t('units.nominalVoltages.GAS'),
          value: 'GAS'
        }
      ]
    },

    customersList () {
      return this.customers.list
    }
  },

  watch: {
    currentPanel (newValue, oldValue) {
      if (newValue === 0 && this.isEditting) {
        this.isEditting = false
        this.object = Object.assign({}, this.defaultObject)
      }
    },

    isCommand (newValue, oldValue) {
      if (!newValue) {
        this.commands = []
        this.commandId = null
        this.unitId = null
      }
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
  },

  // mounted () {
  //   // this.newItem()
  //   // this.openCommandsModule(5)
  // },

  methods: {
    /**
     * Open the delete dialog to confirm a delete
     */
    openDeleteDialog (id) {
      this.idToDelete = id
      this.deleteDialog = true
    },

    openCommandsModule (unitId) {
      this.singleMode = true
      this.$store.commit('setLoading', true)
      this.$apollo.query({
        query: unitCommands,
        variables: {
          apiToken: this.user.apiToken,
          language: this.language,
          unitId
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status, result } = response.data.unitCommands

        switch (status) {
          case 'OK':
            this.unitsIds = [unitId]
            this.commands = result
            this.isCommand = true
            break

          default:
            this.$store.commit('toggleSnackbar', undefined)
            break
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar', undefined)
      }).finally(() => {
        this.$store.commit('setLoading', false)
      })
    },

    executeCommandMulti () {
      this.singleMode = false
      this.$store.commit('setLoading', true)
      this.$apollo.query({
        query: commands,
        variables: {
          apiToken: this.user.apiToken,
          language: this.language
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status, result } = response.data.commands

        switch (status) {
          case 'OK':
            this.unitsIds = []
            this.commands = result
            this.isCommand = true
            break

          default:
            this.$store.commit('toggleSnackbar', undefined)
            break
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar', undefined)
      }).finally(() => {
        this.$store.commit('setLoading', false)
      })
    },

    sendCommand () {
      this.$store.commit('setLoading', true)

      this.$apollo.mutate({
        mutation: execute,
        variables: {
          apiToken: this.user.apiToken,
          language: this.language,
          unitsIds: this.unitsIds,
          commandId: this.commandId
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status } = response.data.executeCommand

        if (status === 'OK') {
          this.$store.commit('toggleSnackbar', {
            duration: 6000,
            message: this.$i18n.t('commands.success'),
            color: 'green darken-2'
          })

          this.$store.dispatch('units/getList')
          this.isCommand = false
        } else {
          this.$store.commit('toggleSnackbar', undefined)
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar', undefined)
      }).finally(() => {
        this.$store.commit('setLoading', false)
      })
    },

    convertToCamelCase (str) {
      str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, (match) => {
        return match.charAt(match.length - 1).toUpperCase()
      })
      return str.charAt(0).toLowerCase() + str.substring(1)
    },

    newItem () {
      this.currentPanel = 1
      this.isEditting = true
      this.object = Object.assign({}, this.defaultObject)
    },

    async editItem (id) {
      this.object = Object.assign({}, this.defaultObject)

      await this.$apollo.query({
        query: details,
        variables: {
          apiToken: this.user.apiToken,
          language: this.language,
          id
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status, result } = response.data.unit

        switch (status) {
          case 'OK':
            this.currentPanel = 1
            this.isEditting = true
            this.object = Object.assign({}, result)
            break

          default:
            this.$store.commit('toggleSnackbar', undefined)
            break
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar', undefined)
      })
    },

    async deleteItem (id) {
      this.object = Object.assign({}, this.defaultObject)

      await this.$apollo.mutate({
        mutation: del,
        variables: {
          apiToken: this.user.apiToken,
          language: this.language,
          id
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status } = response.data.deleteUnit

        switch (status) {
          case 'OK':
            this.ignoreItem()
            // reseting delete dialog
            this.idToDelete = null
            this.deleteDialog = false

            this.$store.dispatch('units/getList', true)
            break

          default:
            this.$store.commit('toggleSnackbar', undefined)
            break
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar', undefined)
      })
    },

    ignoreItem () {
      this.currentPanel = 0
      this.isEditting = false
      this.object = Object.assign({}, this.defaultObject)
    },

    async saveItem () {
      this.rawErrors = {}
      this.isLoading = true
      
      let method = add
      let name = 'addUnit'

      if (this.object.id !== undefined) {
        method = edit
        name = 'editUnit'
      }

      const variables = {
        apiToken: this.user.apiToken,
        language: this.language,
        data: this.object
      }

      delete variables.data.__typename

      await this.$apollo.mutate({
        mutation: method,
        variables,
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status, errors } = response.data[name]
        const errorProcessed = {}
        switch (status) {
          case 'OK':
            this.$store.dispatch('units/getList', true)
            // this.ignoreItem()
            setTimeout(() => this.ignoreItem(), 0)

            break

          case 'UNPROCESSABLE':
            for (const key in errors) {
              errorProcessed[this.convertToCamelCase(key)] = errors[key]
            }
            this.rawErrors = errorProcessed
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('errors.invalidFields'),
              color: 'warning'
            })
            break

          default:
            this.$store.commit('toggleSnackbar', undefined)
            break
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar', undefined)
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/_scrollbar_chrome';

$name_width: 120px;

.unit_name--container{
  min-width: $name_width;
  max-width: $name_width;
}

</style>