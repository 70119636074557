const assets = {}

assets.install = (Vue) => {
  Vue.prototype.$assets = {
    favicon: require('Assets/favicon.svg'),
    robot: require('Assets/robot.png'),
    faviconWhite: require('Assets/favicon-white.svg'),
    background: require('Assets/golf.png')
  }
}

export default assets