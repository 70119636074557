<template>
  <v-expansion-panels v-model="currentPanel" dark>
    <v-expansion-panel class="grey-opacity">
      <v-expansion-panel-header>
        {{ $t(`headers.${$route.name}`) }}
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pa-0">
        <div
          class="scrollbar__seamless expansion__content"
        >
          <v-data-table
            :headers="headers"
            :items="data"
            fixed-header
            multi-sort
            sort-by="unit"
            class="transparent datatable__custom"
            :items-per-page="-1"
            height="55vh"
          >
            <template v-slot:item="{ item }">
              <tr>
                <!-- Cart name -->
                <td class="white--text text-center">
                  {{ item.unit }}
                </td>

                <!-- Cart Run Time -->
                <td class="white--text text-center">
                  {{ $conversor.formatHours(item.runtime) }}
                </td>

                <!-- Ignition status -->
                <td class="white--text text-center">
                  <v-chip
                    small
                    :color="`${item.ignition ? 'green' : 'red'} darken-2`"
                  >
                    {{ $t(`helpers.states.${item.ignition}`) }}
                  </v-chip>
                </td>

                <!-- Movement status -->
                <td class="white--text text-center">
                  <v-chip
                    small
                    :color="`${item.movement ? 'green' : 'red'} darken-2`"
                  >
                    {{ $t(`helpers.movement.${item.movement}`) }}
                  </v-chip>
                </td>

                <!-- Battery level -->
                <td class="white--text text-center">
                  {{ item.batteryLevel }}%
                </td>

                <!-- Is charging -->
                <td class="white--text text-center">
                  <v-chip
                    small
                    :color="`${item.isCharging ? 'green' : 'red'} darken-2`"
                  >
                    {{ $t(`helpers.charging.${item.isCharging}`) }}
                  </v-chip>
                </td>

                <!-- Milage -->
                <td class="white--text text-center">
                  {{ item.mileage | roundMile }} miles
                </td>

                <!-- Crashes -->
                <td
                  class="white--text text-center"
                >
                  <div
                    :class="{
                      'counter__circle d-flex justify-center align-center': true,
                      'red darken-2' : item.crashes > 0
                    }"
                  >
                    {{ item.crashes }}
                  </div>
                </td>

                <td class="white--text text-center">{{ item.rounds }}</td>

                <td class="white--text text-center">{{ item.location }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  filters: {
    roundMile (km) {
      // kilometers to miles and rounded
      return Math.round(km * 0.62137)
    }
  },

  data () {
    return {
      currentPanel: 0
    }
  },

  computed: {
    ...mapState(['general']),
    ...mapGetters({
      units: 'units/byId',
      courses: 'courses/byId',
      holes: 'courses/holesById'
    }),

    headers () {
      return [
        {
          text: this.$i18n.t('maintenances.cart'),
          value: 'unit',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('maintenances.runtime'),
          value: 'runtime',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('maintenances.ignition'),
          value: 'ignition',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('maintenances.movement'),
          value: 'movement',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('maintenances.batteryLevel'),
          value: 'batteryLevel',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('maintenances.isCharging'),
          value: 'isCharging',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('maintenances.mileage'),
          value: 'mileage',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('maintenances.crashes'),
          value: 'crashes',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('maintenances.rounds'),
          value: 'rounds',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('maintenances.location'),
          value: 'location',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        }
      ]
    },

    data () {
      const result = []
      const maintenances = this.general.maintenances

      for (const i in maintenances) {
        const maintenance = maintenances[i]
        const unitId = maintenance.unitId

        if (this.units[unitId] === undefined) {
          continue
        }

        let location = 'NA'

        const course = this.courses[maintenance.courseId]

        if (course !== undefined) {
          const hole = this.holes[maintenance.holeId]
          if (hole !== undefined) {
            location = `${course.name} (#${hole.name})`
          } else {
            location = course.name
          }
        }

        // console.log(maintenance.batteryLevel)

        result.push({
          unit: this.units[unitId].name,
          runtime: maintenance.engineRuntime,
          ignition: maintenance.engineStatus,
          movement: maintenance.isMoving,
          batteryLevel: parseInt(maintenance.batteryLevel * 100) / 100,
          isCharging: maintenance.isCharging,
          mileage: maintenance.mileage,
          crashes: maintenance.crashes,
          rounds: maintenance.rounds,
          location
        })
      }

      return result
    }
    // user () {
    //   return this.users.entity
    // }
  }
}
</script>

<style lang="scss" scoped>
.expansion__content{
  max-height: calc(100vh - 260px);
  padding: 0 10px;
  overflow: hidden;
}
</style>