<template>
  <v-card>
    <v-card-title>
      {{ $t(`${name}.title.list`) }}

      <v-spacer />

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary"
            icon
            small
            text
            v-on="on"
            @click="$emit('new-item')"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>

        <span>{{ $t(`${name}.title.new`) }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            color="warning"
            icon
            small
            text
            v-on="on"
            @click="$emit('reload-list')"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>

        <span>{{ $t('actions.reload') }}</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text>
      <v-row
        no-gutters
        class="px-2"
      >
        <v-combobox
          v-model="searchField"
          prepend-icon="mdi-magnify"
          :placeholder="$t('helpers.search')"
          multiple
          :label="$t('actions.find')"
          outlined
        >
          <template v-slot:selection="data">
            <v-chip
              :key="JSON.stringify(data.item)"
              v-bind="data.attrs"
            >
              <template v-if="data.item.includes(searchSymbol)">
                <v-chip
                  small
                  :class="[
                    { 'ma-0 mr-2 white--text' : true },
                    { 'red darken-2' : headersTitle[data.item.split(searchSymbol)[0].toLowerCase()] === undefined },
                    { 'green darken-2' : headersTitle[data.item.split(searchSymbol)[0].toLowerCase()] !== undefined }
                  ]"
                  left
                >
                  {{ data.item.split(searchSymbol)[0] }}
                </v-chip>
                {{ data.item.split(searchSymbol)[1] }}
              </template>
              <template v-else>
                {{ data.item }}
              </template>
            </v-chip>
          </template>
        </v-combobox>
        <v-menu
          offset-x
          :close-on-content-click="false"
          :open-on-hover="true"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="mt-2"
              icon
              v-on="on"
            >
              <v-icon>
                mdi-help-circle-outline
              </v-icon>
            </v-btn>
          </template>
          <v-card style="width: 300px;">
            <v-card-title>
              {{ $t('helpers.table.searchBarHintTitle') }}
            </v-card-title>
            <v-card-text
              style="white-space: pre-line;"
              v-html="$t('helpers.table.searchBarHintContent')"
            />
          </v-card>
        </v-menu>
      </v-row>

      <v-data-table
        :headers="headers"
        :search="JSON.stringify(searchField)"
        :options.sync="pagination"
        :custom-filter="filter"
        :items="data"
        :dark="isDark"
      >
        <template v-slot:body="{ items }">
          <tr v-for="(item, i) in items" :key="`row-${i}`" :class="[{'white--text' : isDark}]">
            <td>{{ i + 1 }}</td>
            <template v-for="header in columns">
              <td
                :key="`row-${i}-${header.value}`"
                :class="[
                  { 'text-center' : header.alignment === 'center' },
                  { 'text-left' : header.alignment === 'start' },
                  { 'text-right' : header.alignment === 'end' }
                ]"
              >
                <template v-if="header.type === 'badge'">
                  <v-chip :color="`${header.color === undefined ? 'primary' : header.color(item[header.value])}`">
                    <template v-if="header.formatter === undefined">
                      {{ item[header.value] }}
                    </template>
                    
                    <template v-else>
                      {{ header.formatter(item[header.value]) }}
                    </template>
                  </v-chip>
                </template>

                <template v-else-if="header.type === 'image'">
                  <img :src="item[header.value]" style="width: 50px;">
                </template>

                <template v-else>
                  <template v-if="header.formatter === undefined">
                    {{ item[header.value] }}
                  </template>
                  
                  <template v-else>
                    {{ header.formatter(item[header.value]) }}
                  </template>
                </template>
              </td>
            </template>
            
            <td v-if="hasActions" class="text-right">
              <v-btn
                v-if="item.actions.show"
                small
                icon
                text
                :color="`blue ${isDark ? '' : 'darken-4'}`"
                @click="$emit('toggle-action', { action: 'show', id: item.id })"
              >
                <v-icon>mdi-file-find-outline</v-icon>
              </v-btn>

              <v-btn
                v-if="item.actions.edit"
                small
                icon
                text
                :color="`warning`"
                @click="$emit('toggle-action', { action: 'edit', id: item.id })"
              >
                <v-icon>mdi-file-document-edit-outline</v-icon>
              </v-btn>

              <v-btn
                v-if="item.actions.delete"
                small
                icon
                text
                :color="`red ${isDark ? '' : 'darken-4'}`"
                @click="$emit('toggle-action', { action: 'delete', id: item.id })"
              >
                <v-icon>mdi-file-cancel-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    name: {
      type: String,
      required: true
    },

    hasActions: {
      type: Boolean,
      required: false,
      default: () => false
    },

    rows: {
      type: Array,
      required: false,
      default: () => []
    },

    itemsPerPage: {
      type: Number,
      required: false,
      default: () => 10
    },

    actionsAccess: {
      type: Object,
      required: false,
      default: () => { return { show: true, edit: false, delete: false } },
      validator (value) {
        if (value.show === null || value.edit === undefined || value.delete === undefined) {
          return false
        }

        return true
      }
    },

    columns: {
      type: Array,
      required: true,
      validator (columns) {
        // {
        //   type: 'normal', // 'normal', 'badge' - Optional
        //   formatter: (value) => value, // Optional
        //   color: (value) => 'primary', // Optional
        //   alignment: 'center', // 'start', 'center', 'end' - Optional
        //   title: '', // Mandatory
        //   isSortable: false, // Optional
        //   value: '' // Mandatory
        // }

        for (const i in columns) {
          if (columns[i].title === undefined || columns[i].value === undefined) {
            return false
          }

          if (columns[i].alignment !== undefined && !['start', 'center', 'end'].includes(columns[i].alignment)) {
            return false
          }
        
          if (columns[i].type !== undefined && !['normal', 'badge', 'image', 'slot'].includes(columns[i].type)) {
            return false
          }
          
          if (columns[i].formatter !== undefined && (typeof columns[i].formatter) !== 'function') {
            return false
          }

          if (columns[i].color !== undefined && (typeof columns[i].color) !== 'function') {
            return false
          }

          if (columns[i].isSortable !== undefined && (typeof columns[i].isSortable) !== 'boolean') {
            return false
          }
        }

        return true
      }
    }
  },

  data () {
    return {
      searchField: [],
      searchSymbol: '='
    }
  },

  computed: {
    ...mapState(['isDark']),

    data () {
      return this.rows.map((item, i) => {
        return {
          ...item,
          actions: this.actionsAccess
        }
      })
    },

    pagination: {
      get () {
        return { itemsPerPage: this.itemsPerPage }
      },
      set (value) {
        this.$emit('new-items-per-page', value.itemsPerPage)
      }
    },

    headersTitle () {
      const headers = {}

      for (const i in this.headers) {
        headers[this.headers[i].text.toLowerCase()] = this.headers[i]
      }

      return headers
    },

    headers () {
      if (this.hasActions) {
        return [
          {
            sortable: true,
            align: 'center',
            text: '#',
            value: 'id',
            width: 30
          },
          ...this.columns.map((column, i) => {
            return {
              sortable: column.isSortable || false,
              align: column.alignment || 'center',
              text: column.title,
              value: column.value
            }
          }),
          {
            sortable: false,
            align: 'end',
            text: this.$i18n.t('actions.title')
          }
        ]
      } else {
        return [
          {
            sortable: true,
            align: 'center',
            text: '#',
            value: 'id',
            width: 30
          },
          ...this.columns.map((column, i) => {
            return {
              sortable: column.isSortable || false,
              align: column.alignment || 'center',
              text: column.title,
              value: column.value
            }
          })
        ]
      }
    }
  },

  methods: {
    filter (value, search, item) {
      // console.log(value)
      // console.log(search)
      // console.log(item)

      if (this.searchField.length === 0) {
        return true
      }

      for (const i in this.searchField) {
        if (this.searchField[i].includes(this.searchSymbol)) {
          const search = this.searchField[i].split(this.searchSymbol)

          if (this.headersTitle[search[0].toLowerCase()] === undefined || item[this.headersTitle[search[0].toLowerCase()].value] === undefined) {
            continue
          } else {
            if (item[this.headersTitle[search[0].toLowerCase()].value].toLowerCase().includes(search[1].toLowerCase())) {
              return true
            }
          }
        } else {
          for (const k in item) {
            let value = item[k]
            if ((typeof value) === 'object') {
              continue
            } else if ((typeof value) === 'number') {
              value = value.toString()
            }

            if (value.toLowerCase().includes(this.searchField[i].toLowerCase())) {
              return true
            }
          }
        }
      }

      return false
    }
  }
}
</script>