/**
 * Class to draw tooltips in the map
 *
 * must be called after window.google already exists
 * preferably with require('path/TooltipDrawer.js')
 */
class TooltipDrawer extends window.google.maps.OverlayView {
  constructor (google, map, position, vueInstance) {
    super()
    this._map = map
    this._position = new google.maps.LatLng(position.latitude, position.longitude)

    this._google = google

    this._html = null

    this.vueInstance = vueInstance

    this.setMap(map)
  }

  setState (state) {
    const overlayProjection = this.getProjection()

    const pos = overlayProjection.fromLatLngToDivPixel(this._position)

    const width = window.innerWidth / 2
    const height = window.innerHeight / 2

    this.vueInstance.tooltip.x = width + pos.x + 10
    this.vueInstance.tooltip.y = height + pos.y

    this.vueInstance.tooltip.state = state

    this.draw()
  }

  getState () {
    return this.vueInstance.tooltip.state
  }

  draw () {
    const overlayProjection = this.getProjection()

    let pos = { x: 0, y: 0 }
    try {
      pos = overlayProjection.fromLatLngToDivPixel(this._position)
    } catch (error) {
      // console.error('LabelDrawer Error' + error.toString())
    }

    const width = window.innerWidth / 2
    const height = window.innerHeight / 2
    let finalHeight = height + pos.y
    if ((finalHeight + 341) > window.innerHeight) {
      finalHeight -= 341
    }
    this.vueInstance.tooltip.x = width + pos.x + 10
    this.vueInstance.tooltip.y = finalHeight
  }

  setPosition (position) {
    this._position = new this._google.maps.LatLng(position.latitude, position.longitude)

    this.draw()
  }

  onRemove () {
    this.vueInstance.tooltip.state = false
  }
}

module.exports = TooltipDrawer
