
export default {
  version (state) {
    return `${state.version.local.version}-${state.version.local.branch}`
  },

  isMobile (state) {
    return state.width.full <= 930
  },

  clock (state) {
    let clock = ''
    const time = state.clock

    if (time.hours === undefined) {
      return 'Initializing'
    }

    if (time.hours > 9) {
      clock += time.hours
    } else {
      clock += `0${time.hours}`
    }

    clock += ':'

    if (time.minutes > 9) {
      clock += time.minutes
    } else {
      clock += `0${time.minutes}`
    }

    clock += ':'

    if (time.seconds > 9) {
      clock += time.seconds
    } else {
      clock += `0${time.seconds}`
    }

    return clock
  }
}