// Main imports
import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Locales
import messages from '@/locales'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'en',
  messages
})
