<template>
  <v-expansion-panels v-model="currentPanel" dark>
    <v-expansion-panel class="grey-opacity">
      <v-expansion-panel-header>
        {{ $t('courses.paceOfPlay.title') }}
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pa-0">
        <div class="expansion__content scrollbar__seamless" style="max-height: calc(100vh - 195px); overflow-y: scroll;">
          <v-list color="transparent">
            <v-list-item v-for="(hole, key) in holes" :key="`hole-${key}`" dense>
              <v-list-item-content>
                <v-text-field
                  v-model="holes[key].paceOfPlay"
                  :label="$t('courses.paceOfPlay.name', { number: hole.position })"
                  outlined
                  hide-details
                  dense
                  type="number"
                  :suffix="$t('courses.paceOfPlay.unit')"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      currentPanel: 0,
      currentEditting: undefined
    }
  },

  computed: {
    ...mapState(['courses']),

    holes: {
      get () {
        const holes = Object.values(Object.assign({}, this.courses.holes))

        holes.sort((a, b) => { return a.position < b.position ? -1 : 1 })

        return holes
      },

      set (value) {
        // console.log('Valores', value)
        this.$store.commit('courses/setHoles', value)
      }
    }
  },

  mounted () {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'courses/setHoles') {
        this.currentEditting = undefined
        this.$store.commit('toggleMap', {
          key: undefined,
          state: false,
          information: [],
          type: 'hole'
        })
      }
    })
  },

  methods: {
    handleUpdate () {
      // console.log('Handle', this.holes[10])
      this.$store.commit('courses/setHoles', this.holes)
    },

    toggleEditor (key) {
      if (this.currentEditting === undefined) {
        this.currentEditting = key
        this.$store.commit('toggleMap', {
          key,
          state: true,
          information: [],
          type: 'hole'
        })
      } else {
        this.currentEditting = undefined
        this.$store.commit('toggleMap', {
          key: undefined,
          state: false,
          information: [],
          type: 'hole'
        })
      }
    }
  }
}
</script>