import Vue from 'vue'
import Vuex from 'vuex'

import { state, mutations, actions, getters, modules } from '@/vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules
})
