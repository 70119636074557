import units from './units'
import general from './general'
import users from './users'
import consumers from './consumers'
import marshalls from './marshalls'
import icons from './icons'
import courses from './courses'
import restrictedZones from './restrictedZones'
import commands from './commands'
import categories from './categories'
import trips from './trips'
import customers from './customers'
import operators from './operators'

export default {
  units,
  general,
  users,
  consumers,
  marshalls,
  icons,
  courses,
  restrictedZones,
  commands,
  categories,
  trips,
  customers,
  operators
}