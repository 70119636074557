import cms from './cms'
import platform from './platform'
import NotFound from '@/views/NotFound.vue' 

export default [
  {
    path: '/',
    redirect: '/Platform/Login'
  },    
  ...cms,
  ...platform,
  {
    path: '*',
    component: NotFound
  }
]