<template>
  <v-navigation-drawer
    v-model="drawer"
    color="primary"
    dark
    overflow
    app
    mobile-break-point="930"
    :temporary="isMobile"
    :permanent="!isMobile"
    :mini-variant="isMini"
    :expand-on-hover="isMini"
    class="elevation-5"
  >
    <!-- Sidebar header -->
    <v-list-item two-line>
      <v-list-item-avatar>
        <v-avatar height="40" contain>
          <v-img :src="$assets.faviconWhite" contain />
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-title>
        {{ $t('app.title') }}
      </v-list-item-title>
    </v-list-item>

    <!-- /Sidebar header -->

    <v-divider class="mx-3 mb-2" />

    <!-- Sidebar user -->
    <v-list-group>
      <template v-slot:activator>
        <v-list-item-avatar>
          <v-avatar :color="`${isDark ? 'grey darken-4' : 'white black--text'}`">
            {{ avatar }}
          </v-avatar>
        </v-list-item-avatar>

        <v-list-item-title class="white--text text-truncate">
          {{ fullName }}
        </v-list-item-title>
      </template>

      <v-list-item two-line @click="$store.dispatch('users/logout', 'CMS')">
        <v-list-item-avatar>
          <v-icon>mdi-lock</v-icon>
        </v-list-item-avatar>
        
        <v-list-item-title>
          {{ $t('users.signOut') }}
        </v-list-item-title>
      </v-list-item>
    </v-list-group>
    <!-- /Sidebar user -->

    <v-divider class="mx-3 my-1" />
  
    <!-- Sidebar options -->
    <v-list shaped dense dark>
      <v-list-item
        v-for="(link, i) in links"
        :key="`link-${i}`"
        :to="link.path"
        class="my-1"
        :active-class="`${isDark ? 'selected-sidebar-dark' : 'selected-sidebar'}`"
      >
        <v-list-item-avatar>
          <v-icon size="30">{{ link.icon }}</v-icon>
        </v-list-item-avatar>

        <v-list-item-title style="font-size: 0.9em">
          {{ link.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <!-- /Sidebar options -->

    <v-spacer />

    <!-- Sidebar tools -->

    <template v-if="!isMobile" v-slot:append>
      <v-divider class="mb-1 mx-3" />

      <v-list-item @click="$store.commit('setMini', !isMini)">
        <v-list-item-avatar>
          <v-icon>mdi-chevron-double-{{ isMini ? 'right' : 'left' }}</v-icon>
        </v-list-item-avatar>

        <v-list-item-title>
          {{ $t(`actions.sidebar.${isMini ? 'expand' : 'contract'}`) }}
        </v-list-item-title>
      </v-list-item>
    </template>
    
    <!-- /Sidebar tools -->
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState(['users', 'isMini', 'isDark']),
    ...mapGetters({
      avatar: 'users/avatar',
      fullName: 'users/fullName'
    }),
    ...mapGetters(['isMobile']),

    user () {
      return this.users.entity
    },

    drawer: {
      get () {
        return this.$store.state.drawer
      },

      set (value) {
        this.$store.commit('setDrawer', value)
      }
    },

    links () {
      let links = []
      switch (this.user.__typename) {
        case 'Admin':
          links = [
            {
              title: this.$i18n.t('headers.cmsHome'),
              path: '/CMS/Home',
              icon: 'mdi-home'
            },
            {
              title: this.$i18n.t('headers.consumers'),
              path: '/CMS/Consumers',
              icon: 'mdi-account-circle'
            },
            {
              title: this.$i18n.t('headers.customers'),
              path: '/CMS/Customers',
              icon: 'mdi-account-group'
            },
            {
              title: this.$i18n.t('headers.icons'),
              path: '/CMS/Icons',
              icon: 'mdi-image-search-outline'
            },
            {
              title: this.$i18n.t('headers.cmsCategories'),
              path: '/CMS/Categories',
              icon: 'mdi-go-kart'
            },
            {
              title: this.$i18n.t('headers.cmsUnits'),
              path: '/CMS/Units',
              icon: 'mdi-go-kart'
            }
          ]
          break
        case 'Customer':
          links = [
            {
              title: this.$i18n.t('headers.cmsHome'),
              path: '/CMS/Home',
              icon: 'mdi-home'
            },
            {
              title: this.$i18n.t('headers.cmsMarshalls'),
              path: '/CMS/Marshalls',
              icon: 'mdi-account-badge-horizontal'
            },
            {
              title: this.$i18n.t('headers.operators'),
              path: '/CMS/Operators',
              icon: 'mdi-account-multiple'
            },
            {
              title: this.$i18n.t('headers.cmsUnits'),
              path: '/CMS/Units',
              icon: 'mdi-go-kart'
            }
          ]
          break
        case 'Operator':
          links = [
            {
              title: this.$i18n.t('headers.cmsHome'),
              path: '/CMS/Home',
              icon: 'mdi-home'
            },
            {
              title: this.$i18n.t('headers.cmsMarshalls'),
              path: '/CMS/Marshalls',
              icon: 'mdi-account-badge-horizontal'
            },
            {
              title: this.$i18n.t('headers.cmsUnits'),
              path: '/CMS/Units',
              icon: 'mdi-go-kart'
            }
          ]
          break
      }
      return links
    }
  },

  created () {
    // console.log(this.avatar, this.fullName)
  }
}
</script>