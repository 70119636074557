<template>
  <v-flex xs12 sm6 md4>    
    <v-card dark class="pa-3 transparent elevation-0">
      <v-card-title primary-title>
        <img :src="$assets.faviconWhite" height="30">

        <v-divider vertical class="mx-2 grey lighten-1" />

        <small>{{ $t(`spaces.${$route.path.includes('/Platform') ? 'platform' : 'cms'}`) }}</small>
      </v-card-title>

      <v-card-text>
        <v-layout row wrap class="mt-8">
          <v-flex xs12 class="mb-5">
            <v-text-field
              v-model="credentials.username"
              :label="$t('users.username')"
              outlined
              hide-details
              @keydown.enter="handleLogin"
            />
          </v-flex>

          <v-flex xs12>
            <v-text-field
              v-model="credentials.password"
              :label="$t('users.password')"
              outlined
              type="password"
              hide-details
              @keydown.enter="handleLogin"
            />
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions style="display: flex; justify-content: flex-end;">
        <v-btn large outlined @click="handleLogin">{{ $t('users.signIn') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
// import { mapActions } from 'vuex'

export default {
  data: () => ({
    credentials: {
      username: '',
      password: '',
      as: 'CMS'
    }
  }),

  mounted () {
    this.$store.commit('setLoading', false)
  },

  methods: {
    handleLogin () {
      this.$store.dispatch('users/login', this.credentials)
    }
  }
}
</script>
