import Vue from 'vue'
import App from './App.vue'
import { i18n, vuetify, assets, router, store, googlemaps, cookies, apollo, tokens, conversor } from '@/plugins'

import DatetimePicker from '@goldenm/vuetify-datetime-picker'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import '@/assets/application.scss'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false

Vue.use(DatetimePicker)
Vue.use(assets)
Vue.use(googlemaps)
Vue.use(cookies)
Vue.use(tokens)
Vue.use(conversor)

new Vue({
  i18n,
  vuetify,
  router,
  store,
  apolloProvider: apollo,
  watch: {
    $route (newVal, oldVal) {
      document.title = `${this.$i18n.t(`headers.${newVal.name}`)} | ${this.$i18n.t('app.title')}`

      if (oldVal.name === 'settings') {
        this.$store.commit('setHolesEditting', false)
        this.$store.commit('clearDrawing')
      }
    }
  },

  beforeCreate () {
    window.addEventListener('resize', () => {
      this.$store.commit('setWidth', window.innerWidth)

      if (window.innerWidth <= 930) {
        this.$store.commit('setMini', false)
      }
    })

    const cookies = this.$cookies.get('state')
    if (cookies !== undefined) {
      this.$store.commit('setCookies', cookies === 'false')
    }

    const dark = this.$cookies.get('dark')
    if (dark !== undefined) {
      this.$store.commit('setDark', dark === 'true')
      this.$vuetify.theme.dark = dark === 'true'
    }
  },

  created () {
    const apiToken = this.$cookies.get('api_token')

    if (apiToken !== undefined) {
      this.$store.dispatch('users/loadEntity')
    } else {
      this.$router.push('/Platform/Login')
      this.$store.commit('setLoading', false)
    }
  },

  mounted () {
    document.title = `${this.$i18n.t('app.title')}`
  },

  beforeDestroy () {
    window.removeEventListener('resize')
  },

  render: h => h(App)
}).$mount('#app')
