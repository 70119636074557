<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    disable-resize-watcher
    :permanent="permanent"
    :clipped="clipped"
    :mini-variant="miniVariant"
    class="elevation-3 better-scrollbar"
    :dark="dark"
    :color="backgroundColor"
    :expand-on-hover="expandOnHover"
  >
    <!-- Sidebar header -->
    <v-list-item v-if="isMobile" two-line>
      <v-list-item-avatar>
        <v-avatar height="40" contain>
          <v-img :src="logo" contain />
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-title>
        {{ name }}
      </v-list-item-title>
    </v-list-item>

    <!-- /Sidebar header -->

    <v-divider class="mx-3" />

    <!-- Sidebar user -->
    <v-list-group v-if="isMobile" active-class="transparent">
      <template #activator>
        <v-list-item-avatar>
          <v-avatar
            v-if="hasPicture"
            class="elevation-1"
            size="40"
            style="cursor: pointer;"
          >
            <v-img :src="avatar" cover />
          </v-avatar>

          <v-avatar
            v-else
            class="elevation-1"
            color="primary white--text"
            size="40"
            style="cursor: pointer;"
          >
            {{ avatar }}
          </v-avatar>
        </v-list-item-avatar>

        <v-list-item-title :class="{'text-truncate transparent': true, 'white--text': dark}">
          {{ fullName }}
        </v-list-item-title>
      </template>

      <template v-for="(action, i) in actions">
        <v-list-item
          v-if="action.action"
          :key="`action-heder-${i}`"
          dense
          @click="action.action"
        >
          <small>{{ action.name }}</small>
        </v-list-item>
        <v-list-item
          v-else-if="action.to"
          :key="`action-heder-${i}`"
          dense
          :to="action.to"
        >
          <small>{{ action.name }}</small>
        </v-list-item>
      </template>
    </v-list-group>
    <!-- /Sidebar user -->

    <v-divider class="mx-3" />
    <!-- Mobile mode links -->
    <v-list v-if="isMobile">
      <template
        v-for="(link, index) in mobileLinks"
      >
        <v-list-item
          v-if="!link.divider && !link.spacer && link.can"
          :key="'link__'+index"
          class="link"
          active-class="link--selected"
          link
          :to="link.to"
        >
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  {{ link.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>{{ link.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>{{ link.name }}</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item
          v-if="link.spacer"
          :key="'spacing__'+index"
          class="spacer"
        />
        <div
          v-if="link.divider && link.sidebar"
          :key="'divider__'+index"
          class="divider"
        />
      </template>
    </v-list>

    <!-- Link list -->
    <v-list>
      <template
        v-for="(link, index) in links"
      >
        <template v-if="expandOnHover">
          <v-list-item
            v-if="!link.divider && !link.spacer && link.can"
            :key="'link__'+index"
            class="link"
            active-class="link--selected"
            link
            :to="link.to"
          >
            <v-list-item-icon>
              <v-icon>
                {{ link.icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ link.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item
            v-if="!link.divider && !link.spacer && link.can"
            :key="'link__'+index"
            class="link"
            active-class="link--selected"
            link
            :to="link.to"
          >
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-list-item-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    {{ link.icon }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-title>{{ link.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <span>{{ link.name }}</span>
            </v-tooltip>
          </v-list-item>
        </template>
        <v-list-item
          v-if="link.spacer"
          :key="'spacing__'+index"
          class="spacer"
        />
        <div
          v-if="link.divider && link.sidebar"
          :key="'divider__'+index"
          class="divider"
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: () => false
    },
    permanent: {
      type: Boolean,
      default: () => false
    },
    clipped: {
      type: Boolean,
      default: () => false
    },
    miniVariant: {
      type: Boolean,
      default: () => false
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    backgroundColor: {
      type: String,
      default: () => 'white'
    },
    links: {
      type: Array,
      default: () => []
    },
    mobileLinks: {
      type: Array,
      default: () => []
    },
    state: {
      type: Boolean,
      default: () => false
    },
    actions: {
      type: Array,
      default: () => []
    },
    avatar: {
      type: String,
      default: () => 'NA'
    },
    logo: {
      type: String,
      required: true
    },
    hasPicture: {
      type: Boolean,
      default: () => false
    },
    fullName: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      required: true
    },
    hasWhitelabel: {
      type: Boolean,
      default: () => false
    },
    expandOnHover: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      randomNumber: 0
    }
  },
  computed: {
    drawer: {
      get () {
        return this.state
      },

      set (value) {
        this.$emit('set-state', value)
      }
    }
  },
  watch: {
    user () {
      this.randomNumber = parseInt(Math.random() * 1000000)
    }
  }
}
</script>

<style lang="scss" scoped>

.divider {
  border-color: var(--v-accent-base);
  margin: 10px 5px;
  box-sizing: border-box;
  border-style: dotted;
  border-width: 0 0 5px 0;
}

.spacer {
  height: 24px;
  min-height: 24px;
}

.link{
  transition: all 0.2s;
}

.link--selected {
  background-color: var(--v-primary-base);
  color: #FFF !important;
  font-weight: 700;

  &::before{
    opacity: 0 !important;
  }
}

</style>