export default {
  protocols: [],
  /**
   * Units last messages
   */
  messages: {},
  /**
   * Unit messages with position
   */
  positionMessages: {},
  positionMessages2: [],
  activeUnits: [],
  maintenances: {},
  gameControl: [],
  /**
   * game control para el home
   */
  parsedGameControl: {},
  unitsLocation: {},

  alerts: [],
  visibility: [
    'carts',
    'courses',
    'holes',
    'restricted'
  ],
  categoryVisibility: [
    'PLAYER',
    'MARSHALL',
    'MAINTENANCE',
    'STAFF',
    'PERSONAL'
  ],
  /**
   * values to load the selector for the first time
   * when the page is loaded
   */
  defaultVisibility: [],
  defaultCategoryVisibility: [],
  /**
   * Value to fix the tooltip to a corner of the screen
   * possible values
   *  'NONE',
      'TOPLEFT',
      'TOPRIGHT',
      'BOTTOMLEFT',
      'BOTTOMRIGHT'
    */
  tooltipCorner: 'NONE'
}