<template>
  <div style="background: blue; width: 100%; height: 100%;">
    <div
      id="map"
      ref="map"
      style="height: 100%; width: 100%;"
    />
    <v-menu
      ref="tooltip"
      :value="tooltip.state"
      :position-x="tooltipXPosition"
      :position-y="tooltipYPosition"
      absolute
      color="grey darken-4"
    >
      <v-card dark class="unit_info__popup">
        <gm-unit-information
          v-if="tooltip.unitId"
          :unit="unit"
          :message="messagesByUid[unit.id]"
        />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { Unit } from 'Components/utils'

export default {
  components: {
    'gm-unit-information': Unit
  },

  data () {
    return {
      key: undefined,
      drawer: undefined,
      module: undefined,

      tooltip: {
        state: false,
        x: 0,
        y: 100,
        unitId: undefined
      },

      map: null, // Google Maps Map entity
      google: null, // Google Maps object

      type: undefined,

      marker: null,
      isEditting: false,

      california: null,

      operation: {
        markers: {},
        labels: {},
        geofences: {},
        tooltips: {},
        icons: {}
      },

      drawing: {
        labels: {},
        geofences: {}
      },

      overlayClass: {},

      LabelDrawer: null,
      HoleLabelDrawer: null,
      TooltipDrawer: null,

      // trip data
      // currentTrip: null,
      // tripBeginMarker: null,
      // tripEndMarker: null,
      tripCrashMarkers: [],

      tripsByUnits: {},
      // end trip data

      currentTooltipName: null,
      hasLoaded: false
    }
  },

  computed: {
    ...mapState(['courses', 'general', 'restrictedZones', 'hasTripDrawed']),
    ...mapGetters({
      units: 'units/byId',
      isMobile: 'isMobile'
    }),
    /**
     *'NONE',
      'TOPLEFT',
      'TOPRIGHT',
      'BOTTOMLEFT',
      'BOTTOMRIGHT'
     */
    tooltipCorner () {
      return this.$store.state.general.tooltipCorner
    },
    tooltipXPosition () {
      switch (this.tooltipCorner) {
        case 'TOPLEFT':
          return 0
        case 'TOPRIGHT':
          return window.innerWidth - 605
        case 'BOTTOMLEFT':
          return 0
        case 'BOTTOMRIGHT':
          return window.innerWidth - 605
      }
      return this.tooltip.x
    },
    tooltipYPosition () {
      switch (this.tooltipCorner) {
        case 'TOPLEFT':
          return 60
        case 'TOPRIGHT':
          return 60
        case 'BOTTOMLEFT':
          return window.innerHeight - 280
        case 'BOTTOMRIGHT':
          return window.innerHeight - 280
      }
      return this.tooltip.y
    },
    /**
     * Mensajes de la unidad parseados a tener la informacion
     * para ser mostradas en el mapa
    */
    positionMessages () {
      return this.general.positionMessages
    },
    coursesMap () {
      return this.courses.list
    },

    restrictedMap () {
      return this.restrictedZones.list
    },

    activeUnits () {
      return this.general.activeUnits
    },
    /**
     *
     */
    holesDrawing () {
      const holes = {}

      for (const i in this.courses.holes) {
        holes[this.courses.holes[i].position] = this.courses.holes[i]
      }

      return Object.assign({}, holes)
    },

    unit () {
      return this.units[this.tooltip.unitId]
    },

    enabledUnits () {
      return this.$store.state.units.enabled
    },

    categoryVisibility () {
      return this.general.categoryVisibility
    },

    visibility () {
      return this.general.visibility
    },

    globalType () {
      return this.restrictedZones.type
    },

    courseDrawing () {
      return this.courses.geofence
    },

    restrictedDrawing () {
      return this.restrictedZones.geofence
    },

    // rawMessages () {
    //   return this.general.messages
    // },

    edittingId () {
      return this.$store.state.courses.edittingId
    },

    edittingRestrictedId () {
      return this.$store.state.restrictedZones.edittingId
    },

    messagesByUid () {
      return this.general.messages
    },

    previewHoleColor () {
      return this.courses.holeColor
    },

    previewCourseColor () {
      return this.courses.courseColor
    }
  },

  watch: {
    activeUnits () {
      this.toggleUnits()
    },

    visibility () {
      this.setVisibility()
    },

    categoryVisibility () {
      this.setVisibility()
    },
    positionMessages () {
      this.renderItems()
    },

    units () {
      this.renderItems()
    },

    isEditting (newVal, oldVal) {
      if (newVal) {
        this.startDrawing()
      } else {
        if (oldVal) {
          this.stopDrawing()
        }
      }
    },

    coursesMap () {
      if (this.google) {
        // console.log('watched coursesMap')
        setTimeout(() => this.renderCourses(), 0)
        setTimeout(() => this.clearDrawing(), 0)
        setTimeout(() => this.renderCourses(), 100)
        setTimeout(() => this.focusCourse(), 200)
      }
    },

    restrictedMap () {
      if (this.google) {
        setTimeout(() => this.renderRestricted(), 0)
        setTimeout(() => this.clearDrawing(), 0)
      }
    },

    holesDrawing () {
      if (this.google) {
        this.renderDrawingHoles()
      }
    },

    courseDrawing () {
      if (this.google) {
        this.renderDrawingCourse()
      }
    },

    restrictedDrawing () {
      if (this.google) {
        this.renderDrawingRestricted()
      }
    },
    previewHoleColor () {
      if (this.google) {
        this.renderDrawingHoles()
      }
    },
    previewCourseColor () {
      if (this.google) {
        this.renderDrawingCourse()
      }
    },

    globalType () {
      if (this.google) {
        this.renderDrawingRestricted()
      }
    }
  },

  beforeCreate () {
    // console.log('before create map')
    // window.vueTestMap = this

    /* eslint-disable no-undef, no-unused-vars */
    // loads google maps API
    this.$googlemaps
      .load()
      .then(() => {
        this.google = google
        // to use the label drawer "class" window.google must exist and the file is called to be require
        window.google = google
        this.LabelDrawer = require('./LabelDrawer.js')
        this.HoleLabelDrawer = require('./HoleLabelDrawer.js')
        this.TooltipDrawer = require('./TooltipDrawer.js')

        this.initMap()
        this.initDrawer()

        setTimeout(() => {
          this.renderItems()
          this.renderDrawingHoles()
          this.renderDrawingCourse()

          this.renderCourses()
          this.renderRestricted()
          this.focusCourse()
        }, 500)
      })
    /* eslint-enable */
  },

  mounted () {
    // window.vueMap = this
    // Subscripcion a Vuex para pintar en el mapa segun se efectuen cambios
    this.$store.subscribe(mutation => {
      const payload = mutation.payload
      if (mutation.type === 'toggleMap') {
        this.key = payload.key
        this.isEditting = payload.state
        this.type = payload.type
        this.module = payload.module
      } else if (mutation.type === 'clearDrawing') {
        // console.log(mutation)
        this.clearDrawing()
        this.renderCourses()
        this.renderRestricted()
      } else if (mutation.type === 'trips/drawMultipleTrips') {
        // drawMultipleTrips
        this.drawMultipleTrips(payload)
      } else if (mutation.type === 'trips/cleanAllTrips') {
        // cleanAllTrips
        this.cleanAllTrips()
      } else if (mutation.type === 'focusMap') {
        this.focusMap(payload)
      }
    })
  },

  methods: {
    /** focus map at lat lng  */
    focusMap ({ lat, lng }) {
      this.map.setCenter(new this.google.maps.LatLng(lat, lng))
      // zoom in
      this.map.setZoom(20)
    },
    /**
     * Dibujar multiples recorridos en el mapa
     *
     */
    drawMultipleTrips (trips) {
      if (this.hasTripDrawed) {
        this.cleanAllTrips()
      }
      this.$store.commit('setTripState', true)

      this.tripsByUnits = {}
      for (let i = 0; i < trips.length; i++) {
        const trip = trips[i]
        this.tripsByUnits[trip.unitId] = {}
        const path = trip.path
        const mapTrip = new this.google.maps.Polyline({
          path,
          geodesic: true,
          strokeColor: trip.color,
          strokeOpacity: 1.0,
          strokeWeight: 2
        })

        mapTrip.setMap(this.map)

        for (let index = 0; index < path.length; index++) {
          if (path[index].isCrashed) {
            const crashMarker = new this.google.maps.Marker({
              position: {
                lat: path[index].lat,
                lng: path[index].lng
              },
              map: this.map,
              icon: new this.google.maps.MarkerImage(
                '/static/alert-octagram.svg',
                new this.google.maps.Size(100, 100),
                null,
                new this.google.maps.Point(40, 30),
                new this.google.maps.Size(82, 40)
              )
            })
            this.tripCrashMarkers.push(crashMarker)
          }
        }

        const beginMarker = new this.google.maps.Marker({
          position: {
            lat: path[0].lat,
            lng: path[0].lng
          },
          map: this.map,
          icon: new this.google.maps.MarkerImage(
            '/static/marker-begin.svg',
            new this.google.maps.Size(100, 100),
            null,
            new this.google.maps.Point(40, 30),
            new this.google.maps.Size(82, 40)
          )
        })

        const endMarker = new this.google.maps.Marker({
          position: {
            lat: path[path.length - 1].lat,
            lng: path[path.length - 1].lng
          },
          map: this.map,
          icon: new this.google.maps.MarkerImage(
            '/static/marker-end.svg',
            new this.google.maps.Size(100, 100),
            null,
            new this.google.maps.Point(40, 30),
            new this.google.maps.Size(82, 40)
          )
        })

        this.tripsByUnits[trip.unitId].trip = mapTrip
        this.tripsByUnits[trip.unitId].beginMarker = beginMarker
        this.tripsByUnits[trip.unitId].endMarker = endMarker
      }
    },
    /**
     * Limpiar todos los recorridos dibujados en el mapa
     */
    cleanAllTrips () {
      // key = unitsID
      for (const key in this.tripsByUnits) {
        if (Object.hasOwnProperty.call(this.tripsByUnits, key)) {
          this.tripsByUnits[key].trip.setMap(null)
          this.tripsByUnits[key].beginMarker.setMap(null)
          this.tripsByUnits[key].endMarker.setMap(null)

          delete this.tripsByUnits[key]
        }
      }
      for (let i = 0; i < this.tripCrashMarkers.length; i++) {
        this.tripCrashMarkers[i].setMap(null)
      }
      this.tripCrashMarkers = []
      this.$store.commit('setTripState', false)
    },
    /**
     * Enfoca el mapa en un course
     */
    focusCourse () {
      // console.log('calling focusCourse')
      if (!this.hasLoaded && this.coursesMap.length > 0) {
        this.hasLoaded = true
        const points = this.coursesMap[0].geofence.points
        const bounds = new this.google.maps.LatLngBounds()

        // for each point include it as LatLng in the bounds object
        for (let i = 0; i < points.length; i++) {
          bounds.extend(new this.google.maps.LatLng(
            points[i].latitude,
            points[i].longitude
          ))
        }
        this.map.fitBounds(bounds)
      }
    },

    /**
     * Render courses in the map
     */
    renderCourses () {
      const courses = this.coursesMap
      let path
      let center
      let icon
      const coursesKeys = []
      const holesKeys = []

      for (const i in courses) {
        path = courses[i].geofence.points

        if (path.length > 0) {
          center = this.calculateCenter(path)

          icon = (
            (courses[i].icon === null || courses[i].icon === undefined)
              ? null
              : courses[i].icon.uri
          )

          if (this.operation.geofences[`course-${courses[i].id}`] === undefined) {
            // mostrar la geocerca solo si no posee un supercourse

            if (!courses[i].parentId) {
              this.operation.geofences[`course-${courses[i].id}`] = this.drawPolygon(
                this.google,
                path,
                courses[i],
                courses[i].courseColor
              )
            }

            if (!icon) {
              // mostrar el nombre solo si no posee un supercourse
              if (!courses[i].parentId) {
                this.operation.labels[`course-${courses[i].id}`] = this.drawName(
                  this.google,
                  center,
                  courses[i].name,
                  'geofence'
                )
              }
            } else {
              // mostrar el nombre con icono solo si no posee un supercourse
              if (!courses[i].parentId) {
                this.renderItem(
                  `course-${courses[i].id}`,
                  icon,
                  center,
                  courses[i].name
                )
              }
            }
          } else {
            this.redrawPolygon(
              this.google,
              this.operation.geofences[`course-${courses[i].id}`],
              path,
              courses[i].courseColor
            )

            if (icon === null) {
              this.operation.labels[`course-${courses[i].id}`].setPosition(center)
            } else {
              this.redrawItem(
                `course-${courses[i].id}`,
                center,
                icon,
                courses[i].name
              )
            }
          }

          coursesKeys.push(`course-${courses[i].id}`)
        }

        const holes = courses[i].holes

        // Drawing all holes of current course
        for (const j in holes) {
          path = holes[j].geofence.points

          center = this.calculateCenter(path)

          if (this.operation.geofences[`hole-${holes[j].id}`] === undefined) {
            this.operation.geofences[`hole-${holes[j].id}`] = this.drawPolygon(
              this.google,
              path,
              courses[i].holeColor
            )

            // si no posee supercourse
            if (!courses[i].parentId) {
              this.operation.labels[`hole-${holes[j].id}`] = this.drawHoleLabel(
                this.google,
                center,
                holes[j].name
              )
            } else {
              // es un subcourse
              this.operation.labels[`hole-${holes[j].id}`] = this.drawName(
                this.google,
                center,
                `${courses[i].name}-${holes[j].name}`,
                'geofence'
              )
            }
          } else {
            this.redrawPolygon(
              this.google,
              this.operation.geofences[`hole-${holes[j].id}`],
              path,
              courses[i].holeColor
            )

            this.operation.labels[`hole-${holes[j].id}`].setPosition(center)
          }

          holesKeys.push(`hole-${holes[j].id}`)
        }
      }
      /**
       * Cleaning the unused coursed and holes geofences
       */
      let totalUnusedKeys = Object.keys(this.operation.geofences)
      totalUnusedKeys = totalUnusedKeys.filter((item) => {
        return !(coursesKeys.includes(item) || holesKeys.includes(item) || item.includes('restricted-'))
      })

      for (const i in totalUnusedKeys) {
        if (this.operation.geofences[totalUnusedKeys[i]]) {
          this.operation.geofences[totalUnusedKeys[i]].setMap(null)
          this.operation.geofences[totalUnusedKeys[i]] = undefined
        }

        if (this.operation.markers[totalUnusedKeys[i]]) {
          this.operation.markers[totalUnusedKeys[i]].setMap(null)
          this.operation.markers[totalUnusedKeys[i]] = undefined
        }

        if (this.operation.labels[totalUnusedKeys[i]]) {
          this.operation.labels[totalUnusedKeys[i]].setMap(null)
          this.operation.labels[totalUnusedKeys[i]] = undefined
        }
      }
    },
    /**
     * Render restrited geofence in the map
     */
    renderRestricted () {
      const geofences = this.restrictedMap
      let path
      let center
      const restrictedKeys = []

      for (const i in geofences) {
        path = geofences[i].points
        // debugger

        if (path.length > 0) {
          // console.log('path length', path.length)

          center = this.calculateCenter(path)

          // console.log(`Restricted ${geofences[i].name}`, { lat: center.latitude, lng: center.longitude })

          if (this.operation.geofences[`restricted-${geofences[i].id}`] === undefined) {
            this.operation.geofences[`restricted-${geofences[i].id}`] = this.drawPolygon(
              this.google,
              path,
              this.calculateColor(geofences[i].type.toUpperCase())
            )
            // console.log(geofences[i].type.toLowerCase())

            if (geofences[i].name !== '') {
              this.operation.labels[`restricted-${geofences[i].id}`] = this.drawName(
                this.google,
                center,
                geofences[i].name,
                'geofence'
              )
            }
          } else {
            this.redrawPolygon(
              this.google,
              this.operation.geofences[`restricted-${geofences[i].id}`],
              path,
              this.calculateColor(geofences[i].type)
            )

            if (geofences[i].name !== '') {
              this.operation.labels[`restricted-${geofences[i].id}`].setPosition(center)
            }
          }

          restrictedKeys.push(`restricted-${geofences[i].id}`)
        }
      }

      /**
       * Cleaning the unused restricted geofences
       */
      let totalUnusedKeys = Object.keys(this.operation.geofences)

      totalUnusedKeys = totalUnusedKeys.filter((item) => {
        return !(restrictedKeys.includes(item) || item.includes('course-') || item.includes('hole-'))
      })

      for (const i in totalUnusedKeys) {
        if (this.operation.geofences[totalUnusedKeys[i]]) {
          this.operation.geofences[totalUnusedKeys[i]].setMap(null)
          this.operation.geofences[totalUnusedKeys[i]] = undefined
        }

        if (this.operation.markers[totalUnusedKeys[i]]) {
          this.operation.markers[totalUnusedKeys[i]].setMap(null)
          this.operation.markers[totalUnusedKeys[i]] = undefined
        }

        if (this.operation.labels[totalUnusedKeys[i]]) {
          this.operation.labels[totalUnusedKeys[i]].setMap(null)
          this.operation.labels[totalUnusedKeys[i]] = undefined
        }
      }
    },
    /**
     * Render a holes geofences in the map
     * when creating and editing
     */
    renderDrawingHoles () {
      const geofences = this.holesDrawing

      for (const i in geofences) {
        if (geofences[i].geofence.length === 0) {
          continue
        }

        const path = geofences[i].geofence

        const center = this.calculateCenter(path)

        if (geofences[i].id !== undefined && this.operation.geofences[`hole-${geofences[i].id}`] !== undefined) {
          if (this.operation.geofences[`hole-${geofences[i].id}`]) {
            this.operation.geofences[`hole-${geofences[i].id}`].setMap(null)
            this.operation.geofences[`hole-${geofences[i].id}`] = undefined
          }

          if (this.operation.labels[`hole-${geofences[i].id}`]) {
            this.operation.labels[`hole-${geofences[i].id}`].setMap(null)
            this.operation.labels[`hole-${geofences[i].id}`] = undefined
          }
        }

        if (this.drawing.geofences[`hole-${i}`] === undefined) {
          this.drawing.geofences[`hole-${i}`] = this.drawPolygon(
            this.google,
            path,
            this.calculateColor('HOLE')
          )

          this.drawing.labels[`hole-${i}`] = this.drawName(
            this.google,
            center,
            this.$i18n.t('courses.draw.editting', { name: geofences[i].name }),
            undefined,
            'geofence'
          )
        } else {
          this.redrawPolygon(
            this.google,
            this.drawing.geofences[`hole-${i}`],
            path,
            this.calculateColor('HOLE')
          )

          this.drawing.labels[`hole-${i}`].setPosition(center)
        }
      }
    },
    /**
     * Render a course geofence in the map
     * when creating and editing
     */
    renderDrawingCourse () {
      if (this.courseDrawing.length !== 0) {
        const path = this.courseDrawing

        const center = this.calculateCenter(path)

        let name = ''

        if (this.edittingId !== undefined && this.operation.geofences[`course-${this.edittingId}`] !== undefined) {
          if (this.operation.geofences[`course-${this.edittingId}`]) {
            this.operation.geofences[`course-${this.edittingId}`].setMap(null)
            this.operation.geofences[`course-${this.edittingId}`] = undefined
          }

          if (this.operation.labels[`course-${this.edittingId}`]) {
            name = this.operation.labels[`course-${this.edittingId}`].getName()
            this.operation.labels[`course-${this.edittingId}`].setMap(null)
            this.operation.labels[`course-${this.edittingId}`] = undefined
          }
        }
        // console.log(this.drawing.geofences.course);

        if (this.drawing.geofences.course === undefined) {
          this.drawing.geofences.course = this.drawPolygon(
            this.google,
            path,
            this.calculateColor('COURSE')
          )

          this.drawing.labels.course = this.drawName(
            this.google,
            center,
            this.edittingId === undefined
              ? this.$i18n.t('courses.courseObj')
              : this.$i18n.t('courses.draw.editting', { name }),
            undefined,
            'geofence'
          )
        } else {
          this.redrawPolygon(
            this.google,
            this.drawing.geofences.course,
            path,
            this.calculateColor('COURSE')
          )

          this.drawing.labels.course.setPosition(center)
        }
      }
    },
    /**
     * Render a Restricted geofence in the map
     * when creating and editing
     */
    renderDrawingRestricted () {
      if (this.restrictedDrawing.length !== 0) {
        const path = this.restrictedDrawing

        const center = this.calculateCenter(path)

        let name = ''

        if (
          this.edittingRestrictedId !== undefined &&
          this.operation.geofences[`restricted-${this.edittingRestrictedId}`] !== undefined
        ) {
          this.operation.geofences[`restricted-${this.edittingRestrictedId}`].setMap(null)
          this.operation.geofences[`restricted-${this.edittingRestrictedId}`] = undefined

          name = this.operation.labels[`restricted-${this.edittingRestrictedId}`].getName()

          this.operation.labels[`restricted-${this.edittingRestrictedId}`].setMap(null)
          this.operation.labels[`restricted-${this.edittingRestrictedId}`] = undefined
        }

        if (this.drawing.geofences.restricted === undefined) {
          this.drawing.geofences.restricted = this.drawPolygon(
            this.google,
            path,
            // this.calculateColor(this.type || this.globalType)
            this.calculateColor(this.globalType)
          )

          this.drawing.labels.restricted = this.drawName(
            this.google,
            center,
            this.edittingRestrictedId === undefined
              ? this.$i18n.t('restrictedZones.restrictedObj')
              : this.$i18n.t('restrictedZones.draw.editting', { name }),
            'geofence'
          )
        } else {
          this.redrawPolygon(
            this.google,
            this.drawing.geofences.restricted,
            path,
            // this.calculateColor(this.type || this.globalType)
            this.calculateColor(this.globalType)
          )

          this.drawing.labels.restricted.setPosition(center)
        }
      }
    },

    /**
     * Verify if point of coordinates (longitude, latitude) is polygon of coordinates
     * https://github.com/substack/point-in-polygon/blob/master/index.js
     * @param {number} latitude Latitude
     * @param {number} longitude Longitude
     * @param {array<[number,number]>} polygon Polygon contains arrays of points. One array have the following format: [latitude,longitude]
     */
    isPointInPolygon (latitude, longitude, polygon) {
      if (typeof latitude !== 'number' || typeof longitude !== 'number') {
        throw new TypeError('Invalid latitude or longitude. Numbers are expected')
      } else if (!polygon || !Array.isArray(polygon)) {
        throw new TypeError('Invalid polygon. Array with locations expected')
      } else if (polygon.length === 0) {
        throw new TypeError('Invalid polygon. Non-empty Array expected')
      }

      const x = latitude; const y = longitude

      let inside = false
      for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i][0]; const yi = polygon[i][1]
        const xj = polygon[j][0]; const yj = polygon[j][1]

        const intersect = ((yi > y) !== (yj > y)) &&
                (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
        if (intersect) inside = !inside
      }

      return inside
    },

    /**
     * Render messages in the map
     */
    renderItems () {
      const messages = this.positionMessages

      Object.entries(this.positionMessages).forEach((entry) => {
        const key = entry[0]
        try {
          const message = messages[key]
          const unit = this.units[key]

          const isMobile = unit?.protocol?.name.toUpperCase() === 'MOBILE'

          const icon = (unit.icon === undefined ? '/car/render' : unit.icon.uri)
          const unitNameInMap = isMobile ? `Mobile-${unit.name.slice(0, 3)}` : unit.name

          /**
          * Si el marker no existe lo pinta por primera vez
          */
          if (this.operation.markers[`unit-${unit.id}`] === undefined) {
            const currentTime = new Date().getTime()
            const lastTime = new Date(message.receivedAt * 1000).getTime()

            const timeDiffInMinutes = (currentTime - lastTime) / 60000

            // If it is a powetrakker distance mobile unit
            if (isMobile) {
              // and it is not in a hole dont show it
              if (!(message.geofence.hole.id)) {
                return
              } else if (timeDiffInMinutes > 15) {
                // if the message is older than 15 minutes dont show it
                // else if it is in a hole and the last message was too long ago dont show it
                return
              }
            }

            this.renderItem(
              `unit-${unit.id}`,
              icon,
              messages[key].position,
              unitNameInMap, // fullname in map
              unit.id,
              'car'
            )
          } else {
            if (isMobile) {
              if (!(message.geofence.hole.id)) {
                // console.log('isMobile 2: ', isMobile)
                // console.log(`removing unit from map: `,messages[i].unit)
                /**
                * si la unidad de distance
                * salio de un hoyo
                * removerla del mapa
                */
                this.operation.markers[`unit-${messages[key].unit.id}`].setMap(null)
                this.operation.markers[`unit-${messages[key].unit.id}`] = null
                return
              }
            }
            const center = {
              latitude: messages[key].position.latitude,
              longitude: messages[key].position.longitude
            }

            this.redrawItem(
              `unit-${messages[key].unit.id}`,
              center,
              icon,
              unitNameInMap // fullname in map
            )
          }
        } catch (error) {
          console.log('error', error)
        }
      })

      this.toggleUnits()
    },
    /**
     * Toogle units view in the map
     */
    toggleUnits () {
      const active = this.activeUnits

      let latitude = 0
      let longitude = 0

      for (const i in active) {
        if (this.operation.markers[`unit-${active[i]}`]) {
          const center = this.operation.markers[`unit-${active[i]}`].getPosition()

          latitude += center.lat()
          longitude += center.lng()
        }
      }

      if (active.length > 0) {
        latitude /= active.length
        longitude /= active.length

        this.map.setCenter({ lat: latitude, lng: longitude })
        this.map.setZoom(active.length > 1 ? 14 : 18)
      }
    },
    /**
     * Update visibility of objects in the map
     */
    setVisibility () {
      const visibility = this.visibility
      const categoryVisibility = this.categoryVisibility

      for (const i in this.operation.geofences) {
        if (i.includes('course-')) {
          if (!visibility.includes('courses')) {
            if (this.operation.markers[i] !== undefined) {
              this.operation.markers[i].setMap(null)
            }

            this.operation.labels[i].setMap(null)
            this.operation.geofences[i].setMap(null)
          } else {
            const currentMap = this.operation.geofences[i].getMap()

            if (!currentMap) {
              if (this.operation.markers[i] !== undefined) {
                this.operation.markers[i].setMap(this.map)
              }
              this.operation.labels[i].setMap(this.map)
              this.operation.geofences[i].setMap(this.map)
            }
          }
        } else if (i.includes('hole-')) {
          if (!visibility.includes('holes')) {
            this.operation.labels[i].setMap(null)
            this.operation.geofences[i].setMap(null)
          } else {
            const currentMap = this.operation.geofences[i].getMap()

            if (!currentMap) {
              this.operation.labels[i].setMap(this.map)
              this.operation.geofences[i].setMap(this.map)
            }
          }
        } else {
          if (!visibility.includes('restricted')) {
            if (this.operation.labels[i] !== undefined) {
              this.operation.labels[i].setMap(null)
            }
            this.operation.geofences[i].setMap(null)
          } else {
            const currentMap = this.operation.geofences[i].getMap()

            if (!currentMap) {
              if (this.operation.labels[i] !== undefined) {
                this.operation.labels[i].setMap(this.map)
              }
              this.operation.geofences[i].setMap(this.map)
            }
          }
        }
      }

      for (const i in this.operation.markers) {
        if (i.includes('unit-')) {
          const id = i.split('-')[1]

          const category = this.units[id].category

          if (!visibility.includes('carts') || !categoryVisibility.includes(category)) {
            this.operation.markers[i].setMap(null)
            this.operation.labels[i].setMap(null)
          } else {
            const currentMap = this.operation.markers[i].getMap()

            if (!currentMap) {
              this.operation.markers[i].setMap(this.map)
              this.operation.labels[i].setMap(this.map)
            }
          }
        }
      }
    },
    /**
     * Render item in map
     *
     * name
     * icon
     * position
     * fullName
     * unitId
     * type
     */
    renderItem (name, icon, position, fullName, unitId, type = 'geofence') {
      this.renderIcon(name, icon)

      this.operation.markers[name] = new this.google.maps.Marker({
        position: {
          lat: position.latitude,
          lng: position.longitude
        },
        map: this.map,
        icon: this.operation.icons[name]
      })

      if (type === 'car') {
        this.operation.tooltips[name] = this.drawTooltip(this.google, position)

        this.operation.markers[name].addListener('click', () => {
          this.toggleUnitTooltip(name, unitId)
        })
      }

      this.operation.labels[name] = this.drawName(
        this.google,
        position,
        fullName
      )
    },
    /**
     * Show and hide current unit tooltip
     */
    toggleUnitTooltip (name, unitId) {
      if (this.operation.tooltips[name].getState()) {
        // hide tooltip
        this.hideTooltip(name)
        // this.currentTooltipName = undefined
        // this.tooltip.unitId = undefined
        // this.operation.tooltips[name].setState(false)
      } else {
        // show tooltip
        this.currentTooltipName = name
        this.tooltip.unitId = unitId
        this.operation.tooltips[name].setState(true)
      }
    },
    hideTooltip (name) {
      this.operation.tooltips[name].setState(false)
      this.currentTooltipName = undefined
      this.tooltip.unitId = undefined
    },
    hideCurrentTooltip () {
      if (this.currentTooltipName) {
        this.hideTooltip(this.currentTooltipName)
      }
    },
    /**
     * Render icon in the map
     */
    renderIcon (name, icon) {
      this.operation.icons[name] = new this.google.maps.MarkerImage(
        icon,
        new this.google.maps.Size(100, 100),
        null,
        new this.google.maps.Point(40, 30),
        new this.google.maps.Size(82, 40)
      )
    },
    /**
     * Redraw item in the map
     */
    redrawItem (name, position, icon, fullName) {
      this.operation.markers[name].setPosition({
        lat: position.latitude,
        lng: position.longitude
      })

      if (this.operation.markers[name].getIcon().url !== icon) {
        this.renderIcon(name, icon)
        this.operation.markers[name].setIcon(this.operation.icons[name])
      }

      if (fullName !== undefined && this.operation.labels[name].getName() !== fullName) {
        this.operation.labels[name].setName(fullName)
      }

      this.operation.labels[name].setPosition(position)
    },
    /**
     * Clear drawing from the map
     */
    clearDrawing () {
      for (const i in this.drawing.labels) {
        this.drawing.labels[i].setMap(null)
      }

      for (const i in this.drawing.geofences) {
        this.drawing.geofences[i].setMap(null)
      }

      this.stopDrawing()

      this.type = undefined

      this.drawing = {
        labels: {},
        geofences: {}
      }
    },
    /**
     * Calculates the center of geofence
     */
    calculateCenter (path) {
      const first = path[0]
      const last = path[path.length - 1]

      if (first.latitude !== last.latitude || first.longitude !== last.longitude) {
        path.push(first)
      }

      let twicearea = 0
      let latitude = 0
      let longitude = 0
      const quantity = path.length
      let p1
      let p2
      let f

      for (let i = 0, j = quantity - 1; i < quantity; j = i++) {
        p1 = path[i]
        p2 = path[j]

        f = p1.longitude * p2.latitude - p2.longitude * p1.latitude

        twicearea += f

        latitude += (p1.latitude + p2.latitude) * f
        longitude += (p1.longitude + p2.longitude) * f
      }

      f = twicearea * 3

      return {
        longitude: longitude / f,
        latitude: latitude / f
      }
    },
    /**
     * Initialize Singleton instance of Google maps
     */
    initMap () {
      this.$store.commit('setGoogle', this.google)

      this.california = new this.google.maps.LatLng(34.031543, -118.319759)
      this.cdmx = new this.google.maps.LatLng(19.40679, -99.197882)

      this.map = new this.google.maps.Map(
        this.$refs.map,
        {
          mapTypeId: process.env.NODE_ENV === 'development2' ? 'roadmap' : 'hybrid',
          zoom: process.env.NODE_ENV === 'development' ? 16 : 10,
          center: process.env.NODE_ENV === 'development' ? this.cdmx : this.california,
          disableDefaultUI: true,
          zoomControl: true,
          mapTypeControl: true,
          mapTypeControlOptions: {
            position: this.isMobile ? this.google.maps.ControlPosition.LEFT_BOTTOM : this.google.maps.ControlPosition.RIGHT_TOP
          }
        }
      )

      this.map.addListener('click', () => {
        // oculta el tooltip al hacer click en el mapa
        this.hideCurrentTooltip()
      })

      this.$store.commit('setMap', this.map)
    },
    /**
     * Initialize Google Map Drawer
     */
    initDrawer () {
      const google = this.google
      this.drawer = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.RIGHT,
          drawingModes: ['polygon']
        },
        polygonOptions: {
          fillColor: this.calculateColor('HOLE'), // color
          fillOpacity: 0.3,
          strokeColor: this.calculateColor('HOLE'), // color
          strokeOpacity: 1,
          strokeWeight: 4,
          clickable: false,
          editable: true,
          zIndex: 1
        }
      })

      google.maps.event.addListener(this.drawer, 'overlaycomplete', this.attach)
    },
    /**
     * Draw geofence name in the map using ./LabelDrawer.js
     */
    drawName (google, position, itemName, type = 'car') {
      return new this.LabelDrawer(google, this.map, position, itemName, type)
    },
    /**
     * Draw hole name in the map using ./HoleLabelDrawer.js
     */
    drawHoleLabel (google, position, itemName, type = 'car') {
      return new this.HoleLabelDrawer(google, this.map, position, itemName)
    },
    /**
     * Draw tooltip in the map using ./TooltipDrawer.js
     */
    drawTooltip (google, position) {
      return new this.TooltipDrawer(google, this.map, position, this)
    },
    /**
     * Draw polygon in the map
     */
    drawPolygon (google, paths, color) {
      const polygon = new google.maps.Polygon({
        paths: paths.map((item) => {
          return {
            lat: item.latitude,
            lng: item.longitude
          }
        }),
        fillColor: color,
        fillOpacity: 0.3,
        strokeColor: color,
        strokeOpacity: 1,
        strokeWeight: 4,
        zIndex: 1
      })

      polygon.setMap(this.map)

      polygon.addListener('click', () => {
        // oculta el tooltip al hacer click en las geocercas
        this.hideCurrentTooltip()
      })

      return polygon
    },
    /**
     *
     */
    redrawPolygon (google, polygon, paths, color) {
      polygon.setMap(null)

      polygon.setOptions({
        paths: paths.map((item) => {
          return {
            lat: item.latitude,
            lng: item.longitude
          }
        }),
        fillColor: color,
        strokeColor: color
      })

      polygon.setMap(this.map)
    },
    /**
     *
     */
    stopDrawing () {
      this.drawer.setMap(null)
    },
    /**
     *
     */
    startDrawing () {
      this.drawer.setOptions({
        polygonOptions: {
          fillColor: this.calculateColor(this.type),
          strokeColor: this.calculateColor(this.type)
        }
      })

      this.drawer.setMap(this.map)
    },
    /**
     * Event that is called after a polygon is drawn.
     *
     * This event will erase the polygon from the map,
     * repaint the polygon with the label for the name
     * and save the coordinates of the geofence that the polygon represents.
     *
     * @access private
     */
    attach (event) {
      const path = []

      const overlay = event.overlay

      const vertices = overlay.getPath()

      for (let i = 0; i < vertices.getLength(); i++) {
        const xy = vertices.getAt(i)
        path.push({
          latitude: xy.lat(),
          longitude: xy.lng()
        })
      }

      // the geofence is erased before drawing it again
      event.overlay.setMap(null)

      if (this.module === 'restrictedZones') {
        this.$store.commit('restrictedZones/setGeofence', path)
      } else {
        if (this.type === 'hole') {
          const holesDrawing = Object.assign({}, this.holesDrawing)

          holesDrawing[this.key].geofence = path
          this.$store.commit('courses/setHoles', Object.values(holesDrawing)) // matriz de objetos con latitud y longitud
        } else {
          this.$store.commit('courses/setGeofence', path) // arreglo de objetos con latitud y longitud
        }
      }

      this.type = undefined
    },
    /**
     * Calculate color based in the
     * typed of geofence
     *
     * @access private
     */
    calculateColor (type) {
      switch (type) {
        case 'HOLE':
          return this.previewHoleColor
        case 'COURSE':
          return this.previewCourseColor
        case 'DANGEROUS':
          return '#B71C1C'
        case 'RESTRICTED':
          return '#F57C00'
        case 'GEOFENCE':
          return '#500085'
        default:
          return this.$vuetify.theme.themes.light.primary
      }
    }
  }
}
</script>
