import Layout from '@/layouts/platform/Login.vue'
import Login from '@/views/platform/Login.vue'

export default {
  path: '/Platform',
  component: Layout,
  children: [
    {
      path: 'Login',
      name: 'golfLogin',
      components: { default: Login }
    } 
  ]
}