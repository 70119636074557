
/**
 * Class to draw hole label in map
 *
 * must be called after window.google already exists
 * preferably with require('path/HoleLabelDrawer.js')
 */
class HoleLabelDrawer extends window.google.maps.OverlayView {
  constructor (
    google,
    map,
    position,
    itemName,
    labelBackgroundColor = 'black', // string color in acceptable css color
    labelTextColor = 'white' // string color in acceptable css color
  ) {
    super()
    this._map = map
    this._position = new google.maps.LatLng(
      position.latitude,
      position.longitude
    )

    this._name = itemName

    this._google = google

    this._html = null

    this._labelBackgroundColor = labelBackgroundColor
    this._labelTextColor = labelTextColor

    this.setMap(map)
  }

  onAdd () {
    const div = document.createElement('div')
    div.style.border = 'none'
    div.style.position = 'absolute'
    div.style.fontSize = '14px'
    div.style.textAlign = 'center'

    const inner = document.createElement('div')
    inner.innerHTML = this._name
    inner.style.position = 'absolute'
    inner.style.background = this._labelBackgroundColor
    inner.style.border = `2px solid ${this._labelTextColor}`
    inner.style.width = '25px'
    inner.style.height = '25px'
    inner.style.paddingTop = '2px'
    inner.style.borderRadius = '40px'

    const size = 10

    inner.style.left = -(this._name.length * (size / 2))

    inner.style.top = `${this._type === 'car' ? '' : '-'}10px`

    inner.style.fontFamily = 'Fira Sans Extra Condensed'
    inner.style.color = this._labelTextColor
    inner.style.fontWeight = '600'

    div.innerHTML = inner.outerHTML

    this._html = div

    const panes = this.getPanes()
    panes.overlayImage.appendChild(this._html)
  }

  getName () {
    return this._name
  }

  setName (newName) {
    this._name = newName
    this.onRemove()
    this.onAdd()
  }

  draw () {
    const overlayProjection = this.getProjection()

    let pos = { x: 0, y: 0 }
    try {
      pos = overlayProjection.fromLatLngToDivPixel(this._position)
    } catch (error) {
      // console.error('LabelDrawer Error' + error.toString())
    }

    const div = this._html

    if (div !== null && pos) {
      div.style.left = pos.x + 'px'
      div.style.top = pos.y + 'px'
    }
  }

  setPosition (position) {
    this._position = new this._google.maps.LatLng(position.latitude, position.longitude)

    this.draw()
  }

  onRemove () {
    this._html.parentNode.removeChild(this._html)
    this._html = null
  }
}

module.exports = HoleLabelDrawer
