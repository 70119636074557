export default {
  protocolById (state) {
    const result = {}

    for (const i in state.protocols) {
      result[state.protocols[i].id] = state.protocols[i]
    }

    return result
  }
}