<template>
  <v-footer :app="isFull" :color="`${color} white--text`" class="d-flex justify-center">
    &copy;
    {{ new Date().getFullYear() }}
    {{ $t('copyright.company.name') }}
    <v-divider vertical class="mx-2 my-1" color="white" />
    {{ $t('copyright.helpers.madeWith') }}
    &hearts;
    {{ $t('copyright.helpers.by') }}
    {{ $t('copyright.developer.name') }}

    <template v-if="isFull">
      <v-spacer />

      {{ $t('app.version', { version, name: $t(`changelog.version_name`) }) }}
    </template>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    isFull: {
      type: Boolean,
      default: () => true
    },

    color: {
      type: String,
      default: () => 'black'
    }
  },

  computed: {
    ...mapGetters(['version'])
  }
}
</script>