/*
  Course state
*/
export default {
  /**
   * List of courses
   */
  list: [],
  /**
   * All the holes in the platform
   * to later check if point is whithin any hole at all
   */
  holesList: [],
  /**
   * Holes object to use in the
   * form when creating and editing
   */
  holes: {},
  geofence: [],
  edittingId: undefined,
  // Colores para la geocerca cuando se esta dibujando
  courseColor: '#078a1f',
  holeColor: '#0299b8'
}
