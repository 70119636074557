var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("\n    "+_vm._s(_vm.$t((_vm.name + ".title.list")))+"\n\n    "),_c('v-spacer'),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","small":"","text":""},on:{"click":function($event){return _vm.$emit('new-item')}}},on),[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t((_vm.name + ".title.new"))))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"warning","icon":"","small":"","text":""},on:{"click":function($event){return _vm.$emit('reload-list')}}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('actions.reload')))])])],1),_vm._v(" "),_c('v-card-text',[_c('v-row',{staticClass:"px-2",attrs:{"no-gutters":""}},[_c('v-combobox',{attrs:{"prepend-icon":"mdi-magnify","placeholder":_vm.$t('helpers.search'),"multiple":"","label":_vm.$t('actions.find'),"outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({key:JSON.stringify(data.item)},'v-chip',data.attrs,false),[(data.item.includes(_vm.searchSymbol))?[_c('v-chip',{class:[
                  { 'ma-0 mr-2 white--text' : true },
                  { 'red darken-2' : _vm.headersTitle[data.item.split(_vm.searchSymbol)[0].toLowerCase()] === undefined },
                  { 'green darken-2' : _vm.headersTitle[data.item.split(_vm.searchSymbol)[0].toLowerCase()] !== undefined }
                ],attrs:{"small":"","left":""}},[_vm._v("\n                "+_vm._s(data.item.split(_vm.searchSymbol)[0])+"\n              ")]),_vm._v("\n              "+_vm._s(data.item.split(_vm.searchSymbol)[1])+"\n            ")]:[_vm._v("\n              "+_vm._s(data.item)+"\n            ")]],2)]}}]),model:{value:(_vm.searchField),callback:function ($$v) {_vm.searchField=$$v},expression:"searchField"}}),_vm._v(" "),_c('v-menu',{attrs:{"offset-x":"","close-on-content-click":false,"open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("\n              mdi-help-circle-outline\n            ")])],1)]}}])},[_vm._v(" "),_c('v-card',{staticStyle:{"width":"300px"}},[_c('v-card-title',[_vm._v("\n            "+_vm._s(_vm.$t('helpers.table.searchBarHintTitle'))+"\n          ")]),_vm._v(" "),_c('v-card-text',{staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(_vm.$t('helpers.table.searchBarHintContent'))}})],1)],1)],1),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":JSON.stringify(_vm.searchField),"options":_vm.pagination,"custom-filter":_vm.filter,"items":_vm.data,"dark":_vm.isDark},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                var items = ref.items;
return _vm._l((items),function(item,i){return _c('tr',{key:("row-" + i),class:[{'white--text' : _vm.isDark}]},[_c('td',[_vm._v(_vm._s(i + 1))]),_vm._v(" "),_vm._l((_vm.columns),function(header){return [_c('td',{key:("row-" + i + "-" + (header.value)),class:[
                { 'text-center' : header.alignment === 'center' },
                { 'text-left' : header.alignment === 'start' },
                { 'text-right' : header.alignment === 'end' }
              ]},[(header.type === 'badge')?[_c('v-chip',{attrs:{"color":("" + (header.color === undefined ? 'primary' : header.color(item[header.value])))}},[(header.formatter === undefined)?[_vm._v("\n                    "+_vm._s(item[header.value])+"\n                  ")]:[_vm._v("\n                    "+_vm._s(header.formatter(item[header.value]))+"\n                  ")]],2)]:(header.type === 'image')?[_c('img',{staticStyle:{"width":"50px"},attrs:{"src":item[header.value]}})]:[(header.formatter === undefined)?[_vm._v("\n                  "+_vm._s(item[header.value])+"\n                ")]:[_vm._v("\n                  "+_vm._s(header.formatter(item[header.value]))+"\n                ")]]],2)]}),_vm._v(" "),(_vm.hasActions)?_c('td',{staticClass:"text-right"},[(item.actions.show)?_c('v-btn',{attrs:{"small":"","icon":"","text":"","color":("blue " + (_vm.isDark ? '' : 'darken-4'))},on:{"click":function($event){return _vm.$emit('toggle-action', { action: 'show', id: item.id })}}},[_c('v-icon',[_vm._v("mdi-file-find-outline")])],1):_vm._e(),_vm._v(" "),(item.actions.edit)?_c('v-btn',{attrs:{"small":"","icon":"","text":"","color":"warning"},on:{"click":function($event){return _vm.$emit('toggle-action', { action: 'edit', id: item.id })}}},[_c('v-icon',[_vm._v("mdi-file-document-edit-outline")])],1):_vm._e(),_vm._v(" "),(item.actions.delete)?_c('v-btn',{attrs:{"small":"","icon":"","text":"","color":("red " + (_vm.isDark ? '' : 'darken-4'))},on:{"click":function($event){return _vm.$emit('toggle-action', { action: 'delete', id: item.id })}}},[_c('v-icon',[_vm._v("mdi-file-cancel-outline")])],1):_vm._e()],1):_vm._e()],2)})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }