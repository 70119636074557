/*
  Icon getters
*/
export default {
  defaultObject () {
    return {
      id: undefined,
      name: '',
      color: ''
    }
  }
}
