import Layout from '@/layouts/platform/Main.vue'

import HomeView from '@/views/platform/Home.vue'
import UnitsView from '@/views/platform/Units.vue'
import SettingsView from '@/views/platform/Settings.vue'
import RestrictedZonesView from '@/views/platform/RestrictedZones.vue'
import PaceOfPlayView from '@/views/platform/PaceOfPlay.vue'
import MonitorView from '@/views/platform/Monitor.vue'
import AlertsView from '@/views/platform/Alerts.vue'
import TripsView from '@/views/platform/Trips.vue'

export default [
  {
    path: '/Platform',
    component: Layout,
    children: [
      {
        path: 'Home',
        name: 'home',
        components: { default: HomeView }
      },
      {
        path: 'Units',
        name: 'units',
        components: { default: UnitsView }
      },
      {
        path: 'Settings',
        name: 'settings',
        components: { default: SettingsView }
      },
      {
        path: 'Restricted',
        name: 'restricted',
        components: { default: RestrictedZonesView }
      },
      {
        path: 'PaceOfPlay',
        name: 'paceOfPlay',
        components: { default: PaceOfPlayView }
      },
      {
        path: 'Monitor',
        name: 'monitor',
        components: { default: MonitorView }
      },
      {
        path: 'Alerts',
        name: 'alerts',
        components: { default: AlertsView }
      },
      /**
       * Legacy url support
       */
      {
        path: 'Trips',
        name: 'trips',
        components: { default: TripsView }
      },
      {
        path: 'History',
        name: 'history',
        components: { default: TripsView }
      }
    ]
  }
]