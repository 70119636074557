export default {
  version: {
    local: {
      version: '1.1.2',
      branch: '1'
    },
    remote: { // Remote key setted automaticly
      version: '',
      branch: ''
    }
  },

  clock: {
    hours: (new Date()).getHours(),
    minutes: (new Date()).getMinutes(),
    seconds: (new Date()).getSeconds()
  },

  // Dashboard operations variables
  changelog: false,
  isCookies: false,
  isLoading: false,
  // isMini: false,
  drawer: false,
  isDark: false,
  google: null,
  map: null,

  isHolesEditting: false,
  isRestrictedEditting: false,
  isSingleMode: true,

  holes: {},

  language: 'EN',

  hasTripDrawed: false,

  width: {
    full: window.innerWidth,
    dialog: window.innerWidth * 0.8333333333 // 10 of 12 columns
  }
}