/*
  Restricted getters
*/
export default {
  defaultObject () {
    return {
      id: undefined,
      name: '',
      customerId: null,
      type: 'RESTRICTED',
      actions: [
        {
          id: undefined,
          type: 'MARSHALL',
          willExecute: true
        },
        {
          id: undefined,
          type: 'USER',
          willExecute: true
        },
        {
          id: undefined,
          type: 'CUTOFF',
          willExecute: true
        }
      ]
    }
  },

  byId (state) {
    const result = {}

    for (const i in state.list) {
      result[state.list[i].id] = state.list[i]
    }

    return result
  }
}
