/*
  General actions
*/

import cookies from 'js-cookie'
import { i18n } from '@/plugins'

import { apollo } from '@/plugins/apollo'

// graphql files
import protocols from 'GraphQL/queries/utils/protocols.gql'
import messages from 'GraphQL/queries/utils/messages.gql'
import mobileMessages from 'GraphQL/queries/utils/mobileMessages.gql'
import maintenances from 'GraphQL/queries/utils/maintenances.gql'

export default {
  /**
   *
   */
  getProtocols ({ commit, rootState }) {
    apollo.query({
      query: protocols,
      variables: {
        apiToken: rootState.users.entity.apiToken,
        language: rootState.language
      }
    }).then((response) => {
      const { status, result } = response.data.protocols

      switch (status) {
        case 'OK':
          commit('setProtocols', result)
          break
        default:
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      commit('toggleSnackbar', undefined, { root: true })
    })
  },

  getMessages ({ commit, rootState }) {
    apollo.query({
      query: messages,
      variables: {
        apiToken: rootState.users.entity.apiToken,
        language: rootState.language
      }
    }).then((response) => {
      const { status, result } = response.data.messages
      // console.log('get Messages from graphql')
      // console.log(result)
      // console.log(status)
      const parsed = {}

      switch (status) {
        case 'OK':
          for (const i in result) {
            const unitId = result[i].unitId
            parsed[unitId] = {
              unitId,
              unit: {
                id: unitId
              },

              paceOfPlay: {
                startAt: result[i].gameStartAt,
                allowed: result[i].gameAllowedTime,
                average: result[i].gameAverage
              },

              killBoxParameters: JSON.parse(result[i].killBoxParameters),

              position: {
                latitude: result[i].latitude,
                longitude: result[i].longitude,
                speed: result[i].speed,
                direction: result[i].direction,
                altitude: result[i].altitude,
                hdop: result[i].hdop,
                satellites: result[i].satellites,

                motion: {
                  state: result[i].isMoving,
                  value: result[i].state
                }
              },

              battery: {
                voltage: result[i].batteryVoltage,
                level: result[i].batteryLevel,
                charging: {
                  status: result[i].batteryCharging
                }
              },

              gprs: {
                baseId: result[i].gprsBaseId,
                networkCode: result[i].gprsNetworkCode,
                locationCode: result[i].gprsLocationCode,
                strength: result[i].gprsStrength
              },

              geofence: {
                course: {
                  id: result[i].courseId
                },
                hole: {
                  id: result[i].holeId
                },
                supercourse: {
                  id: result[i].supercourseId
                },
                geofence: {
                  id: result[i].geofenceId
                }
              },

              source: {
                voltage: result[i].sourceVoltage,
                level: result[i].sourceLevel,
                charging: {
                  status: result[i].sourceState
                }
              },

              engine: {
                virtual: {
                  status: result[i].virtualIgnition
                },
                ignition: {
                  status: result[i].ignition
                }
              },
              receivedAt: result[i].receivedAt
            }
          }

          commit('setMessages', parsed)
          break
        default:
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      console.error('General actions Error', error)
      commit('toggleSnackbar', undefined, { root: true })
    })
  },

  getMobileMessages ({ commit, rootState }) {
    // console.log('starting getMobileMessages')
    apollo.query({
      query: mobileMessages,
      variables: {
        apiToken: rootState.users.entity.apiToken,
        language: rootState.language
      }
    }).then((response) => {
      // console.log('response ', response)
      const { status, result } = response.data.mobileMessages
      // console.log('result ', result)
      // console.log('status ', status)
      const parsed = {}

      switch (status) {
        case 'OK':
          for (const i in result) {
            const unitId = result[i].unitId
            parsed[unitId] = {
              mobileMessage: true,
              unitId,
              unit: {
                id: unitId
              },

              paceOfPlay: {
                startAt: result[i].gameStartAt,
                allowed: result[i].gameAllowedTime,
                average: result[i].gameAverage
              },

              position: {
                latitude: result[i].latitude,
                longitude: result[i].longitude,
                speed: result[i].speed,
                direction: result[i].direction,
                altitude: result[i].altitude,
                hdop: result[i].hdop,

                motion: {
                  state: result[i].isMoving,
                  value: result[i].state
                }
              },

              battery: {
                voltage: result[i].batteryVoltage,
                level: result[i].batteryLevel,
                charging: {
                  status: result[i].batteryCharging
                }
              },

              gprs: {
                baseId: result[i].gprsBaseId,
                networkCode: result[i].gprsNetworkCode,
                locationCode: result[i].gprsLocationCode,
                strength: result[i].gprsStrength
              },

              geofence: {
                course: {
                  id: result[i].courseId
                },
                hole: {
                  id: result[i].holeId
                },
                supercourse: {
                  id: result[i].supercourseId
                },
                geofence: {
                  id: result[i].geofenceId
                }
              },

              source: {
                voltage: result[i].sourceVoltage,
                level: result[i].sourceLevel,
                charging: {
                  status: result[i].sourceState
                }
              },

              engine: {
                virtual: {
                  status: result[i].virtualIgnition
                },
                ignition: {
                  status: result[i].ignition
                }
              },
              receivedAt: result[i].receivedAt
            }
          }
          commit('setMobileMessages', parsed)
          break
        default:
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      console.error('General actions Error', error)
      commit('toggleSnackbar', undefined, { root: true })
    })
  },

  getMaintenances ({ commit, rootState }) {
    apollo.query({
      query: maintenances,
      variables: {
        apiToken: rootState.users.entity.apiToken,
        language: rootState.language
      }
    }).then((response) => {
      const { status, result } = response.data.maintenances

      const parsed = {}

      switch (status) {
        case 'OK':
          for (const i in result) {
            const unitId = result[i].unitId
            parsed[unitId] = {
              unitId: result[i].unitId,
              batteryLevel: result[i].batteryLevel,
              engineRuntime: result[i].engineRuntime,
              engineStatus: result[i].engineStatus,
              isMoving: result[i].isMoving,
              isCharging: result[i].isCharging,
              crashes: result[i].crashes,
              courseId: result[i].courseId,
              holeId: result[i].holeId,
              mileage: result[i].mileage,
              rounds: result[i].quantityRounds
            }
          }

          commit('setMaintenances', parsed)
          break
        default:
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      console.error('General actions Error: ', error)
      commit('toggleSnackbar', undefined, { root: true })
    })
  },

  /**
   * Getting visibility value from the cookies
   * @param {} commit
   */
  getVisibility ({ commit, rootState }) {
    const visibility = cookies.get('visibility')
    // console.log('getting visibility')
    if (visibility) {
      commit('setVisibility', visibility)
    }
    const defaultVisibility = [
      {
        text: i18n.t('home.visibilityOptions.carts'),
        value: 'carts',
        isSelected: rootState.general.visibility.includes('carts')
      },
      {
        text: i18n.t('home.visibilityOptions.courses'),
        value: 'courses',
        isSelected: rootState.general.visibility.includes('courses')
      },
      {
        text: i18n.t('home.visibilityOptions.holes'),
        value: 'holes',
        isSelected: rootState.general.visibility.includes('holes')
      },
      {
        text: i18n.t('home.visibilityOptions.restricted'),
        value: 'restricted',
        isSelected: rootState.general.visibility.includes('restricted')
      }
    ]

    commit('setDefaultVisibility', defaultVisibility)
  },

  /**
   * Getting the category visibility value from the cookies
   * @param {} commit
   */
  getCategoryVisibility ({ commit, rootState }) {
    const categoryVisibility = cookies.get('categoryVisibility')
    // console.log('getting categoryVisibility')
    if (categoryVisibility) {
      commit('setCategoryVisibility', categoryVisibility)
    }

    const defaultCategoryVisibility = [
      {
        text: i18n.t('units.categories.player'),
        value: 'PLAYER',
        isSelected: rootState.general.categoryVisibility.includes('PLAYER')
      },
      {
        text: i18n.t('units.categories.marshall'),
        value: 'MARSHALL',
        isSelected: rootState.general.categoryVisibility.includes('MARSHALL')
      },
      {
        text: i18n.t('units.categories.maintenance'),
        value: 'MAINTENANCE',
        isSelected: rootState.general.categoryVisibility.includes('MAINTENANCE')
      },
      {
        text: i18n.t('units.categories.staff'),
        value: 'STAFF',
        isSelected: rootState.general.categoryVisibility.includes('STAFF')
      },
      {
        text: i18n.t('units.categories.personal'),
        value: 'PERSONAL',
        isSelected: rootState.general.categoryVisibility.includes('PERSONAL')
      }
    ]

    commit('setDefaultCategoryVisibility', defaultCategoryVisibility)
  }
}