<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12>
        <gm-listing
          name="categories"
          has-actions
          :actions-access="{ show: true, edit: true, delete: true }"
          :columns="columns"
          :items-per-page="itemsPerPage"
          :rows="data"
          @new-item="newItem"
          @reload-list="$store.dispatch('categories/getList', true)"
          @new-items-per-page="setNewItemsPerPage"
          @toggle-action="toggleAction"
        />
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="dialog"
      :fullscreen="isMobile"
      :max-width="width.dialog"
      scrollable
      persistent
      :overlay="false"
      transition="dialog-transition"
      :dark="isDark"
    >
      <v-card>
        <v-card-title>
          {{ dialogTitle }}
          <v-spacer />
          <v-btn icon text @click="discard">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider class="mx-4 my-2" />

        <v-card-text class="mb-0 pb-0 pt-1">
          <v-layout row wrap>
            <v-flex xs12 md6 class="px-2">
              <v-text-field
                v-model="object.name"
                :disabled="!isEditting"
                :error="errors.name.length > 0"
                :error-messages="errors.name"
                :label="$t('categories.name')"
                outlined
              />
            </v-flex>         

            <v-flex xs12 md6 class="px-2">
              <v-menu
                v-model="primaryPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                full-width
                :nudge-right="40"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="color"
                    :disabled="!isEditting"
                    :error="errors.color.length > 0"
                    :error-messages="errors.color"
                    :label="$t('categories.color')"
                    outlined
                    readonly
                    v-on="on"
                  >
                    <template v-slot:append>
                      <div :style="`background-color: ${color}; width: 20px; height: 20px; border-radius: 50%; margin-top: 2px; margin-right: 5px; box-shadow: 0 0 10px rgba(0,0,0,0.2)`" />
                    </template>
                  </v-text-field>
                </template>

                <v-color-picker
                  v-model="color"
                  mode="hexa"
                  hide-inputs
                  hide-mode-switch
                />
              </v-menu>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions class="d-flex justify-space-between flex-row-reverse">
          <v-btn v-if="isEditting" color="green darken-2 white--text" :disabled="isLoading" @click="graphqlHandler(object.id === undefined ? 'add' : 'edit')">
            <v-icon>mdi-check-circle</v-icon>
            {{ $t('actions.save') }}
          </v-btn>

          <v-btn v-if="!isEditting" color="orange darken-2 white--text" :disabled="isLoading" @click="editItem">
            <v-icon>mdi-pencil</v-icon>
            {{ $t('actions.edit') }}
          </v-btn>

          <v-btn v-if="isEditting" color="red darken-2 white--text" @click="discard">
            <v-icon>mdi-close-circle</v-icon>
            {{ $t('actions.discard') }}
          </v-btn>

          <v-btn v-if="object.id !== undefined && !isEditting" color="red darken-2 white--text" :disabled="isLoading" @click="graphqlHandler('delete', object.id)">
            <v-icon>mdi-delete</v-icon>
            {{ $t('actions.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { Listing } from 'Components/scaffolds'
import { mapState, mapGetters } from 'vuex'

import add from 'GraphQL/mutations/categories/add.gql'
import edit from 'GraphQL/mutations/categories/edit.gql'
import del from 'GraphQL/mutations/categories/delete.gql'
import details from 'GraphQL/queries/categories/details.gql'

export default {
  components: {
    'gm-listing': Listing
  },

  data () {
    return {
      object: {},
      dialog: false,
      isEditting: false,
      itemsPerPage: 10,
      currentTab: 0,
      primaryPicker: false,
      secondaryPicker: false,
      showPassword: false,
      isLoading: false,
      rawErrors: {}
    }
  },

  computed: {
    ...mapState(['categories', 'width', 'isDark', 'users', 'language', 'units']),
    ...mapGetters(['isMobile']),
    ...mapGetters({
      defaultObject: 'categories/defaultObject'
    }),

    color: {
      get () {
        if (this.object.color === undefined || this.object.color === '') {
          return this.$vuetify.theme.themes.light.primary
        } else {
          return this.object.color
        }
      },

      set (value) {
        this.object.color = value.slice(0, 7)
      }
    },
    errors () {
      const errors = {}

      for (const key in this.defaultObject) {
        let keyCamel = key
        if (key.includes('_')) {
          keyCamel = this.convertToCamelCase(key)
        }

        if (this.rawErrors[key] === undefined) {
          errors[keyCamel] = ''
        } else {
          errors[keyCamel] = this.rawErrors[key].join(', ')
        }
      }
      return errors
    },

    dialogTitle () {
      if (this.object.id === undefined) {
        return this.$i18n.t('categories.title.new')
      } else {
        return this.$i18n.t(`categories.title.${this.isEditting ? 'edit' : 'show'}`, { name: this.object.name })
      }
    },

    user () {
      return this.users.entity
    },

    data () {
      return this.categories.list
    },

    columns () {
      return [
        {
          title: this.$i18n.t('categories.name'),
          value: 'name',
          isSortable: true,
          alignment: 'center'
        },
        {
          title: this.$i18n.t('categories.color'),
          value: 'color',
          isSortable: false,
          alignment: 'center'
        }
      ]
    }

  },

  mounted () {
    this.object.color = this.$vuetify.theme.themes.light.primary
    this.object.secondaryColor = this.$vuetify.theme.themes.light.secondary
  },

  methods: {
    convertToCamelCase (str) {
      str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, (match) => {
        return match.charAt(match.length - 1).toUpperCase()
      })
      return str.charAt(0).toLowerCase() + str.substring(1)
    },

    setNewItemsPerPage (newValue) {
      this.itemsPerPage = newValue
    },

    newItem () {
      this.dialog = true
      this.isEditting = true
      this.object = Object.assign({}, this.defaultObject)
      // console.log(this.user)
      // console.log(this.users)
    },

    editItem () {
      this.isEditting = true
    },

    async toggleAction ({ action, id }) {
      await this.$store.commit('setLoading', true)

      if (action === 'delete') {
        await this.$store.commit('setLoading', true)
        this.graphqlHandler('delete', id)
        await this.$store.commit('setLoading', false)
      } else {
        await this.$apollo.query({
          query: details,
          variables: {
            apiToken: this.user.apiToken,
            language: this.language,
            id
          },
          fetchPolicy: 'no-cache'
        }).then((response) => {
          const { status, result } = response.data.category

          if (status === 'OK') {
            this.object = Object.assign({}, result)
            this.dialog = true

            if (action === 'edit') {
              this.isEditting = true
            }
          } else {
            this.$store.commit('toggleSnackbar')
          }
        }).catch((error) => {
          this.$store.commit('toggleSnackbar')
        }).finally(() => {
          this.$store.commit('setLoading', false)
        })
      }
    },

    discard () {
      this.object = Object.assign({}, this.defaultObject)
      this.isEditting = false
      this.dialog = false
    },

    async graphqlHandler (method, id = undefined) {
      this.isLoading = true
      this.rawErrors = {}

      let mutation = add
      let name = 'addCategory'
      
      const variables = {
        apiToken: this.user.apiToken,
        language: this.language,
        data: this.object
      }

      delete variables.data.__typename
      if (method === 'edit') {
        name = 'editCategory'
        mutation = edit
      } else if (method === 'delete') {
        name = 'deleteCategory'
        delete variables.data
        variables.id = id
        mutation = del
      }

      await this.$apollo.mutate({
        mutation,
        variables,
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const data = response.data[name]
        const status = data.status

        const errorProcessed = {}

        switch (status) {
          case 'OK':
            this.$store.dispatch('categories/getList', true)

            if (method === 'delete') {
              this.discard()
            } else {
              this.object = Object.assign({}, data.result)
              this.isEditting = false
            }
            break

          case 'UNPROCESSABLE':
            for (const key in data.errors) {
              errorProcessed[this.convertToCamelCase(key)] = data.errors[key]
            }

            this.rawErrors = Object.assign({}, errorProcessed)
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('errors.invalidFields'),
              color: 'orange darken-2'
            })
            break
        }
      }).catch((error) => {
        console.error('Categories Error: ', error)
        this.$store.commit('toggleSnackbar')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>
