/*
  Icon mutations
*/
export default {
  setList (state, payload) {
    state.list = []
    state.list = payload
  },

  drawMultipleTrips (state, payload) {
    state.tripsByUnits = []
    state.tripsByUnits = payload
  },

  cleanAllTrips (state, payload) {
    state.tripsByUnits = []
  }
}
