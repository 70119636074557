<template>
  <v-container fill-height fluid pa-0>
    <v-layout align-center justify-center :style="styles">
      <div style="position: fixed; top: 0; left: 0; background: rgba(0,0,0,0.8); width: 100vw; height: 100vh;" />
      
      <transition name="component-fade" mode="out-in">
        <router-view />
      </transition>
    </v-layout>

    <gm-redirect />

    <gm-footer color="transparent" />
  </v-container>
</template>

<script>
import { Redirect } from 'Components/utils'
import { Footer } from 'Components/layout'

export default {
  components: {
    'gm-redirect': Redirect,
    'gm-footer': Footer
  },

  data () {
    return {
      fab: false
    }
  },

  computed: {
    styles () {
      return `background-image: url(${this.$assets.background}); background-size: cover; background-position: center;`
    }
  }
}
</script>

<style>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>