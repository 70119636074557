/*
  Icon getters
*/
export default {
  defaultObject () {
    return {
      id: undefined,
      name: '',
      primaryColor: '',
      secondaryColor: '',
      category: 'CAR'
    }
  }
}
