<template>
  <v-app-bar color="transparent" class="elevation-0" :height="72.5">
    <v-app-bar-nav-icon v-if="isMobile" @click="$store.commit('setDrawer', true)" />

    <v-toolbar-title>
      {{ $t(`headers.${$route.name}`) }}
      <br>
      <small>{{ $t('app.version', { version, name: $t(`changelog.versions.${$store.state.version.local.version}.name`) }) }}</small>
    </v-toolbar-title>

    <v-spacer />

    <v-btn icon @click="toggleDark">
      <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isMobile', 'version'])
  },

  methods: {
    ...mapMutations(['toggleDark'])
  }
}
</script>