<template>
  <v-expansion-panels v-model="currentPanel" dark>
    <v-expansion-panel class="grey-opacity">
      <v-expansion-panel-header>
        {{ $t('home.carts') }}
      </v-expansion-panel-header>
      <!-- Main expansion panel of the Summary -->
      <v-expansion-panel-content class="pa-0">
        <div class="expansion__content scrollbar__seamless">
          <v-data-table
            :headers="headers"
            :items="units"
            multi-sort
            :custom-sort="sortTableRows"

            fixed-header
            height="65vh"
            class="transparent datatable__custom"
            :items-per-page="-1"
          >
            <template v-slot:item="{ item }">
              <tr :key="`home-unit-${item.id}`">
                <!-- Button to locate unit -->
                <td class="white--text text-center" style="width: 30px; max-width: 30px;">
                  <v-btn
                    small
                    icon
                    :disabled="!messages[item.id] || !categoryVisibility.includes(item.category)"
                    @click="toggleCenter(item.id)"
                  >
                    <v-icon x-small>mdi-crosshairs{{ activeUnits.includes(item.id) ? '-gps' : '' }}</v-icon>
                  </v-btn>
                </td>
                <!-- Name of the unit and tooltip -->
                <v-menu
                  close-on-content-click
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <td
                      class="white--text"
                      style="font-size: 12px;"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-avatar :size="18">
                        <v-img :src="item.icon.uri || '/car/render'" contain />
                      </v-avatar>
                      &nbsp;
                      {{ item.name }}

                      <gm-signal
                        :value="getSignalValue(item)"
                      />
                    </td>
                  </template>
                  <v-card
                    dark
                    class="unit_info__popup"
                  >
                    <gm-unit-information
                      :unit="item"
                      :message="messages[item.id]"
                    />
                  </v-card>
                </v-menu>
                <!-- course -->
                <td class="white--text text-center">
                  {{ getCourseValue(item.id)}}
                </td>

                <!-- Location -->
                <td class="white--text text-center">
                  {{ getLocationValue(item.id) }}
                </td>

                <!-- Vehicle type -->
                <td class="white--text text-center">
                  {{ $t(`units.categories.${item.category.toLowerCase()}`) }}
                </td>

                <!-- Pace status -->
                <td class="white--text text-center">
                  <template v-if="gameControl[item.id] === undefined">
                    N/A
                  </template>
                  <template v-else>
                    <gm-status
                      type="basic"
                      :message="gameControl[item.id]"
                      :offset="gameControl[item.id].offset"
                    />
                  </template>
                  
                </td>

                <!-- Battery Level -->
                <td class="white--text text-center">
                  {{getBatteryLevel(item.id)}}
                </td>

                <!-- Movement status -->
                <td class="white--text text-center">
                  <v-chip
                    small
                    :color="getMovementStatusColor(item.id)"
                  >
                    {{ getMovementStatusText(item.id) }}
                  </v-chip>
                </td>

                <!-- Ignition status -->
                <td
                  class="white--text text-center"
                >
                  <v-chip
                    small
                    :color="getIgnitionStatusColor(item.id)"
                  >
                    {{ getIgnitionStatusText(item.id) }}
                  </v-chip>
                </td>
                <!-- Connection status -->
                <td
                  class="white--text text-center"
                >
                  <!-- tooltip with the connection status icon and info -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="ma-0"
                        :color="connectionStatusColor(messages[item.id])"
                        v-on="on"
                      >
                        mdi-circle
                      </v-icon>
                    </template>
                    <span>
                      {{ connectionStatusText(messages[item.id]) }}
                    </span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { Unit, PaceOfPlay, Signal } from 'Components/utils'

var relativeTime = require('dayjs/plugin/relativeTime')
var duration = require('dayjs/plugin/duration')
var dayjs = require('dayjs')
dayjs.extend(duration)
dayjs.extend(relativeTime)

export default {
  components: {
    'gm-unit-information': Unit,
    'gm-status': PaceOfPlay,
    'gm-signal': Signal
  },

  data () {
    return {
      currentPanel: 0,
      show: false,
      /* units: [], */
      object: {},
      isEditting: false,
      isLoading: false,
      rawErrors: {},
    }
  },

  computed: {
    ...mapGetters({
      defaultObject: 'games/defaultObject',
      protocolById: 'general/protocolById'
    }),

    ...mapState(['general', 'games', 'language', 'users']),
    headers () {
      return [
        {
          text: '',
          value: '',
          sortable: false,
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('home.name'),
          value: 'name',
          class: 'transparent text--center',
          sortable: true,
        },
        {
          text: this.$i18n.t('home.course'),
          value: 'course',
          class: 'transparent text--center',
          sortable: true,
        },
        {
          text: this.$i18n.t('home.location'),
          value: 'location',
          class: 'transparent text--center',
          sortable: true,
        },
        {
          text: this.$i18n.t('units.category'),
          value: 'category',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('home.paceStatus'),
          value: 'paceStatus',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('home.batteryLevel'),
          value: 'batteryLevel',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('home.movementStatus'),
          value: 'movementStatus',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('home.ignitionStatus'),
          value: 'ignitionStatus',
          class: 'transparent text--center'
        },
        // Connection status
        {
          text: this.$i18n.t('home.connectionStatus'),
          value: 'connectionStatus',
          class: 'transparent text--center'
        }
      ]
    },

    gameControl () {
      return this.general.parsedGameControl
    },

    unitsLocation () {
      return this.general.unitsLocation
    },

    messages () {
      return this.general.messages
    },

    categoryVisibility () {
      return this.general.categoryVisibility
    },

    activeUnits () {
      return this.general.activeUnits
    },

    units () {
      return this.$store.state.units.tableList
    },

    courses () {
      return this.$store.state.courses.list
    },

    consumers () {
      return this.$store.state.consumers.list
    },

    user () {
      return this.users.entity
    }
  },

  methods: {
    sortTableRows(items, sortBy, sortDesc, locale, customSorters) {
      if (sortBy.length === 0) {
        return items
      }
      items.sort((row1, row2) => {
        for (let index = 0; index < sortBy.length; index++) {
          const element = sortBy[index];
          const sortDescElement = sortDesc[index];
          switch (element) {
            case 'name':
              let nameDiff;
              if (sortDescElement) {
                nameDiff = row2.name.toLowerCase().localeCompare(row1.name.toLowerCase())
              } else {
                nameDiff = row1.name.toLowerCase().localeCompare(row2.name.toLowerCase())
              }
              if (nameDiff !== 0) {
                return nameDiff;
              }
              break;
            case 'course':
              let courseDiff;
              const course1 = this.getCourseValue(row1.id)
              const course2 = this.getCourseValue(row2.id)
              if (sortDescElement) {
                courseDiff = course2.toLowerCase().localeCompare(course1.toLowerCase())
              } else {
                courseDiff = course1.toLowerCase().localeCompare(course2.toLowerCase())
              }
              if (courseDiff !== 0) {
                return courseDiff;
              }
              break;
            case 'location':
              let locationDiff;
              const location1 = this.getLocationValue(row1.id)
              const location2 = this.getLocationValue(row2.id)
              if (sortDescElement) {
                locationDiff = location2.toLowerCase().localeCompare(location1.toLowerCase())
              } else {
                locationDiff = location1.toLowerCase().localeCompare(location2.toLowerCase())
              }
              if (locationDiff !== 0) {
                return locationDiff;
              }
              break;
            case 'category':
              let categoryDiff;
              if (sortDescElement) {
                categoryDiff = row2.category.toLowerCase().localeCompare(row1.category.toLowerCase())
              } else {
                categoryDiff = row1.category.toLowerCase().localeCompare(row2.category.toLowerCase())
              }
              if (categoryDiff !== 0) {
                return categoryDiff;
              }
              break;
            case 'paceStatus':
              let paceDiff;
              const pace1 = this.getPaceValueRaw(row1.id)
              const pace2 = this.getPaceValueRaw(row2.id)
              if (sortDescElement) {
                paceDiff = pace2 - pace1
              } else {
                paceDiff = pace1 - pace2
              }
              if (paceDiff !== 0) {
                return paceDiff;
              }
              break;
            case 'batteryLevel':
              let batteryLevelDiff;
              const batteryLevel1 = this.getBatteryLevelForSorting(row1.id)
              const batteryLevel2 = this.getBatteryLevelForSorting(row2.id)
              if (sortDescElement) {
                batteryLevelDiff = batteryLevel2 - batteryLevel1
              } else {
                batteryLevelDiff = batteryLevel1 - batteryLevel2
              }
              if (batteryLevelDiff !== 0) {
                return batteryLevelDiff;
              }
              break;
            case 'movementStatus':
              let movementStatusDiff;
              const movementStatus1 = this.getMovementStatusText(row1.id)
              const movementStatus2 = this.getMovementStatusText(row2.id)
              if (sortDescElement) {
                movementStatusDiff = movementStatus2.toLowerCase().localeCompare(movementStatus1.toLowerCase())
              } else {
                movementStatusDiff = movementStatus1.toLowerCase().localeCompare(movementStatus2.toLowerCase())
              }
              if (movementStatusDiff !== 0) {
                return movementStatusDiff;
              }
              break;
            case 'ignitionStatus':
              let ignitionStatusDiff;
              const ignitionStatus1 = this.getIgnitionStatusText(row1.id)
              const ignitionStatus2 = this.getIgnitionStatusText(row2.id)
              if (sortDescElement) {
                ignitionStatusDiff = ignitionStatus2.toLowerCase().localeCompare(ignitionStatus1.toLowerCase())
              } else {
                ignitionStatusDiff = ignitionStatus1.toLowerCase().localeCompare(ignitionStatus2.toLowerCase())
              }
              if (ignitionStatusDiff !== 0) {
                return ignitionStatusDiff;
              }
              break;
            case 'connectionStatus':
              let connectionStatusDiff;
              const connectionStatus1 = this.getConnectionStatusTextForSorting(this.messages[row1.id])
              const connectionStatus2 = this.getConnectionStatusTextForSorting(this.messages[row2.id])
              if (sortDescElement) {
                connectionStatusDiff = connectionStatus2 - connectionStatus1
              } else {
                connectionStatusDiff = connectionStatus1 - connectionStatus2
              }
              if (connectionStatusDiff !== 0) {
                return connectionStatusDiff;
              }
              break;
            default:
              break;
          }
        }
        return 0;
      });
      return items;
    },
    toggleCenter (unitId) {
      if (this.activeUnits.includes(unitId)) {
        this.$store.commit('general/removeUnit', unitId)
      } else {
        this.$store.commit('general/addUnit', unitId)
      }
    },
    timeDifference (message) {
      // console.log(message)
      if (message !== undefined) {
        // tiempo llega en segundo asi que se multiplica por 1000 para obtener en milisegundos
        const lastConnectionDate = message.receivedAt * 1000
        const now = new Date().getTime()
        const difference = now - (lastConnectionDate)
        return difference
      }
      return undefined
    },
    connectionStatusColor (message) {
      if (message === undefined) {
        return 'grey'
      } else {
        // 60_000
        const minutesInMillis = 60000

        const difference = this.timeDifference(message)

        // 20 minutos sin conexion desconectada
        if (difference > (minutesInMillis * 15)) {
          return 'grey'
        } else {
          return 'green'
        }
      }
    },
    connectionStatusText (message) {
      if (message === undefined) {
        return this.$i18n.t('signal.status.na')
      } else {
        // 60_000
        const minutesInMillis = 60000

        const difference = this.timeDifference(message)
        let status = this.$i18n.t('signal.status.disconnected')
        // 15 minutos
        if (difference > (minutesInMillis * 15)) {
          status = `${this.$i18n.t('signal.status.disconnected')}`
        // 5 minutos
        } else if (difference > (minutesInMillis * 5)) {
          status = `${this.$i18n.t('signal.status.waiting')}`
        } else {
          status = `${this.$i18n.t('signal.status.connected')}`
        }

        return `${status}, ${this.$i18n.t('signal.status.last_connection')}: ${dayjs.duration(difference, 'milliseconds').humanize()} ${this.$i18n.t('signal.time.ago')}`
      }
    },
    getConnectionStatusTextForSorting(message){
      if (message === undefined) {
        // return this.$i18n.t('signal.status.na')
        return 0
      } else {
        // 60_000
        const minutesInMillis = 60000

        const difference = this.timeDifference(message)
        let connectionStatus = 0
        // 15 minutos
        if (difference > (minutesInMillis * 15)) {
          connectionStatus = 0
        // 5 minutos
        } else if (difference > (minutesInMillis * 5)) {
          connectionStatus = 1
        } else {
          connectionStatus = 2
        }
        return connectionStatus
      }
    },
    getSignalValue(item){
      if (this.messages[item.id] === undefined) {
        return 0
      }
      return this.messages[item.id]?.position?.satellites
    },
    getLocationValue(itemID){
      if (this.unitsLocation[itemID] === undefined) {
        return 'N/A'
      }
      if (this.unitsLocation[itemID].geofence.length > 0) {
        return this.unitsLocation[itemID].geofence
      }
      return this.unitsLocation[itemID].hole
    },
    getCourseValue(itemID){
      if (this.unitsLocation[itemID] === undefined) {
        return 'N/A'
      }
      return this.unitsLocation[itemID].course
    },
    getBatteryLevel (itemID) {
      if (this.messages[itemID] === undefined || this.messages[itemID].source === undefined ||  this.messages[itemID].source.level === undefined) {
        return 'N/A'
      }
      return `${Math.round(this.messages[itemID].source.level)}%`
    },
    getBatteryLevelForSorting (itemID) {
      if (this.messages[itemID] === undefined || this.messages[itemID]?.source?.level === undefined) {
        return 0
      }
      return this.messages[itemID].source.level
    },
    getPaceValueRaw (itemID) {
      if (this.gameControl[itemID] === undefined) {
        // return 'N/A'
        return 0
      }
      let allowed = null;
      let message=this.gameControl[itemID]
      let offset=this.gameControl[itemID].offset
      
      const current = ((new Date() / 1000) - message.startAt) / 60
      allowed = message.allowed

      const overtime = current >= allowed
      const reaching = current >= (allowed - offset)

      if (overtime) {
        // return this.$i18n.t('paceOfPlay.states.overtime')
        return 1
      } else if (reaching) {
        // return this.$i18n.t('paceOfPlay.states.reaching')
        return 2
      } else {
        // return this.$i18n.t('paceOfPlay.states.allowed')
        return 3
      }
    },
    getMovementStatusText(itemID){
      if (this.messages[itemID] === undefined) {
        return this.$i18n.t('helpers.movement.false')
      }
      const value = Boolean(this.messages[itemID]?.position?.motion?.state)
      return this.$i18n.t(`helpers.movement.${value}`)
    },
    getMovementStatusColor(itemID){
      if (this.messages[itemID] === undefined) {
        return 'red'
      }
      const value = Boolean(this.messages[itemID]?.position?.motion?.state)
      return value ? 'green' : 'red'
    },
    getIgnitionStatusText(itemID){
      if (this.messages[itemID] === undefined) {
        return this.$i18n.t('helpers.states.false')
      }
      const value = Boolean(this.messages[itemID]?.engine?.ignition?.status)
      return this.$i18n.t(`helpers.states.${value}`)
    },
    getIgnitionStatusColor(itemID){
      if (this.messages[itemID] === undefined) {
        return 'red'
      }
      const value = Boolean(this.messages[itemID]?.engine?.ignition?.status)
      return value ? 'green' : 'red'
    },
  }
}
</script>

<style lang="scss" scoped>
.expansion__content{
  max-height: calc(100vh - 170px);
  overflow: auto;
  padding: 0 10px;
}
</style>