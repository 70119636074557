// Main imports
import Vue from 'vue'
import VueRouter from 'vue-router'

// // Vuetify locales
import routes from '@/routes'

// Create component
Vue.use(VueRouter)

export default new VueRouter({
  routes
})