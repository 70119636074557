<template>
  <v-img :src="image" contain :width="200" />
</template>

<script>
export default {
  props: {
    primary: {
      type: String,
      default: () => '#48aae0'
    },
    secondary: {
      type: String,
      default: () => '#036fa7'
    }
  },

  computed: {
    image () {
      return 'data:image/svg+xml,' + encodeURIComponent(this.inlineSvg)
    },

    inlineSvg () {
      return `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <defs>
        <style>
            .cls-1 {
                fill: #231f20;
            }
            .cls-2 {
                fill: #3b2314;
            }
            .cls-3 {
                fill: #929497;
            }
            .cls-4 {
                fill: ${this.secondary};
            }
            .cls-5 {
                fill: #404041;
            }
            .cls-6 {
                fill: ${this.primary};
            }
            .cls-7 {
                fill: #58595b;
            }
            .cls-8 {
                fill: #6d6e70;
            }
            .cls-9 {
                fill: #fff;
            }
        </style>
    </defs>
    <title>caddygolf-car</title>
    <path class="cls-1" d="M95.45,82.13c9-3,17.74-8.14,14-17.32,0,0-4.9-12-21.41-7,0,0-38.51,12-26.4,38.81l65.73,145.61,4.53-2.24c-1.54-3.39-3.09-6.8-4.61-10.2q-5.73-12.69-11.47-25.36-7.5-16.63-15-33.26-7.65-16.94-15.29-33.87-7-15.63-14.11-31.26-1.32-2.92-2.63-5.87C64.67,91,76.38,86.72,83,85.32A115.08,115.08,0,0,0,95.45,82.13Z"
    />
    <path class="cls-1" d="M105.66,104.07c6.59-2.2,13.08-6,10.31-12.76,0,0-3.58-8.82-15.76-5.15,0,0-28.35,8.83-19.44,28.59L129.18,222l3.35-1.65q-1.71-3.77-3.4-7.52-4.22-9.34-8.45-18.69-5.52-12.24-11.06-24.49Q104,157.2,98.36,144.71L88,121.69c-.64-1.44-1.3-2.89-2-4.33-3-6.77,5.58-9.91,10.44-11A81.19,81.19,0,0,0,105.66,104.07Z"
    />
    <path class="cls-1" d="M25.57,168.29c-4.1,5.61-9.67,10.65-15.29,6,0,0-7.31-6.1-.12-16.58,0,0,17-24.36,33.14-9.87l87.54,78.65-2.58,2.68-6.16-5.51q-7.6-6.85-15.24-13.7l-20-18q-10.18-9.15-20.37-18.3L47.72,156.78l-3.53-3.17c-5.54-4.94-11.13,2.31-13.61,6.63A80.86,80.86,0,0,1,25.57,168.29Z"
    />
    <path class="cls-1" d="M29.48,120.35C24.05,124.67,17.33,128,13.16,122c0,0-5.42-7.84,4.3-16,0,0,22.89-18.94,34.58-.68l63.41,99.15-3.21,1.89c-1.49-2.32-3-4.63-4.45-7-3.68-5.76-7.38-11.52-11-17.27q-7.23-11.32-14.48-22.65L67.52,136.44,53.91,115.15c-.85-1.33-1.7-2.66-2.57-4-4-6.23-11.35-.73-14.87,2.78A84.83,84.83,0,0,1,29.48,120.35Z"
    />
    <path class="cls-2" d="M49.37,182.69c-18.42,22.6-6.28,63,27.17,90.32s75.49,31.09,93.93,8.5,6.27-63-27.16-90.31S67.82,160.11,49.37,182.69Zm112.87,89.4c-15.53,19-50.94,15.82-79.1-7.16s-38.41-57-22.88-76.07S111.21,173,139.37,196,177.77,253.06,162.24,272.09Z"
    />
    <path class="cls-3" d="M125.37,144.53,57.52,194.15l76.86,105.12,67.85-49.62s-24.86-70.53-76.86-105.12" />
    <polygon class="cls-2" points="125.53 144.41 57.67 194.03 49.37 182.69 117.24 133.08 125.53 144.41" />
    <polygon class="cls-2" points="210.68 260.86 142.83 310.48 134.54 299.15 202.41 249.54 210.68 260.86" />
    <path class="cls-4" d="M363.59,144.32H336.45c.21.72.42,1.44.58,2.22l54.9,163.1c0,11.11-8.11,20.12-18.13,20.12H179.31c-10,0-18.12-9-18.12-20.12l8.53-163.1c0-.75.05-1.49.11-2.22H143.66l-13,192.19c0,14.31,10.48,25.92,23.39,25.92H404.88c12.9,0,23.37-11.61,23.37-25.92Z"
    />
    <path class="cls-5" d="M193.21,290.29l-11.3-74.65A10.82,10.82,0,0,1,191,203.31h0a10.85,10.85,0,0,1,12.32,9.09l11.31,74.65s.85,34.85,66.14,29.69c0,0,7.38,10.53-3.69,13.28H201.83Z" />
    <polygon class="cls-5" points="360.68 290.99 369.8 280.57 328.44 244.37 319.33 254.77 360.68 290.99" />
    <polygon class="cls-5" points="344.56 232.15 337.5 226.53 303.26 269.51 310.33 275.13 344.56 232.15" />
    <path class="cls-6" d="M359.59,275c-11,0-19.89,8.49-19.89,19,0,0,12.1,49-65.54,52.6C207,339,218.23,294,218.23,294c-.59-13.77-8.9-19-19.89-19H84.83c-11,0-19.91,8.49-19.91,19v73.7c0,10.47,8.91,19,19.91,19H473.11c11,0,19.89-8.49,19.89-19V340.13C478.69,278.47,359.59,275,359.59,275Z"
    />
    <path class="cls-7" d="M471.45,387.36c0-.27,0-.55,0-.83a56.73,56.73,0,1,0-113.46,0c0,.58,0,1.16,0,1.73a10.79,10.79,0,0,1-4.21,2.55,6.42,6.42,0,0,1-5-2.4c0-.59-.05-1.17-.05-1.75,0-37.25,29.5-67.44,65.92-67.44s65.92,30.19,65.92,67.44v.25C478.38,388.66,474.51,388.45,471.45,387.36Z"
    />
    <path class="cls-7" d="M212.91,387.36c0-.27,0-.55,0-.83a56.72,56.72,0,1,0-113.44,0c0,.58,0,1.16,0,1.73a10.86,10.86,0,0,1-4.21,2.55,6.37,6.37,0,0,1-5-2.4c0-.58,0-1.17,0-1.75,0-37.25,29.5-67.44,65.92-67.44s65.91,30.19,65.91,67.44c0,.08,0,.16,0,.25C219.84,388.66,216,388.45,212.91,387.36Z"
    />
    <polygon class="cls-2" points="133.16 101.8 369.8 101.8 393.32 144.32 109.02 144.32 133.16 101.8" />
    <path class="cls-5" d="M358,386.66a56.73,56.73,0,1,0,56.72-56.72A56.73,56.73,0,0,0,358,386.66Z" />
    <path class="cls-8" d="M385.15,386.66a29.6,29.6,0,1,0,29.58-29.59A29.59,29.59,0,0,0,385.15,386.66Z" />
    <rect class="cls-9" x="411.26" y="357.07" width="6.97" height="59.19" />
    <rect class="cls-9" x="385.15" y="383.18" width="59.19" height="6.96" />
    <rect class="cls-9" x="385.14" y="383.19" width="59.19" height="6.96" transform="translate(-147.13 462.95) rotate(-50.73)" />
    <polygon class="cls-9" points="394.02 365.25 389.61 370.64 435.46 408.08 439.87 402.69 394.02 365.25" />
    <path class="cls-5" d="M99.48,386.66a56.72,56.72,0,1,0,56.73-56.72A56.73,56.73,0,0,0,99.48,386.66Z" />
    <path class="cls-8" d="M126.61,386.66a29.6,29.6,0,1,0,29.6-29.59A29.59,29.59,0,0,0,126.61,386.66Z" />
    <rect class="cls-9" x="152.72" y="357.07" width="6.97" height="59.19" />
    <rect class="cls-9" x="126.61" y="383.18" width="59.19" height="6.96" />
    <polygon class="cls-9" points="177.6 365.94 172.23 361.54 134.78 407.38 140.19 411.79 177.6 365.94" />
    <rect class="cls-9" x="152.72" y="357.07" width="6.96" height="59.18" transform="matrix(0.63, -0.77, 0.77, 0.63, -242.04, 262.92)" />
</svg>
`
    }
  }
}
</script>