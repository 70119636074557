/*
  Command getters
*/
export default {
  defaultObject () {
    return {
      id: undefined,
      
    }
  }
}
