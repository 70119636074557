import cookies from 'js-cookie'
import Vue from 'vue'

/**
 * Construye el arreglo de mensajes con posicion a modo de lograr reactividad
 *
 * @param {Array} rawMessages
 * @param {Array} units
 * @returns {Array} messages
 */
function buildPositionMessages(rawMessages, units) {
  const messages = {}
  // console.log(units)
  for (const i in rawMessages) {
    const unitRaw = rawMessages[i].unit
    if (!unitRaw) {
      continue
    }
    const unit = units[unitRaw.id]

    if (!unit) {
      // console.log('unit not found', rawMessages[i].unit.id)
      continue
    }
    // console.log('saving location for unitId', unit.id)
    // console.log('unit', unit)

    const message = Object.assign({}, rawMessages[i])

    message.unit = Object.assign({}, unit)

    messages[rawMessages[i].unit.id] = message
  }

  return messages
}

/**
 * parse el gameControl a un diccionario determinado por el id de la unidad
 * @param {*} gameControl
 */
function parsedArrayToDict(array, key) {
  // console.log('updated gameControl')
  const result = {}

  // const gameControl = this.general.gameControl

  for (const i in array) {
    result[array[i][key]] = array[i]
  }

  return result
}

/**
 * calcula el game control en base a los mensajes que estan llegando para lograr reactividad
 * tambien genera el array para el unitsLocation para mostrar la ubicacion de la unidad sin
 * problemas
 *
 * @param {*}
 *  {
 *    messages
      courses
      geofences
      holes
      units
    }
 */
function calculateGameControl({ messages, courses, geofences, holes, units }) {
  const gameControlResult = []
  const locationResult = []

  for (const i in messages) {
    let hole = ''
    let course = ''
    let supercourse = ''
    let geofence = ''

    let offset = 2
    // console.log("messages[i]")
    // const unit = units[messages[i].unit?.id]
    // console.log("unit ", unit)
    // console.log("message ",messages[i])

    if (messages[i]?.geofence?.supercourse?.id) {
      if (courses[messages[i].geofence.supercourse.id]) {
        supercourse = courses[messages[i].geofence.supercourse.id].name
      }
    }

    if (messages[i]?.geofence?.geofence?.id) {
      if (geofences[messages[i].geofence.geofence.id]) {
        geofence = geofences[messages[i].geofence.geofence.id].name
      }
    }

    if (messages[i]?.geofence?.course?.id) {
      if (courses[messages[i]?.geofence?.course?.id]) {
        offset = courses[messages[i].geofence.course.id].almostThere
        course = courses[messages[i].geofence.course.id].name
      }
    }

    if (messages[i]?.geofence?.hole?.id) {
      if (holes[messages[i].geofence.hole.id]) {
        hole = `#${holes[messages[i].geofence.hole.id].name}`
      }
    }

    if (supercourse.length > 0) {
      if (hole.length > 0) {
        hole = `${course}-${hole}`
      }
      course = supercourse
    }

    if (course.length === 0) {
      course = 'N/A'
    }

    if (hole.length === 0) {
      hole = 'N/A'
    }

    // saving for game control
    if (messages[i].paceOfPlay && messages[i].paceOfPlay.startAt) {
      let current = ((new Date() / 1000) - (messages[i].paceOfPlay.startAt)) / 60

      current = parseInt(current * 100) / 100

      let remaining = messages[i].paceOfPlay.allowed - current

      remaining = parseInt(remaining * 100) / 100

      let average = messages[i].paceOfPlay.average

      average = parseInt(average * 100) / 100

      let unitName = ''
      if (messages[i].unit === undefined) {
        return
      }
      if (units[messages[i].unit.id]) {
        unitName = units[messages[i].unit.id].name
      }

      gameControlResult.push({
        unit: unitName,
        unitId: messages[i].unit.id,
        course,
        hole,
        offset,
        average,
        geofence,
        allowed: messages[i].paceOfPlay.allowed,
        startAt: messages[i].paceOfPlay.startAt,
        current,
        remaining,
        overtime: messages[i].paceOfPlay.overtime
        // status
      })
    }
    // console.log('trying to save unit location');
    // console.log(units[messages[i].unit.id]);

    // saving for unit location
    locationResult.push({
      unitId: messages[i].unit.id,
      course,
      hole,
      geofence
    })
  }

  return {
    gameControl: gameControlResult,
    unitsLocation: locationResult
  }
}

export default {
  setProtocols(state, payload) {
    state.protocols = []
    state.protocols = payload
  },

  appendAlert(state, payload) {
    state.alerts.push(payload)
  },

  setAlerts(state, payload) {
    state.alerts = []
    state.alerts = payload
  },

  /**
   * Set last messages and new messages that
   * come from the platform
   *
   * @param {*} state
   * @param {*} payload
   */
  setMessages(state, payload) {
    // state.messages = {}
    const messagePayload = {
      ...state.messages,
      ...payload
    }
    Vue.set(state, 'messages', messagePayload)

    const positionMessages = buildPositionMessages(
      messagePayload,
      this.getters['units/byId']
    )

    Vue.set(state, 'positionMessages', positionMessages)

    // updating game control
    const temp = calculateGameControl({
      messages: messagePayload,
      units: this.getters['units/byId'],
      courses: this.getters['courses/byId'],
      holes: this.getters['courses/holesById'],
      geofences: this.getters['restrictedZones/byId']
    })

    if (temp === undefined) {
      return
    }

    Vue.set(state, 'gameControl', temp.gameControl)

    const unitsLocation = parsedArrayToDict(temp.unitsLocation, 'unitId')
    Vue.set(state, 'unitsLocation', unitsLocation)

    const parsedGameControl = parsedArrayToDict(temp.gameControl, 'unitId')
    Vue.set(state, 'parsedGameControl', parsedGameControl)
  },

  setMobileMessages(state, payload) {
    const messagePayload = {
      ...state.messages,
      ...payload
    }
    Vue.set(state, 'messages', messagePayload)

    const positionMessages = buildPositionMessages(
      messagePayload,
      this.getters['units/byId']
    )
    const posmess = []
    for (const key in positionMessages) {
      posmess.push(positionMessages[key])
    }
    state.positionMessages2 = posmess
    Vue.set(state, 'positionMessages', positionMessages)
  },

  /**
   * Set individual unit message
   * @param {*} state
   * @param {*} payload
   * @returns
   */
  setMessage(state, payload) {
    // llega nuevo mensaje
    if (payload.paceOfPlay === undefined) {
      try {
        if (state.messages[payload.unit.id]) {
          payload.paceOfPlay = Object.assign({}, state.messages[payload.unit.id].paceOfPlay)
        }
      } catch (error) {
        console.error('General vuex mutation error', error)
      }
    }

    Vue.set(state.messages, payload.unit.id, payload)

    // guardando mensajes para la posicion del mapa y lograr reactividad
    const positionMessages = buildPositionMessages(
      state.messages,
      this.getters['units/byId']
    )

    Vue.set(state, 'positionMessages', positionMessages)

    // if (payload.unit.protocol.id === 4) {
    //   /**
    //    * Stop if mobile protocol
    //    */
    //   console.log('stopping mobile unit for ', payload)
    //   return
    // }

    // updating game control
    const temp = calculateGameControl({
      messages: state.messages,
      units: this.getters['units/byId'],
      courses: this.getters['courses/byId'],
      holes: this.getters['courses/holesById'],
      geofences: this.getters['restrictedZones/byId']
    })

    if (temp === undefined) {
      return
    }

    Vue.set(state, 'gameControl', temp.gameControl)

    const unitsLocation = parsedArrayToDict(temp.unitsLocation, 'unitId')
    Vue.set(state, 'unitsLocation', unitsLocation)

    const parsedGameControl = parsedArrayToDict(temp.gameControl, 'unitId')
    Vue.set(state, 'parsedGameControl', parsedGameControl)
  },
  setMaintenances(state, payload) {
    state.maintenances = {}
    // state.maintenances = payload
    Vue.set(state, 'maintenances', payload)
  },

  setMaintenance(state, payload) {
    // state.maintenances[payload.unitId] = Object.assign({}, payload)
    Vue.set(state.maintenances, payload.unitId, payload)
  },

  setPaceOfPlay(state, payload) {
    let data = {
      current: payload.current,
      finished: payload.finished,
      overtime: payload.overtime,
      allowed: payload.allowed,
      average: payload.average,
      startAt: payload.startAt
    }

    if (payload.finished) {
      data = {
        current: null,
        finished: null,
        overtime: null,
        allowed: null,
        average: null,
        startAt: null
      }
    }

    if (!state.messages[payload.unitId]) {
      // state.messages[payload.unitId] = {}
      Vue.set(state.messages, payload.unitId, {})
    }
    // state.messages[payload.unitId].paceOfPlay = Object.assign({}, data)
    Vue.set(state.messages[payload.unitId], 'paceOfPlay', data)

    // updating game control
    const temp = calculateGameControl({
      messages: state.messages,
      units: this.getters['units/byId'],
      courses: this.getters['courses/byId'],
      holes: this.getters['courses/holesById'],
      geofences: this.getters['restrictedZones/byId']
    })
    if (temp === undefined) {
      return
    }
    // setting game control
    Vue.set(state, 'gameControl', temp.gameControl)
    // setting units location
    const unitsLocation = parsedArrayToDict(temp.unitsLocation, 'unitId')
    Vue.set(state, 'unitsLocation', unitsLocation)
    // setting parsedGameControl
    const parsedGameControl = parsedArrayToDict(temp.gameControl, 'unitId')
    Vue.set(state, 'parsedGameControl', parsedGameControl)
  },

  addUnit(state, payload) {
    // state.activeUnits.push(payload)
    Vue.set(state.activeUnits, state.activeUnits.length, payload)
  },

  removeUnit(state, payload) {
    state.activeUnits = state.activeUnits.filter(item => { return item !== payload })
  },

  setGameControl(state, payload) {
    Vue.set(state, 'gameControl', payload)
    Vue.set(state, 'parsedGameControl', parsedArrayToDict(payload))
  },

  increaseSeconds(state) {
    for (const i in state.gameControl) {
      state.gameControl[i].current += 0.016
      state.gameControl[i].remaining = state.gameControl[i].allowed - state.gameControl[i].current
    }
  },

  setVisibility(state, payload) {
    state.visibility = payload

    cookies.set('visibility', state.visibility)
  },

  setCategoryVisibility(state, payload) {
    state.categoryVisibility = payload

    cookies.set('categoryVisibility', state.categoryVisibility)
  },

  setDefaultVisibility(state, payload) {
    state.defaultVisibility = payload
  },

  setDefaultCategoryVisibility(state, payload) {
    state.defaultCategoryVisibility = payload
  },
  setTooltipCorner(state, payload) {
    state.tooltipCorner = payload
  },

  clearMessages(state, _) {
    Vue.set(state, 'messages', {})
  }
}