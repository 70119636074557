const tokens = {}

const rawTokens = {
  realtime: 'TOoIe9akvo7hUQ5L9RD7WWsT70GBe7XXLatP7rXrGVyqzt1yastVeJEbybBC6mnH'
}

tokens.install = (Vue) => {
  Vue.prototype.$tokens = rawTokens
}

export { rawTokens, tokens }

export default tokens