<template>
  <v-chip :color="`white--text ${colorByInterval}`">
    {{ textByInterval }}
  </v-chip>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: () => undefined
    }
  },

  computed: {
    colorByInterval () {
      if (this.value === undefined) {
        return 'grey darken-2'
      } else if (this.value >= 1 && this.value <= 2) {
        return 'green darken-2'
      } else if (this.value >= 2 && this.value <= 5) {
        return 'yellow darken-2'
      } else if (this.value >= 5 && this.value <= 10) {
        return 'orange darken-2'
      } else if (this.value >= 10 && this.value <= 20) {
        return 'red darken-2'
      }

      return 'grey darken-2'
    },

    textByInterval () {
      if (this.value === undefined) {
        return 'Not signal'
      } else if (this.value >= 1 && this.value <= 2) {
        return 'Excellent'
      } else if (this.value >= 2 && this.value <= 5) {
        return 'Good'
      } else if (this.value >= 5 && this.value <= 10) {
        return 'Moderate'
      } else if (this.value >= 10 && this.value <= 20) {
        return 'Fair'
      }

      return 'Poor'
    }
  }
}
</script>