<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-icon
        class="ma-0 ml-2"
        :color="colorByInterval"
        v-on="on"
      >
        {{ iconByInterval }}
      </v-icon>
    </template>
    <span>
      {{ $t('signal.satellite.connection', {'satellites': value}) }}
    </span>
  </v-tooltip>
</template>

<script>
/**
 * Show the signal strength of a device
 * based on the number of satellites it is connected to
 *
 * 0 satellites: 1 bar in red
 * 1-3 satellites: 2 bars in yellow
 * 4+ satellites: 3 bars in green
 */
export default {
  props: {
    value: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    colorByInterval () {
      if (this.value === 0) {
        return 'red darken-2'
      } else if (this.value >= 1 && this.value <= 3) {
        return 'yellow darken-2'
      } else if (this.value >= 4 ) {
        return 'green darken-2'
      }

      return 'grey lighten-1'
    },

    textByInterval () {
      if (this.value === undefined) {
        return this.$i18n.t('signal.status.na')
      } else if (this.value >= 1 && this.value <= 2) {
        return this.$i18n.t('signal.status.excellent')
      } else if (this.value >= 2 && this.value <= 5) {
        return this.$i18n.t('signal.status.good')
      } else if (this.value >= 5 && this.value <= 10) {
        return this.$i18n.t('signal.status.moderate')
      } else if (this.value >= 10 && this.value <= 20) {
        this.$i18n.t('signal.status.fair')
      }

      return this.$i18n.t('signal.status.poor')
    },
    iconByInterval () {
      if (this.value === 0) {
        return 'mdi-signal-cellular-1'
      } else if (this.value >= 1 && this.value <= 3) {
        return 'mdi-signal-cellular-2'
      } else if (this.value >= 4 ) {
        return 'mdi-signal-cellular-3'
      }

      return 'mdi-signal-cellular-outline'
    }
  }
}
</script>

<style>

</style>