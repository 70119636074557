import app from './app'
import login from './login'

export default [
  {
    path: '/CMS',
    redirect: '/CMS/Login'
  },
  ...app,
  login
]