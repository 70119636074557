var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('gm-navbar'),_vm._v(" "),_c('gm-platform-sidebar',{attrs:{"is-mobile":_vm.isMobile,"dark":true,"mobile-links":_vm.links,"background-color":"black","state":_vm.drawer,"name":_vm.$t('app.title'),"logo":_vm.$assets.faviconWhite,"avatar":_vm.avatar,"full-name":_vm.fullName,"mini-variant":false,"actions":_vm.actions,"permanent":false},on:{"set-state":function (value) { return _vm.$store.commit('setDrawer', value); }}}),_vm._v(" "),_c('v-main',[_c('div',{staticStyle:{"position":"absolute","top":"0","left":"0","right":"0","bottom":"0","z-index":"1","pointer-events":"none","overflow":"hidden"}},[_c('vue-draggable-resizable',{staticStyle:{"pointer-events":"auto"},attrs:{"w":_vm.width,"h":_vm.height,"x":_vm.x,"y":_vm.y,"parent":true,"drag-handle":'.drag-handle',"class-name-handle":"vue-resize-handle","handles":['ml','mr'],"draggable":!_vm.isDragLocked,"resizable":!_vm.isResizeLocked,"active":_vm.activeResizeDrag},on:{"update:active":function($event){_vm.activeResizeDrag=$event},"resizing":_vm.onResize,"dragging":_vm.onDrag}},[_c('v-card',{attrs:{"dark":""}},[_c('v-card-title',{staticClass:"pa-1"},[_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.isDragLocked = !_vm.isDragLocked}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("\n                    "+_vm._s(_vm.isDragLocked ? 'mdi-lock' : 'mdi-lock-open-variant')+"\n                  ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('app.menu.lock_drag')))])]),_vm._v(" "),_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.isResizeLocked = !_vm.isResizeLocked}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("\n                    "+_vm._s(_vm.isResizeLocked ? 'mdi-arrow-horizontal-lock' : 'mdi-arrow-expand')+"\n                  ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('app.menu.lock_resize')))])]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{on:{"click":function($event){_vm.activeResizeDrag = true}}},[_vm._v("\n                    mdi-arrow-expand-horizontal\n                  ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('app.menu.activate_resize')))])]),_vm._v(" "),_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"drag-handle",staticStyle:{"cursor":"move"}},'v-icon',attrs,false),on),[_vm._v("\n                  mdi-drag\n                ")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('app.menu.drag_handle')))])])],1)],1),_vm._v(" "),_c('div',{staticClass:"main-content"},[_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[_c('router-view')],1)],1),_vm._v(" "),_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[(_vm.isHolesEditting && _vm.isSingleMode)?_c('div',{staticClass:"column-abs-2",style:(("left: " + _vm.absOffsetContainer2 + ";"))},[_c('gm-holes')],1):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[(_vm.isHolesEditting && _vm.isSingleMode)?_c('div',{staticClass:"column-abs-3",style:(("left: " + _vm.absOffsetContainer3 + ";"))},[_c('gm-pace-of-play')],1):_vm._e()])],1)],1),_vm._v(" "),_c('div',{staticClass:"column-abs-final"},[_c('v-card',{staticClass:"grey-opacity pa-2",staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"dark":""}},[_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"open-on-hover":false,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","depressed":"","color":"transparent"}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}])},[_vm._v(" "),_c('v-list',_vm._l((_vm.options),function(item,i){return _c('v-list-item',{key:item.value,on:{"click":function($event){return _vm.toggleOptions(i)}}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"readonly":"","color":"primary","dense":"","hide-details":"","label":item.text},model:{value:(item.isSelected),callback:function ($$v) {_vm.$set(item, "isSelected", $$v)},expression:"item.isSelected"}})],1)}),1)],1),_vm._v(" "),_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"open-on-hover":false,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","depressed":"","color":"transparent"}},on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_vm._v(" "),_c('v-list',_vm._l((_vm.categoryOptions),function(item,i){return _c('v-list-item',{key:item.value,on:{"click":function($event){return _vm.toggleCategory(i)}}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"readonly":"","color":"primary","dense":"","hide-details":"","label":item.text},model:{value:(item.isSelected),callback:function ($$v) {_vm.$set(item, "isSelected", $$v)},expression:"item.isSelected"}})],1)}),1)],1),_vm._v(" "),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.toggleTooltipCorner}},[_c('v-icon',{style:(("transform: rotate(" + _vm.popupCornerRotation + "deg);"))},[_vm._v("\n            "+_vm._s(_vm.currentCorner === 0 ? 'mdi-card-outline' : 'mdi-chevron-left')+"\n          ")])],1)],1)],1),_vm._v(" "),_c('gm-map')],1),_vm._v(" "),_c('gm-footer',{attrs:{"is-login":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }