<template>
  <v-bottom-sheet v-model="dialog" persistent hide-overlay>
    <v-card color="primary white--text">
      <v-card-title>
        <h3>{{ $t('cookies.title') }}</h3>
      </v-card-title>

      <v-card-text class="white--text">
        <h4>{{ $t('cookies.description') }}</h4>
      </v-card-text>

      <v-card-actions class="d-flex justify-center">
        <v-btn @click="dialog = false">{{ $t('actions.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['isCookies']),

    dialog: {
      get () {
        return !this.isCookies
      },

      set (value) {
        this.$store.commit('setCookies', value)
      }
    }
  }
}
</script>