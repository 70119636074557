import Layout from '@/layouts/cms/Main.vue'
import Home from '@/views/cms/Home.vue'
import Marshalls from '@/views/cms/Marshalls.vue'
import Consumers from '@/views/cms/Consumers.vue'
import Icons from '@/views/cms/Icons.vue'
import Units from '@/views/cms/Units.vue'
import Categories from '@/views/cms/Categories.vue'
import Customers from '@/views/cms/Customers.vue'
import Operators from '@/views/cms/Operators.vue'

export default [
  {
    path: '/CMS',
    component: Layout,
    children: [
      {
        path: 'Home',
        name: 'cmsHome',
        components: { default: Home }
      },
      {
        path: 'Marshalls',
        name: 'cmsMarshalls',
        components: { default: Marshalls }
      },
      {
        path: 'Consumers',
        name: 'cmsConsumers',
        components: { default: Consumers }
      },
      {
        path: 'Icons',
        name: 'icons',
        components: { default: Icons }
      },
      {
        path: 'Units',
        name: 'cmsUnits',
        components: { default: Units }
      },
      {
        path: 'Categories',
        name: 'categories',
        components: { default: Categories }
      },
      {
        path: 'Customers',
        name: 'customers',
        components: { default: Customers }
      },
      {
        path: 'Operators',
        name: 'operators',
        components: { default: Operators }
      }
    ]
  }
]