/*
  Course getters
*/
export default {
  defaultObject () {
    return {
      id: undefined,
      name: 'New course',
      customerId: null,
      courseColor: '#078a1f',
      holeColor: '#0299b8',
      iconId: null,
      typeOf: 'FULL',
      mode: 'NORMAL',
      childsIds: [],
      parentId: null,
      almostThere: 2,
      actions: [
        {
          id: undefined,
          type: 'MARSHALL',
          willExecute: true
        },
        {
          id: undefined,
          type: 'USER',
          willExecute: true
        },
        {
          id: undefined,
          type: 'CUTOFF',
          willExecute: true
        }
      ]
    }
  },

  defaultActions () {
    return [
      {
        id: undefined,
        type: 'MARSHALL',
        willExecute: true
      },
      {
        id: undefined,
        type: 'USER',
        willExecute: true
      },
      {
        id: undefined,
        type: 'CUTOFF',
        willExecute: true
      }
    ]
  },

  grouped (state) {
    const result = {}

    for (const i in state.list) {
      if (state.list[i].parentId) {
        if (result[state.list[i].parentId]) {
          result[state.list[i].parentId].noHoles += state.list[i].typeOf === 'FULL' ? 18 : 9
          result[state.list[i].parentId].childs.push(state.list[i])
        }
      } else {
        result[state.list[i].id] = {
          ...state.list[i],
          noHoles: 0,
          childs: []
        }
      }
    }
    return Object.values(result)
  },

  byId (state) {
    const result = {}

    for (const i in state.list) {
      result[state.list[i].id] = state.list[i]
    }

    return result
  },

  holesById (state) {
    const result = {}
    for (const i in state.list) {
      const course = state.list[i]

      for (const j in course.holes) {
        result[course.holes[j].id] = course.holes[j]
      }
    }
    return result
  }
}
