/*
  Course mutations
*/
export default {
  setList (state, payload) {
    state.list = []
    state.list = payload
  },

  /**
   * Set holes when in courses form
   * @param {*} state
   * @param {*} payload
   */
  setHoles (state, payload) {
    // console.log('set holes mutation')
    state.holes = {}
    state.holes = payload
  },
  /**
   *
   * @param {*} state
   * @param {*} courses
   */
  setHolesList (state, courses) {
    state.holesList = []
    let result = []
    for (const i in courses) {
      result = result.concat(courses[i].holes)
    }
    state.holesList = result
  },

  setGeofence (state, payload) {
    state.geofence = []
    state.geofence = payload
  },

  setEdittingId (state, payload) {
    state.edittingId = payload
  },

  setCourseColor (state, payload) {
    state.courseColor = payload
  },

  setHoleColor (state, payload) {
    state.holeColor = payload
  }
}
