<template>
  <v-img :src="image" contain :width="200" />
</template>

<script>
export default {
  props: {
    primary: {
      type: String,
      default: () => '#48aae0'
    },
    secondary: {
      type: String,
      default: () => '#036fa7'
    }
  },

  computed: {
    image () {
      return 'data:image/svg+xml,' + encodeURIComponent(this.inlineSvg)
    },

    inlineSvg () {
      return `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><defs><style>.cls-1{fill:${this.primary};}.cls-2{fill:${this.secondary};}.cls-3,.cls-4{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:1.09px;}.cls-4{stroke-dasharray:4.36 4.36;}.cls-5{fill:#fafafa;}</style></defs><title>powertrakker-golf-cap</title><path class="cls-1" d="M42.8,189.76s167.29-274.42,392-27.21c0,0,92.4,99.89,48.29,217,0,0-218.71-110.26-474.63-19.46C8.49,360.11-11.32,286.58,42.8,189.76Z"/><path class="cls-2" d="M8.49,360.11S201,182.69,489.25,354.68c0,0,10.8,87.18-62.85,76.73,0,0-146-73.7-362.85,9.22C63.55,440.63,24.24,445.38,8.49,360.11Z"/><path class="cls-3" d="M469.15,348.5a24.76,24.76,0,0,1,.39,2.74"/><path class="cls-4" d="M469.55,356.78c-.87,10.15-7.35,25.53-39.68,21.53,0,0-146-73.71-362.86,9.22,0,0-31.36,15.74-40.12-35.64"/><path class="cls-3" d="M26.45,349.11c-.14-.9-.26-1.82-.38-2.75"/><path class="cls-1" d="M489.25,354.68c-2.42,25-14.25,60.6-62.85,53.71,0,0-146-73.7-362.85,9.22,0,0-33.07,3.95-50.69-61.22-2.83,2.32-4.37,3.72-4.37,3.72,15.75,85.27,55.06,80.51,55.06,80.51,216.89-82.91,362.85-9.22,362.85-9.22,73.65,10.46,62.85-76.72,62.85-76.72Z"/><path class="cls-5" d="M232.6,65.39s-124,69.7-102.88,230.72c0,0,90.52-37.17,220.29-1.7C350,294.41,360.83,115.75,232.6,65.39Z"/></svg>`
    }
  }
}
</script>