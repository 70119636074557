import { Loader } from '@googlemaps/js-api-loader'

const googlemaps = {}

googlemaps.install = (Vue) => {
  Vue.prototype.$googlemaps = new Loader({
    apiKey: 'AIzaSyDm3PUrP5kSJeLEk_W3qsjmwcr5v0uBUAQ',
    version: 'weekly',
    libraries: ['drawing'],
    language: 'en'
  })
}

export default googlemaps