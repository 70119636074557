<template>
  <v-expansion-panels v-model="currentPanel" dark>
    <v-expansion-panel class="grey-opacity">
      <v-expansion-panel-header>
        {{ $t(`headers.${$route.name}`) }}
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pa-0">
        <div class="scrollbar__seamless expansion__content">
          <v-btn text small @click="newItem">
            <v-icon small>mdi-plus-circle-outline</v-icon>
            &nbsp;
            {{ $t('actions.new') }}
          </v-btn>

          <v-data-table
            :headers="headers"
            :items="restrictedZones"
            fixed-header
            multi-sort
            class="transparent datatable__custom"
            height="55vh"
            :items-per-page="-1"
          >
            <template #[`header.name`]="{ header }">
              {{ header.text }}
            </template>
            <template #[`header.type`]="{ header }">
              {{ header.text }}
            </template>

            <template #[`header.actions`]>
              <v-icon small>mdi-wrench</v-icon>
            </template>

            <template v-slot:item="{ item }">
              <tr :key="`playing-${item.id}`">
                <td class="white--text">
                  {{ item.name }}
                </td>

                <td class="white--text">
                  {{ $t(`restrictedZones.types.${item.type.toLowerCase()}`) }}
                </td>

                <td class="text-right white--text">
                  <v-tooltip right color="blue darken-2">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on">
                        <v-icon small @click="editItem(item.id)">mdi-pencil</v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('actions.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip right color="red darken-4">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on">
                        <v-icon
                          small
                          color="red"
                          @click="openDeleteDialog(item.id)"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('actions.delete') }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel class="grey-opacity">
      <v-expansion-panel-header>{{ $t('actions.editor') }}</v-expansion-panel-header>

      <v-expansion-panel-content>
        <div class="scrollbar__seamless form--expansion__content">
          <template v-if="isEditting">
            <v-layout row wrap class="pt-2 mx-1">
              <v-flex xs12>
                <v-text-field
                  v-model="object.name"
                  :label="$t('restrictedZones.name')"
                  :error="errors.name.length > 0"
                  :error-messages="errors.name"
                  outlined
                />
              </v-flex>
              <v-flex v-if="isAdmin" xs12>
                <v-select
                  v-model="object.customerId"
                  :label="$t('courses.customer')"
                  :items="customersList"
                  :error="errors.customerId.length > 0"
                  :error-messages="errors.customerId"
                  item-text="username"
                  item-value="id"
                  :disabled="!isEditting"
                  outlined
                />
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="object.type"
                  :label="$t('restrictedZones.type')"
                  :error="errors.type.length > 0"
                  :error-messages="errors.type"
                  :items="types"
                  outlined
                />
              </v-flex>

              <v-flex xs12>
                <v-divider />
              </v-flex>

              <v-flex xs12 class="mt-2">
                <h4 class="text-center">{{ $t('restrictedZones.actions.title') }}</h4>
              </v-flex>

              <v-flex v-for="action in object.actions" :key="`action-${action.type}`" xs12>
                <v-checkbox
                  v-model="action.willExecute"
                  :label="$t(`restrictedZones.actions.${action.type.toLowerCase()}`)"
                  hide-details
                />
              </v-flex>

              <v-flex xs12>
                <v-btn
                  text
                  block
                  :color="`${drawing ? 'warning' : 'primary'}`"
                  @click="draw"
                >
                  {{ $t(`restrictedZones.draw.${drawing}`) }}
                </v-btn>
              </v-flex>

              <v-flex xs12 class="mb-3 d-flex justify-center">
                <v-btn
                  text
                  :loading="isLoading"
                  color="error"
                  @click="ignoreItem"
                >
                  {{ $t('actions.ignore') }}
                </v-btn>
                <v-btn
                  text
                  :loading="isLoading"
                  :disabled="!allowed"
                  color="success"
                  @click="saveItem"
                >
                  {{ $t('actions.save') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </template>

          <template v-else>
            <h4 class="text-center pa-2">{{ $t('helpers.notAllowed') }}</h4>
          </template>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <gm-delete-dialog
      v-model="deleteDialog"
      :action="deleteItem"
      :name="geofenceToDeleteName"

      :title="$t('restrictedZones.delete.title')"
      :caption="$t('restrictedZones.delete.caption')"
      :confirm-message="$t('helpers.delete.dialog.confirm')"
      :options="[
        $t('helpers.delete.dialog.message1'),
        $t('helpers.delete.dialog.message2'),
        $t('helpers.delete.dialog.message3')
      ]"
      :id-to-delete="idToDelete"
      @clear="idToDelete = null"
    />
  </v-expansion-panels>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

import add from 'GraphQL/mutations/restrictedZones/add.gql'
import edit from 'GraphQL/mutations/restrictedZones/edit.gql'
import del from 'GraphQL/mutations/restrictedZones/delete.gql'
import details from 'GraphQL/queries/restrictedZones/details.gql'
import DeleteDialog from 'Components/utils/DeleteDialog.vue'

export default {
  components: {
    'gm-delete-dialog': DeleteDialog
  },
  data () {
    return {
      currentPanel: 0,
      object: {},
      isEditting: false,
      isLoading: false,
      rawErrors: {},
      drawing: false,
      deleteDialog: false,
      idToDelete: null
    }
  },

  computed: {
    /**
     * name of the geofence to delete
     */
    geofenceToDeleteName () {
      const geofence = this.restrictedZones.find(geofence => geofence.id === this.idToDelete)
      if (geofence) {
        return geofence.name
      }
      return ''
    },
    ...mapGetters({
      defaultObject: 'restrictedZones/defaultObject',
      protocolById: 'general/protocolById'
    }),
    ...mapState(['general', 'users', 'language', 'customers']),

    isAdmin () {
      return this.user.__typename === 'Admin'
    },

    customersList () {
      return this.customers.list
    },

    protocols () {
      return this.general.protocols
    },

    restrictedZones () {
      return this.$store.state.restrictedZones.list
    },
    restrictedZoneType () {
      return this.object.type
    },
    geofence () {
      return this.$store.state.restrictedZones.geofence
    },

    user () {
      return this.users.entity
    },

    icons () {
      return this.$store.state.icons.list
    },

    types () {
      return [
        {
          text: this.$i18n.t('restrictedZones.types.restricted'),
          value: 'RESTRICTED'
        },
        {
          text: this.$i18n.t('restrictedZones.types.dangerous'),
          value: 'DANGEROUS'
        },
        {
          text: this.$i18n.t('restrictedZones.types.geofence'),
          value: 'GEOFENCE'
        }
      ]
    },

    result () {
      return {
        ...this.object,
        points: this.geofence
      }
    },

    allowed () {
      let result = true

      if (this.result.points.length === 0) {
        result = false
      }

      return result
    },

    headers () {
      return [
        {
          text: this.$i18n.t('courses.name'),
          value: 'name',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('restrictedZones.type'),
          value: 'type',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('actions.actions'),
          value: 'actions',
          class: 'transparent text--center',
          filterable: false,
          sortable: false
        }
      ]
    },

    errors () {
      const errors = {}

      for (const key in this.defaultObject) {
        let keyCamel = key
        if (key.includes('_')) {
          keyCamel = this.convertToCamelCase(key)
        }

        if (this.rawErrors[key] === undefined) {
          errors[keyCamel] = ''
        } else {
          errors[keyCamel] = this.rawErrors[key].join(', ')
        }
      }

      return errors
    }
  },

  watch: {
    currentPanel (newValue, oldValue) {
      if (newValue === 0 && this.isEditting) {
        this.ignoreItem()
        this.$store.commit('clearDrawing')
      }
    },
    restrictedZoneType (newValue, oldValue) {
      this.$store.commit('restrictedZones/setType', newValue)
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
  },

  mounted () {
    // this.newItem()

    this.$store.subscribe((mutation) => {
      if (mutation.type === 'restrictedZones/setGeofence') {
        this.drawing = false
      }
    })
  },

  methods: {
    /**
     * Open the delete dialog
     * to cofirm a delete action
     */
    openDeleteDialog (id) {
      this.idToDelete = id
      this.deleteDialog = true
    },

    draw () {
      if (!this.drawing) {
        this.drawing = true
        this.$store.commit('toggleMap', {
          state: true,
          information: [],
          type: this.object.type,
          module: 'restrictedZones'
        })
      } else {
        this.drawing = false
        this.$store.commit('toggleMap', {
          state: false,
          information: [],
          type: this.object.type,
          module: 'restrictedZones'
        })
      }
    },

    convertToCamelCase (str) {
      str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, (match) => {
        return match.charAt(match.length - 1).toUpperCase()
      })
      return str.charAt(0).toLowerCase() + str.substring(1)
    },

    newItem () {
      this.currentPanel = 1
      this.$store.commit('setRestrictedEditting', true)
      this.isEditting = true
      this.$store.commit('restrictedZones/setGeofence', [])

      this.object = Object.assign({}, this.defaultObject)
    },

    async editItem (id) {
      this.object = Object.assign({}, this.defaultObject)

      await this.$apollo.query({
        query: details,
        variables: {
          apiToken: this.user.apiToken,
          language: this.language,
          id
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status, result } = response.data.restrictedZone

        switch (status) {
          case 'OK':
            this.currentPanel = 1
            this.isEditting = true
            this.$store.commit('setRestrictedEditting', true)

            result.points = result.points.map((item) => {
              return {
                latitude: item.latitude,
                longitude: item.longitude
              }
            })

            result.actions = result.actions.map((item) => {
              return {
                id: item.id,
                type: item.type,
                willExecute: item.willExecute
              }
            })

            this.object = Object.assign({}, result)

            this.$store.commit('restrictedZones/setGeofence', result.points)
            this.$store.commit('restrictedZones/setEdittingId', result.id)
            this.$store.commit('restrictedZones/setType', result.type)
            break

          default:
            this.$store.commit('toggleSnackbar', undefined)
            break
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar', undefined)
      })
    },

    async deleteItem (id) {
      this.object = Object.assign({}, this.defaultObject)

      await this.$apollo.mutate({
        mutation: del,
        variables: {
          apiToken: this.user.apiToken,
          language: this.language,
          id
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status } = response.data.deleteRestrictedZone

        switch (status) {
          case 'OK':
            this.$store.dispatch('restrictedZones/getList', true)
            this.ignoreItem()

            // close delete dialog
            this.deleteDialog = false
            this.idToDelete = null

            break

          default:
            this.$store.commit('toggleSnackbar', undefined)
            break
        }
      }).catch((error) => {
        console.error('RestrictedZones Delete Error', error)
        this.$store.commit('toggleSnackbar', undefined)
      })
    },

    ignoreItem () {
      // console.log('ignored item')
      this.currentPanel = 0
      this.isEditting = false
      this.object = Object.assign({}, this.defaultObject)
      this.$store.commit('setRestrictedEditting', false)
      this.$store.commit('restrictedZones/setEdittingId', undefined)
      this.$store.commit('clearDrawing')
    },

    async saveItem () {
      // console.log(this.result)
      this.rawErrors = {}
      this.isLoading = true
      
      let method = add
      let name = 'addRestrictedZone'

      if (this.object.id !== undefined) {
        method = edit
        name = 'editRestrictedZone'
      }

      const variables = {
        apiToken: this.user.apiToken,
        language: this.language,
        data: this.result
      }

      delete variables.data.__typename

      await this.$apollo.mutate({
        mutation: method,
        variables,
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status, errors } = response.data[name]
        // console.log('Entró en esta vaina', status)
        const errorProcessed = {}
        switch (status) {
          case 'OK':
            this.$store.dispatch('restrictedZones/getList', true)
            /*
              Despues de guardar el item
              se debe esperar un tiempo para que
              se pida la lista de items de nuevo a la base de datos
            */
            setTimeout(() => this.ignoreItem(), 300)
            break

          case 'UNPROCESSABLE':
            for (const key in errors) {
              errorProcessed[this.convertToCamelCase(key)] = errors[key]
            }
            this.rawErrors = errorProcessed
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('errors.invalidFields'),
              color: 'warning'
            })
            break

          default:
            this.$store.commit('toggleSnackbar', undefined)
            break
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar', undefined)
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
