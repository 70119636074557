/*
  Restricted actions
*/

// graphql files
import list from 'GraphQL/queries/restrictedZones/list.gql'

import { apollo } from '@/plugins/apollo'

export default {
  async getList ({ commit, rootState }, payload = false) {
    if (payload) {
      commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: list,
      variables: {
        apiToken: rootState.users.entity.apiToken,
        language: rootState.language
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, result } = response.data.restrictedZones

      switch (status) {
        case 'OK':
          commit('setList', result)
          break
        
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      console.error(error)
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      if (payload) {
        commit('setLoading', false, { root: true })
      }
    })
  }
}
