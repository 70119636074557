<template>
  <v-app-bar
    color="black"
    dark
    :height="60"
    :max-height="60"
  >
    <v-btn
      v-if="isMobile"
      text
      icon
      small
      class="ml-2"
      @click="$store.commit('setDrawer', true)"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-toolbar-title class="d-flex align-center">
      <v-img :src="$assets.faviconWhite" :width="50" />
      {{ $t('app.title') }}
    </v-toolbar-title>

    <v-divider vertical class="mx-2" />
    <v-spacer />

    <div class="d-flex scrollbar__seamless" style="overflow:auto;">
      <template v-if="!isMobile">
        <v-btn
          v-for="(item, index) in links"
          :key="index"
          :to="item.to"
          text
          class="mx-1"
        >
          {{ $t(item.label) }}
        </v-btn>
      </template>
    </div>

    <v-spacer />

    <v-chip color="white black--text" class="mr-2">
      <v-icon small>mdi-clock-outline</v-icon>
      &nbsp;
      {{ currentTime }}
    </v-chip>

    <v-menu
      v-if="!isMobile"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-avatar color="white black--text" size="40" style="cursor: pointer;" v-on="on">
          {{ avatar }}
        </v-avatar>
      </template>

      <v-list>
        <v-list-item @click="$store.dispatch('users/logout', 'Platform')">{{ $t('users.signOut') }}</v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      avatar: 'users/avatar',
      currentTime: 'clock',
      isMobile: 'isMobile'
    }),
    links () {
      return [
        {
          to: '/Platform/Home',
          label: 'headers.home'
        },
        {
          to: '/Platform/Units',
          label: 'headers.units'
        },
        {
          to: '/Platform/Settings',
          label: 'headers.settings'
        },
        {
          to: '/Platform/Restricted',
          label: 'headers.restricted'
        },
        {
          to: '/Platform/PaceOfPlay',
          label: 'headers.paceOfPlay'
        },
        {
          to: '/Platform/Monitor',
          label: 'headers.monitor'
        },
        {
          to: '/Platform/Alerts',
          label: 'headers.alerts'
        },
        {
          to: '/Platform/History',
          label: 'headers.history'
        }
      ]
    }
  }
}
</script>

<style lang="scss" >

</style>
