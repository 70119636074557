<template>
  <v-expansion-panels v-model="currentPanel" dark>
    <v-expansion-panel class="grey-opacity">
      <v-expansion-panel-header>
        {{ $t(`headers.${$route.name}`) }}
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pa-0">
        <div
          class="scrollbar__seamless expansion__content"
        >
          <v-btn
            dense
            text
            small
            color="white"
            @click="dialog = true"
          >
            {{ $t('paceOfPlay.request') }}
          </v-btn>

          <v-data-table
            :headers="headers"
            :items="messages"
            fixed-header
            multi-sort
            sort-by="remaining"
            class="transparent datatable__custom"
            height="55vh"
            :items-per-page="-1"
          >
            <template v-slot:item="{ item }">
              <!-- {{ item.unitId }} -->
              <tr>
                <td class="white--text text-center">
                  <v-btn
                    small
                    icon
                    @click="toggleCenter(item.unitId)"
                  >
                    <v-icon x-small>mdi-crosshairs{{ activeUnits.includes(item.unitId) ? '-gps' : '' }}</v-icon>
                  </v-btn>
                </td>
                <td class="white--text text-center">
                  {{ item.unit }}
                </td>
                <td class="white--text text-center">
                  {{ item.course }}
                </td>
                <td class="white--text text-center">
                  {{ item.hole }}
                </td>
                <td class="white--text text-center">
                  {{ $conversor.formatMinutes(item.allowed, false) }}
                </td>
                <!-- <td class="white--text text-center">
                  {{ $conversor.formatMinutes(item.average, false) }}
                </td> -->
                <td class="white--text text-center">
                  {{ $conversor.formatMinutes(item.current, false) }}
                </td>
                <td class="white--text text-center">
                  {{ $conversor.formatMinutes(item.remaining, false) }}
                </td>
                <td class="white--text text-center">
                  <gm-status type="basic" :message="item" :offset="item.offset" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-dialog
      v-model="dialog"
      :fullscreen="isMobile"
      :max-width="width.dialog"
      scrollable
      persistent
      :overlay="false"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>{{ $t('paceOfPlay.dialog.title') }}</v-card-title>
        
        <v-divider class="my-2 mx-4" />
        
        <v-card-text class="py-2">
          <v-stepper v-model="step" vertical class="elevation-0">
            <v-stepper-step
              :step="1"
              :complete="step > 1"
            >
              {{ $t('paceOfPlay.dialog.steps.selectCourse') }}
            </v-stepper-step>

            <v-stepper-content :step="1">
              <v-select
                v-model="courseId"
                :items="coursesSelector"
                outlined
                hide-details
                :placeholder="$t('paceOfPlay.dialog.steps.selectCourse')"
              />

              <v-btn
                :disabled="!courseId || isLoading"
                :loading="isLoading" color="primary"
                class="mt-4"
                @click="fetchData"
              >
                {{ $t('actions.next') }}
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :step="2">{{ $t('paceOfPlay.dialog.steps.display') }}</v-stepper-step>

            <v-stepper-content :step="2">
              <v-simple-table fixed-header :height="height">
                <thead>
                  <tr>
                    <th class="text-center grey darken-4 white--text">
                      {{ $t('paceOfPlay.dialog.headers.hole') }}
                    </th>
                    <th class="text-center grey darken-4 white--text">
                      {{ $t('paceOfPlay.dialog.headers.pace') }}
                    </th>
                    <th class="text-center grey darken-4 white--text">
                      {{ $t('paceOfPlay.dialog.headers.allowed') }}
                    </th>
                    <th class="text-center grey darken-4 white--text">
                      {{ $t('paceOfPlay.dialog.headers.onTime') }}
                    </th>
                    <th class="text-center grey darken-4 white--text">
                      {{ $t('paceOfPlay.dialog.headers.onOver') }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="hole in data" :key="`hole-metric-${hole.name}`">
                    <td class="text-center">Hole {{ hole.name }}</td>
                    <td class="text-center">
                      <template v-if="hole.average">
                        {{ $conversor.formatMinutes(hole.average) }}
                      </template>
                      <template v-else>
                        {{ $t('helpers.errors.insufficient') }}
                      </template>
                    </td>
                    <td class="text-center">
                      <template v-if="hole.paceOfPlay">
                        {{ $conversor.formatMinutes(hole.paceOfPlay) }}
                      </template>
                      <template v-else>
                        {{ $t('helpers.errors.insufficient') }}
                      </template>
                    </td>
                    
                    <td class="text-center">
                      <template v-if="hole.percentOnTime">
                        {{ hole.percentOnTime }}%
                      </template>
                      <template v-else>
                        {{ $t('helpers.errors.insufficient') }}
                      </template>
                    </td>

                    <td class="text-center">
                      <template v-if="hole.percentOnTime">
                        {{ 100 - hole.percentOnTime }}%
                      </template>
                      <template v-else>
                        {{ $t('helpers.errors.insufficient') }}
                      </template>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              
              <v-divider class="mx-2 my-8" />

              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-center grey darken-4 white--text">
                      {{ $t('paceOfPlay.dialog.headers.totalTime') }}
                    </th>
                    <th class="text-center grey darken-4 white--text">
                      {{ $t('paceOfPlay.dialog.headers.totalOnTime') }}
                    </th>
                    <th class="text-center grey darken-4 white--text">
                      {{ $t('paceOfPlay.dialog.headers.totalOnOver') }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td class="text-center">
                      {{ $conversor.formatHours(generalMetrics.totalTime) }}
                    </td>
                    <td class="text-center green darken-2 white--text">
                      {{ generalMetrics.totalOnTime }}%
                    </td>
                    <td class="text-center red darken-2 white--text">
                      {{ generalMetrics.totalOnOver }}%
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-stepper-content>
          </v-stepper>
        </v-card-text>

        <v-divider class="my-2 mx-4" />

        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="white--text red darken-2"
            @click="dialog = false; step = 1;"
          >
            {{ $t('actions.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expansion-panels>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { PaceOfPlay } from 'Components/utils'
import metrics from 'GraphQL/queries/courses/metrics.gql'

export default {
  components: {
    'gm-status': PaceOfPlay
  },

  data () {
    return {
      currentPanel: 0,
      interval: null,
      dialog: false,
      step: 1,
      isLoading: false,
      courseId: null,
      data: []
    }
  },

  computed: {
    ...mapState(['general', 'width', 'language', 'users']),
    ...mapGetters({
      units: 'units/byId',
      courses: 'courses/byId',
      holes: 'courses/holesById'
    }),
    ...mapGetters(['isMobile']),
    activeUnits () {
      return this.general.activeUnits
    },
    user () {
      return this.users.entity
    },
    /**
     *
     */
    coursesSelector () {
      return this.$store.state.courses.list.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      })
    },

    height () {
      return window.innerHeight * 0.40
    },

    messages () {
      // console.log('game control updated')
      return this.general.gameControl
    },

    generalMetrics () {
      let totalTime = 0
      let totalOnTime = 0
      let totalWithData = 0

      for (const i in this.data) {
        totalTime += this.data[i].average || 0
        if (this.data[i].percentOnTime) {
          totalOnTime += this.data[i].percentOnTime
          totalWithData++
        }
      }

      totalTime /= 60
      totalOnTime /= totalWithData
      totalOnTime = parseInt(totalOnTime * 100) / 100

      return {
        totalTime,
        totalOnTime,
        totalOnOver: 100 - totalOnTime
      }
    },

    sources () {
      return [
        {
          text: this.$i18n.t('paceOfPlay.dialog.sources.holes'),
          value: 'HOLES'
        },
        {
          text: this.$i18n.t('paceOfPlay.dialog.sources.units'),
          value: 'UNITS'
        }
      ]
    },
    /**
     *
     */
    holesOptions () {
      let options = []
      const courses = this.$store.state.courses.list

      for (const i in courses) {
        for (const j in courses[i].holes) {
          options.push({
            value: courses[i].holes[j].id,
            text: `Hole ${courses[i].holes[j].name} in Course ${courses[i].name}`
          })
        }
      }

      options = options.sort((a, b) => { return parseInt(a.value) > parseInt(b.value) ? 1 : -1 })

      return options
    },

    headers () {
      return [
        {
          text: '',
          value: 'unit',
          sortable: false,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('paceOfPlay.cart'),
          value: 'unit',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('paceOfPlay.course'),
          value: 'course',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('paceOfPlay.hole'),
          value: 'hole',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('paceOfPlay.allowed'),
          value: 'allowed',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        // {
        //   text: this.$i18n.t('paceOfPlay.average'),
        //   value: 'average',
        //   sortable: true,
        //   align: 'center',
        //   class: 'transparent text--center'
        // },
        {
          text: this.$i18n.t('paceOfPlay.current'),
          value: 'current',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('paceOfPlay.remaining'),
          value: 'remaining',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('paceOfPlay.status'),
          value: 'status',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        }
      ]
    }
  },

  methods: {
    toggleCenter (unitId) {
      if (this.activeUnits.includes(unitId)) {
        this.$store.commit('general/removeUnit', unitId)
      } else {
        this.$store.commit('general/addUnit', unitId)
      }
    },
    /**
     *
     */
    fetchData () {
      this.isLoading = true

      this.$apollo.query({
        query: metrics,
        variables: {
          apiToken: this.user.apiToken,
          language: this.language,
          courseId: this.courseId
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status, result } = response.data.courseMetrics

        if (status === 'OK') {
          this.step++
          this.data = result
        } else {
          this.$store.commit('toggleSnackbar', undefined)
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar', undefined)
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss">
  .fix-chip-2 {
    width: 150px;

    .v-chip__content {
      text-align: center;
      margin: auto;
    }
  }
</style>

<style lang="scss" scoped>
.expansion__content{
  max-height: calc(100vh - 260px);
  padding: 0 10px;
  overflow: hidden;
}
</style>
