import cookies from 'js-cookie'

export default {
  setSidebar (state, payload) {
    state.sidebar = payload
  },

  toggleDark (state) {
    state.isDark = !state.isDark

    cookies.set('dark', state.isDark)
  },

  setDark (state, payload) {
    state.isDark = payload

    cookies.set('dark', state.isDark)
  },

  toggleDrawer (state) {
    state.drawer = !state.drawer
  },

  contractDrawer (state) {
    state.drawer = false
  },

  setDrawer (state, payload) {
    state.drawer = payload
  },

  setLoading (state, payload) {
    state.isLoading = payload
  },

  setCookies (state, payload) {
    cookies.set('state', !payload)
    state.isCookies = !payload
  },

  setMini (state, payload) {
    state.isMini = payload

    cookies.set('mini', state.isMini)
  },

  setHolesEditting (state, payload) {
    state.isHolesEditting = payload
  },

  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  setRestrictedEditting (state, payload) {
    state.isRestrictedEditting = payload
  },

  setSingleMode (state, payload) {
    state.isSingleMode = payload
  },

  setWidth (state, payload) {
    if (payload < 930) {
      state.width = {
        full: payload,
        dialog: payload
      }
    } else {
      state.width = {
        full: payload,
        dialog: payload * 0.8333333333
      }
    }
  },

  toggleSnackbar (state, payload) {},

  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  toggleMap (state, payload) {
  },

  clearDrawing (state, payload) {
  },

  setMap (state, payload) {
    state.map = payload
  },

  setGoogle (state, payload) {
    state.google = payload
  },

  setTripState (state, payload) {
    state.hasTripDrawed = payload
  },

  drawTrip (state, payload) {},

  cleanTrip (state, payload) {},

  updateClock (state, payload) {
    state.clock = payload
  },

  focusMap (state, payload) {}

}