<template>
  <v-expansion-panels v-model="currentPanel" dark>
    <v-expansion-panel class="grey-opacity">
      <v-expansion-panel-header>
        {{ $t(`headers.${$route.name}`) }}
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pa-0">
        <div class="scrollbar__seamless expansion__content" style="overflow-y: scroll;">
          <v-layout row wrap class="mx-1 pt-2">
            <v-flex xs12>
              <v-checkbox
                v-model="selectAll"
                class="pa-4"
                :label="selectAll ? $t('labels.deselectAll') : $t('labels.selectAll')"
              />
            </v-flex>
            <v-flex xs12>
              <v-autocomplete
                v-model="object.unitsIds"
                :label="$t('trips.unitId')"
                :items="units"
                :error="errors.unitId.length > 0"
                :error-messages="errors.unitId"
                item-text="name"
                item-value="id"
                multiple
                dark
                outlined
              />
            </v-flex>

            <v-flex xs12>
              <v-datetime-picker
                v-model="object.begin"
                :label="$t('trips.begin')"
                date-format="MMMM dd, yyyy"
                time-format="hh:mm:ss a"
                :time-picker-props="{useSeconds: true, ampmInTitle: true}"
                outlined
              />
            </v-flex>

            <v-flex xs12>
              <v-datetime-picker
                v-model="object.end"
                :label="$t('trips.end')"
                date-format="MMMM dd, yyyy"
                time-format="hh:mm:ss a"
                :time-picker-props="{useSeconds: true, ampmInTitle: true}"
                outlined
              />
            </v-flex>

            <v-flex xs12 class="mb-3 pb-4 d-flex justify-center">
              <v-btn
                v-if="hasTripDrawed"
                text
                :loading="isLoading"
                color="red darken-2"
                @click="$store.commit('trips/cleanAllTrips')"
              >
                {{ $t('actions.clean') }}
              </v-btn>

              <v-btn
                text
                :loading="isLoading"
                :disabled="!(object.unitsIds.length > 0 && object.begin && object.end)"
                color="success"
                @click="drawingMultipleTrips"
              >
                {{ $t('actions.execute') }}
              </v-btn>
            </v-flex>
            <v-flex xs12 class="pa-4" style="height: 90px;">
              <v-layout row wrap>
                <div
                  v-for="(trip, index) in tripsByUnits"
                  :key="index"
                  class="ma-1 d-flex align-center"
                >
                  <v-btn
                    fab
                    small
                    :color="trip.color"
                    class="mx-1"
                    @click="focusMap(trip.path[0])"
                  >
                    <v-icon dark>mdi-crosshairs-gps</v-icon>
                  </v-btn>
                  <p class="ma-0">
                    {{ trip.unitName }}
                  </p>
                </div>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
// import messagesUnit from 'GraphQL/queries/trips/messagesUnit.gql'
import messagesByUnits from 'GraphQL/queries/trips/messagesByUnits.gql'

export default {
  data () {
    return {
      currentPanel: 0,
      rawErrors: {},
      object: {},
      isLoading: false,
      selectAll: false
    }
  },

  computed: {
    ...mapGetters({
      defaultObject: 'trips/defaultObject'
    }),
    ...mapState(['general', 'users', 'language', 'hasTripDrawed']),

    tripsByUnits () {
      return this.$store.state.trips.tripsByUnits
    },

    user () {
      return this.users.entity
    },

    units () {
      /**
       * JSON parse to force new variable
       * */
      const units = JSON.parse(
        JSON.stringify(this.$store.state.units.tableList)
      )
      return units.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (a.name < b.name) {
          return -1
        }
        // a must be equal to b
        return 0
      })
      // return units
    },
    
    errors () {
      const errors = {}

      for (const key in this.defaultObject) {
        let keyCamel = key
        if (key.includes('_')) {
          keyCamel = this.convertToCamelCase(key)
        }

        if (this.rawErrors[key] === undefined) {
          errors[keyCamel] = ''
        } else {
          errors[keyCamel] = this.rawErrors[key].join(', ')
        }
      }

      return errors
    }
  },

  watch: {
    selectAll (value) {
      if (value) {
        this.object.unitsIds = this.units.map(unit => unit.id)
      } else {
        this.object.unitsIds = []
      }
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
  },

  mounted () {
    this.object.begin = moment().format('YYYY-MM-DD')
    this.object.begin = moment(this.object.begin + ' 00:00:00').toDate()

    this.object.end = moment().format('YYYY-MM-DD')
    this.object.end = moment(this.object.end + ' 23:59:59').toDate()
  },

  methods: {
    focusMap (position) {
      this.$store.commit('focusMap', position)
    },
    convertToCamelCase (str) {
      str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, (match) => {
        return match.charAt(match.length - 1).toUpperCase()
      })
      return str.charAt(0).toLowerCase() + str.substring(1)
    },

    drawingMultipleTrips () {
      if (this.object.unitsIds.length === 0) {
        this.$store.commit('toggleSnackbar', {
          message: this.$i18n.t('errors.unitRequired'),
          color: 'warning'
        })
        return
      }

      const dateEnd = (Date.parse(this.object.end) / 1000)
      const dateBegin = (Date.parse(this.object.begin) / 1000)

      this.$apollo.query({
        query: messagesByUnits,
        variables: {
          apiToken: this.user.apiToken,
          language: this.language,
          ids: this.object.unitsIds,
          begin: dateBegin,
          end: dateEnd
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { errors, status, result } = response.data.messagesByUnits
        const tripsByUnits = []
        let hasTrip = false

        switch (status) {
          case 'OK':
            for (let i = 0; i < result.length; i++) {
              const element = result[i]
              if (element.messages.length > 0) {
                hasTrip = true
                tripsByUnits.push({
                  unitId: element.unitId,
                  unitName: element.unitName,
                  color: this.getRandomColor(),
                  path: element.messages.map(item => {
                    return {
                      lat: item.latitude,
                      lng: item.longitude,
                      isCrashed: item.isCrashed
                    }
                  })
                })
              }
            }

            if (hasTrip) {
              this.$store.commit(
                'trips/drawMultipleTrips',
                tripsByUnits
              )
            } else {
              this.$store.commit('toggleSnackbar', {
                message: this.$i18n.t('errors.emptyTrips'),
                color: 'warning'
              })
            }
            break

          case 'UNPROCESSABLE':
            this.rawErrors = errors
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('errors.invalidFields'),
              color: 'warning'
            })
            break

          default:
            this.$store.commit('toggleSnackbar', undefined)
            break
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar', undefined)
        console.error(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
    getRandomColor () {
      return '#' + Math.floor(Math.random() * 16777215).toString(16)
    }
  }
}
</script>

<style lang="scss" scoped>
.expansion__content{
  max-height: calc(100vh - 260px);
  padding: 0 10px;
}
</style>
