<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12>
        <gm-listing
          name="units"
          has-actions
          :actions-access="{ show: true, edit: true, delete: true }"
          :columns="columns"
          :items-per-page="itemsPerPage"
          :rows="data"
          @new-item="newItem"
          @reload-list="$store.dispatch('units/getList', true)"
          @new-items-per-page="setNewItemsPerPage"
          @toggle-action="toggleAction"
        />
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      :fullscreen="isMobile"
      :max-width="width.dialog"
      scrollable
      persistent
      :overlay="false"
      transition="dialog-transition"
      :dark="isDark"
    >
      <v-card>
        <v-card-title>
          {{ dialogTitle }}
          <v-spacer />
          <v-btn icon text @click="discard">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider class="mx-4 my-2" />

        <v-card-text class="mb-0 pb-0">
          <v-layout row wrap>
            <v-flex xs12 sm6 md4 class="px-2 pt-2">
              <v-text-field
                v-model="object.name"
                :disabled="!isEditting"
                :error="errors.name.length > 0"
                :error-messages="errors.name"
                :label="$t('units.name')"
                outlined
              />
            </v-flex>
            <v-flex xs12 sm6 md4 class="px-2 pt-2">
              <v-select
                v-model="object.category"
                :label="$t('units.category')"
                :items="categories"
                :error="errors.category.length > 0"
                :error-messages="errors.category"
                item-text="name"
                item-value="value"
                outlined
                :disabled="!isEditting"
              />
            </v-flex>
            <v-flex xs12 sm6 md4 class="px-2 pt-2">
              <v-autocomplete
                v-model="object.iconId"
                :items="iconsList"
                :error-messages="errors.iconId"
                :label="$t('units.icon')"
                outlined
                item-text="name"
                item-value="id"
                :disabled="!isEditting"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-avatar>
                    <v-img :src="item.uri" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name" />
                  </v-list-item-content>
                </template>
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    @click="data.select"
                  >
                    <v-avatar left>
                      <v-img :src="data.item.uri" />
                    </v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 sm6 class="px-2 pt-2">
              <v-text-field
                v-model="object.ident"
                :disabled="!isEditting"
                :error="errors.ident.length > 0"
                :error-messages="errors.ident"
                :label="$t('units.ident')"
                outlined
              />
            </v-flex>
            <v-flex xs12 sm6 class="px-2 pt-2">
              <v-text-field
                v-model="object.phone"
                :disabled="!isEditting"
                :error="errors.phone.length > 0"
                :error-messages="errors.phone"
                :label="$t('units.phone')"
                outlined
              />
            </v-flex>
            <v-flex xs12 class="px-2 pt-2">
              <v-select
                v-model="object.protocolId"
                :label="$t('units.protocolId')"
                :items="protocols"
                :error="errors.protocolId.length > 0"
                :error-messages="errors.protocolId"
                item-text="name"
                item-value="id"
                :disabled="!isEditting"
                outlined
              />
            </v-flex>
            <v-flex v-if="isAdmin" xs12 class="px-2 pt-2">
              <v-select
                v-model="object.customerId"
                :label="$t('units.customer')"
                :items="customersList"
                :error="errors.customerId.length > 0"
                :error-messages="errors.customerId"
                item-text="username"
                item-value="id"
                :disabled="!isEditting"
                outlined
              />
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions class="d-flex justify-space-between flex-row-reverse">
          <v-btn v-if="isEditting" color="green darken-2 white--text" :disabled="isLoading" @click="graphqlHandler(object.id === undefined ? 'add' : 'edit')">
            <v-icon>mdi-check-circle</v-icon>
            {{ $t('actions.save') }}
          </v-btn>

          <v-btn v-if="!isEditting" color="orange darken-2 white--text" :disabled="isLoading" @click="editItem">
            <v-icon>mdi-pencil</v-icon>
            {{ $t('actions.edit') }}
          </v-btn>

          <v-btn v-if="isEditting" color="red darken-2 white--text" @click="discard">
            <v-icon>mdi-close-circle</v-icon>
            {{ $t('actions.discard') }}
          </v-btn>

          <v-btn v-if="object.id !== undefined && !isEditting" color="red darken-2 white--text" :disabled="isLoading" @click="graphqlHandler('delete', object.id)">
            <v-icon>mdi-delete</v-icon>
            {{ $t('actions.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { Listing } from 'Components/scaffolds'
import { mapState, mapGetters } from 'vuex'
import add from 'GraphQL/mutations/units/add.gql'
import edit from 'GraphQL/mutations/units/edit.gql'
import del from 'GraphQL/mutations/units/delete.gql'
import details from 'GraphQL/queries/units/details.gql'

export default {
  components: {
    'gm-listing': Listing
  },

  data () {
    return {
      object: {},
      dialog: false,
      isEditting: false,
      itemsPerPage: 10,
      currentTab: 0,
      showPassword: false,
      isLoading: false,
      rawErrors: {}
    }
  },
  computed: {
    ...mapState([
      'units',
      'general',
      'icons',
      'marshalls',
      'width',
      'isDark',
      'users',
      'language',
      'customers'
    ]),
    ...mapGetters(['isMobile']),
    ...mapGetters({
      defaultObject: 'units/defaultObject'
    }),
    isAdmin () {
      return this.user.__typename === 'Admin'
    },
    protocols () {
      return this.general.protocols
    },
    categories () {
      return [
        {
          name: this.$i18n.t('units.categories.marshall'),
          value: 'MARSHALL'
        },
        {
          name: this.$i18n.t('units.categories.player'),
          value: 'PLAYER'
        }
      ]
    },
    iconsList () {
      return this.icons.list
    },
    errors () {
      const errors = {}

      for (const key in this.defaultObject) {
        let keyCamel = key
        if (key.includes('_')) {
          keyCamel = this.convertToCamelCase(key)
        }

        // console.log(key, keyCamel, this.rawErrors[key])
        if (this.rawErrors[key] === undefined) {
          errors[keyCamel] = ''
        } else {
          errors[keyCamel] = this.rawErrors[key].join(', ')
        }
      }
      return errors
    },

    dialogTitle () {
      if (this.object.id === undefined) {
        return this.$i18n.t('units.title.new')
      } else {
        return this.$i18n.t(`units.title.${this.isEditting ? 'edit' : 'show'}`, { name: this.object.name })
      }
    },

    user () {
      return this.users.entity
    },

    data () {
      return this.units.list
    },

    columns () {
      return [
        {
          title: this.$i18n.t('units.name'),
          value: 'name',
          isSortable: false,
          alignment: 'center'
        },
        {
          title: this.$i18n.t('units.phone'),
          value: 'phone',
          isSortable: true,
          alignment: 'center'
        }
      ]
    },

    customersList () {
      return this.customers.list
    }
  },
  methods: {
    convertToCamelCase (str) {
      str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, (match) => {
        return match.charAt(match.length - 1).toUpperCase()
      })
      return str.charAt(0).toLowerCase() + str.substring(1)
    },

    setNewItemsPerPage (newValue) {
      this.itemsPerPage = newValue
    },

    newItem () {
      // console.log(this.categories)
      this.dialog = true
      this.isEditting = true
      this.object = Object.assign({}, this.defaultObject)
    },

    editItem () {
      this.isEditting = true
    },

    async toggleAction ({ action, id }) {
      await this.$store.commit('setLoading', true)

      if (action === 'delete') {
        await this.$store.commit('setLoading', true)
        this.graphqlHandler('delete', id)
        await this.$store.commit('setLoading', false)
      } else {
        await this.$apollo.query({
          query: details,
          variables: {
            apiToken: this.user.apiToken,
            language: this.language,
            id
          },
          fetchPolicy: 'no-cache'
        }).then((response) => {
          const { status, result } = response.data.unit
         
          if (status === 'OK') {
            this.object = Object.assign({}, result)
            this.dialog = true

            if (action === 'edit') {
              this.isEditting = true
            }
          } else {
            this.$store.commit('toggleSnackbar')
          }
        }).catch((error) => {
          this.$store.commit('toggleSnackbar')
        }).finally(() => {
          this.$store.commit('setLoading', false)
        })
      }
    },

    discard () {
      this.object = Object.assign({}, this.defaultObject)
      this.isEditting = false
      this.dialog = false
    },

    async graphqlHandler (method, id = undefined) {
      this.isLoading = true
      this.rawErrors = {}

      let mutation = add
      let name = 'addUnit'
      
      const variables = {
        apiToken: this.user.apiToken,
        language: this.language,
        data: this.object
      }

      delete variables.data.__typename

      if (method === 'edit') {
        name = 'editUnit'
        mutation = edit
      } else if (method === 'delete') {
        name = 'deleteUnit'
        delete variables.data
        variables.id = id
        mutation = del
        // console.log('Here', id)
      }

      await this.$apollo.mutate({
        mutation,
        variables,
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const data = response.data[name]
        const status = data.status

        const errorProcessed = {}

        switch (status) {
          case 'OK':
            this.$store.dispatch('units/getList', true)

            if (method === 'delete') {
              this.discard()
            } else {
              this.object = Object.assign({}, data.result)
              this.isEditting = false
            }
            break

          case 'UNPROCESSABLE':
            for (const key in data.errors) {
              errorProcessed[this.convertToCamelCase(key)] = data.errors[key]
            }

            this.rawErrors = Object.assign({}, errorProcessed)
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('errors.invalidFields'),
              color: 'orange darken-2'
            })
            break
        }
      }).catch((error) => {
        console.error('Units Mutation Error ', error)
        this.$store.commit('toggleSnackbar')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>