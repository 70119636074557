const conversor = {}

conversor.install = (Vue) => {
  Vue.prototype.$conversor = {
    formatMinutes (value, showSeconds = true) {
      let isNegative = false

      if (value < 0) {
        isNegative = true
        value = Math.abs(value)
      }

      let minutes = parseInt(value)
      let seconds = Math.round((value - minutes) * 60)

      if (seconds >= 60) {
        minutes++
        seconds = 0
      }

      return `${isNegative ? '-' : ''}${minutes > 9 ? minutes : `0${minutes}`}m ${showSeconds ? `${seconds > 9 ? seconds : `0${seconds}`}s` : ''}`
    },

    formatTime (value) {
      let hours = parseInt(value)
      let minutes = Math.round((value - hours) * 60)

      if (minutes >= 60) {
        hours++
        minutes = 0
      }

      return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`
    },

    formatHours (value) {
      let isNegative = false

      if (value < 0) {
        isNegative = true
        value = Math.abs(value)
      }

      let hours = parseInt(value)
      let minutes = Math.round((value - hours) * 60)

      if (minutes >= 60) {
        hours++
        minutes = 0
      }

      return `${isNegative ? '-' : ''}${hours > 9 ? hours : `0${hours}`}h ${minutes > 9 ? minutes : `0${minutes}`}m`
    }
  }
}

export default conversor