/*
  Unit getters
*/
export default {
  defaultObject () {
    return {
      id: undefined,
      name: 'New unit',
      ident: '',
      phone: '',
      category: 'PLAYER',
      protocolId: '1',
      iconId: '1',
      nominalVoltage: 'VOLT48',
      customerId: null
    }
  },

  byId (state) {
    const byId = {}

    for (const i in state.list) {
      byId[state.list[i].id] = state.list[i]
    }

    return byId
  },

  players (state) {
    return state.list.filter((item, i) => item.category === 'PLAYER').map((item, index) => {
      return {
        ...item,
        voltage: 0,
        engine: false
      }
    })
  },

  marshalls (state) {
    return state.list.filter((item, i) => item.category === 'MARSHALL').map((item, index) => {
      return {
        ...item,
        voltage: 0,
        engine: false
      }
    })
  }
}
