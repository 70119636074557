<template>
  <div ref="resizableContainer">
    <v-row no-gutters class="pa-0 ma-0" style="height: 100%;" justify="center">
      <v-sheet
        v-if="left"
        class="handle__bar mr-1 d-flex justify-center align-center bar__left"
        tile
        elevation="3"
        color="grey lighten-4"
        width="9px"
        height="100%"
        @mousedown="startDragLeft"
        @mouseup="stopDragLeft"
        @touchstart="startDragLeft"
        @touchend="stopDragLeft"
      >
        <v-icon>mdi-drag-vertical</v-icon>
      </v-sheet>
      <div :style="`width: calc(100% - ${offsetBar}px);`">
        <slot />
      </div>
      <v-sheet
        v-if="right"
        class="handle__bar ml-1 d-flex justify-center align-center bar__right"
        tile
        elevation="3"
        color="grey lighten-4"
        width="9px"
        height="100%"
        @mousedown="startDragRight"
        @mouseup="stopDragRight"
        @touchstart="startDragRight"
        @touchend="stopDragRight"
      >
        <v-icon>mdi-drag-vertical</v-icon>
      </v-sheet>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    left: {
      type: Boolean,
      default: () => true
    },
    right: {
      type: Boolean,
      default: () => true
    },
    resizeOff: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      handleSize: 24,
      panel: null,
      mousePosition: null
    }
  },
  computed: {
    offsetBar () {
      if (!this.resizeOff) {
        if (this.left && this.right) {
          return this.handleSize * 2
        } else {
          return this.handleSize
        }
      }
      return 0
    }
  },
  mounted () {
    this.panel = this.$refs.resizableContainer
  },
  methods: {
    startDragRight (e) {
      this.mousePosition = e.x
      document.addEventListener('mousemove', this.resizeRight, false)
      document.addEventListener('mouseup', this.stopDragRight, false)
    },
    stopDragRight (e) {
      document.removeEventListener('mousemove', this.resizeRight, false)
      document.removeEventListener('mouseup', this.stopDragRight, false)
    },
    resizeRight (e) {
      const dx = this.mousePosition - e.x
      this.mousePosition = e.x
      // se resta dx
      const size = (parseInt(getComputedStyle(this.panel, '').width) - dx)
      this.panel.style.width = (size / window.visualViewport.width * 100) + '%'
    },
    resizeLeft (e) {
      const dx = this.mousePosition - e.x
      this.mousePosition = e.x
      // se suma dx
      const size = (parseInt(getComputedStyle(this.panel, '').width) + dx)

      this.panel.style.width = (size / window.visualViewport.width * 100) + '%'
    },
    startDragLeft (e) {
      this.mousePosition = e.x
      document.addEventListener('mousemove', this.resizeLeft, false)
      document.addEventListener('mouseup', this.stopDragLeft, false)
    },
    stopDragLeft (e) {
      document.removeEventListener('mousemove', this.resizeLeft, false)
      document.removeEventListener('mouseup', this.stopDragLeft, false)
    },
    fillWidth () {
      this.panel.style.width = '100%'
    }
  }
}
</script>

<style lang="scss" scoped>
.handle__bar{
  cursor: ew-resize !important;
}
</style>