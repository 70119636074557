export default {
  defaultObject () {
    return {
      id: undefined,
      name: '',
      lastName: '',
      phone: '',
      username: '',
      email: '',
      password: ''
    }
  }
}