<template>
  <v-chip
    small
    :color="color"
    :class="`text-center ${type === 'basic' ? 'fix-chip' : ''}`"
  >
    {{ currentValue }}
  </v-chip>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    },

    type: {
      type: String,
      default: () => 'full',
      options: ['full', 'basic']
    },

    offset: {
      type: Number,
      default: () => 2
    }
  },

  data () {
    return {
      isOvertime: false,
      currentTime: 0,
      current: null,
      allowed: null,
      interval: null,
      reaching: null,
      overtime: null
    }
  },

  computed: {
    /**
      * Current Time
      * Get current time from message
      *
      * @since  2020-02-11
      * @access private
      * 
      * @return {String} Time in minutes or Labeling
      *
      */
    currentValue () {
      if (this.type === 'full') {
        if (this.current !== null) {
          return `${this.$conversor.formatMinutes(this.current)}`
        } else {
          return 'N/A'
        }
      } else {
        if (this.overtime) {
          return this.$i18n.t('paceOfPlay.states.overtime')
        } else if (this.reaching) {
          return this.$i18n.t('paceOfPlay.states.reaching')
        } else {
          return this.$i18n.t('paceOfPlay.states.allowed')
        }
      }
    },

    /**
      * Get color
      * Get color by elapsed time (current time) of Pace of Play
      *
      * @since  2020-02-11
      * @access private
      * 
      * @return {String} color
      *
      */
    color () {
      if (this.current === null) {
        return 'grey darken-2'
      } else if (this.overtime) {
        return 'red darken-2'
      } else if (this.reaching) {
        return 'yellow darken-2 black--text'
      } else {
        return 'green darken-2'
      }
    }
  },

  watch: {
    /**
      * Recalculate all
      *
      * @since  2020-02-12
      * @access private
      * 
      * @param {Object} newVal new Value of Message
      * @param {Object} oldVal old Value of Message
      *
      */
    message (newVal, oldVal) {
      this.calculateValues()
    },

    /**
      * Calculate Overtime and Is Reaching
      * Calculate if the current is in overtime or reaching the allowed time
      *
      * @since  2020-02-12
      * @access private
      *
      */
    current (newVal, oldVal) {
      if (!newVal) {
        return null
      }

      this.overtime = newVal >= this.allowed
      this.reaching = newVal >= (this.allowed - this.offset)
    }
  },

  mounted () {
    this.calculateValues()
  },

  methods: {
    /**
      * Calculate values
      * Process raw message to get all variables filled
      *
      * @since  2020-02-12
      * @access private
      *
      */
    calculateValues () {
      if (this.type === 'full') {
        if (this.message === null || this.message === undefined || this.message.paceOfPlay === undefined || this.message.paceOfPlay.startAt === null) {
          this.current = null
          this.allowed = null
        } else {
          this.current = ((new Date() / 1000) - this.message.paceOfPlay.startAt) / 60
          this.allowed = this.message.paceOfPlay.allowed
        }
      } else {
        this.current = ((new Date() / 1000) - this.message.startAt) / 60
        this.allowed = this.message.allowed
      }

      if (this.current !== null && this.interval === null) {
        this.interval = setInterval(() => {
          this.current += 0.016
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss">
  .fix-chip {
    width: 100px;

    .v-chip__content {
      text-align: center;
      margin: auto;
    }
  }
</style>