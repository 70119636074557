/*
  Unit state
*/
export default {
  /**
   * List of all units (mobile and cars)
   */
  list: [],
  /**
   * only car units to be displayed in the table
   */
  tableList: [],
  enabled: [],
  unitConnectionStatus: {}
}
