/*
  Restricted mutations
*/
export default {
  setList (state, payload) {
    state.list = []
    state.list = payload
  },

  setGeofence (state, payload) {
    state.geofence = []
    state.geofence = payload
  },

  setEdittingId (state, payload) {
    state.edittingId = payload
  },

  setType (state, payload) {
    state.type = payload
  }
}
