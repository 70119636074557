<template>
  <v-expansion-panels v-model="currentPanel" dark>
    <v-expansion-panel class="grey-opacity">
      <v-expansion-panel-header>
        {{ $t(`headers.${$route.name}`) }}
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pa-0">
        <div
          class="scrollbar__seamless expansion__content"
        >
          <v-data-table
            :headers="headers"
            :items="data"
            fixed-header
            multi-sort
            sort-by="at"
            class="transparent datatable__custom"
            :items-per-page="-1"
            height="55vh"
          >
            <template v-slot:item="{ item }">
              <tr>
                <!-- Alert Name -->
                <td class="white--text text-center">
                  {{ item.unit.name }}
                </td>

                <!--  Alert content -->
                <td class="white--text text-center">
                  {{ $t(item.content.content) }}
                </td>

                <!-- Alert date -->
                <td class="white--text text-center">
                  {{ parseDate(item.at) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  data () {
    return {
      currentPanel: 0
    }
  },

  computed: {
    ...mapState(['general']),
    headers () {
      return [
        {
          text: this.$i18n.t('alerts.unit'),
          value: 'unit',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('alerts.content'),
          value: 'content',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        },
        {
          text: this.$i18n.t('alerts.at'),
          value: 'at',
          sortable: true,
          align: 'center',
          class: 'transparent text--center'
        }
      ]
    },

    data () {
      return this.general.alerts
    }
  },

  methods: {
    parseDate (unix) {
      return moment(unix * 1000).format('MM/DD/YYYY HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped>
.expansion__content{
  max-height: calc(100vh - 260px);
  padding: 0 10px;
  overflow: hidden;
}
</style>
