/*
  Icon getters
*/
export default {
  defaultObject () {
    return {
      unitId: undefined,
      begin: new Date(),
      end: new Date(),
      unitsIds: []
    }
  }
}
