<template>
  <v-expansion-panels v-model="currentPanel" dark>
    <v-expansion-panel class="grey-opacity">
      <v-expansion-panel-header>
        {{ $t('courses.holes.title') }}
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pa-0">
        <div class="expansion__content scrollbar__seamless" style="max-height: calc(100vh - 195px); overflow-y: scroll;">
          <v-list color="transparent">
            <v-list-item v-for="(hole, key) in holes" :key="`hole-${key}`" dense>
              <v-list-item-title>
                <v-badge :color="`${hole.geofence.length === 0 ? 'orange' : 'green'} darken-2`" dot>
                  {{ hole.name }}
                </v-badge>
              </v-list-item-title>

              <v-list-item-action>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon v-on="on" @click="toggleEditor(hole.position)">
                      <v-icon small>mdi-{{ currentEditting === hole.position ? 'cancel' : 'pencil' }}</v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t(`helpers.${ currentEditting === hole.position ? 'finishDraw' : 'drawMap' }`) }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      currentPanel: 0,
      currentEditting: undefined
    }
  },

  computed: {
    ...mapState(['courses']),

    holes () {
      const holes = Object.values(Object.assign({}, this.courses.holes))

      holes.sort((a, b) => { return a.position < b.position ? -1 : 1 })

      return holes
    }
  },

  mounted () {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'courses/setHoles') {
        this.currentEditting = undefined
        this.$store.commit('toggleMap', {
          key: undefined,
          state: false,
          information: [],
          type: 'hole'
        })
      }
    })
  },

  methods: {
    toggleEditor (key) {
      if (this.currentEditting === undefined) {
        this.currentEditting = key
        this.$store.commit('toggleMap', {
          key,
          state: true,
          information: [],
          type: 'hole'
        })
      } else {
        this.currentEditting = undefined
        this.$store.commit('toggleMap', {
          key: undefined,
          state: false,
          information: [],
          type: 'hole'
        })
      }
    }
  }
}
</script>